@charset "UTF-8";
/*!
 * Cartzilla | Multipurpose E-Commerce Bootstrap HTML Template
 * Copyright 2024 Createx Studio
 * Theme styles
*/
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300 800;
  src: url("../fonts/inter-variable-latin.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* stylelint-disable @stylistic/value-list-max-empty-lines, @stylistic/function-max-empty-lines */
.focus-none:focus, .focus-none:focus-visible {
  outline: none !important;
  box-shadow: none !important;
}

[class^=rounded].overflow-hidden,
[class*=" rounded"].overflow-hidden {
  transform: translateZ(0);
}

.bg-dotted {
  --cz-bg-color: var(--cz-tertiary-bg);
  --cz-dot-color: var(--cz-secondary);
  --cz-dot-size: 1px;
  --cz-dot-space: 20px;
  background: linear-gradient(90deg, var(--cz-bg-color) calc(var(--cz-dot-space) - var(--cz-dot-size)), transparent 1%) center, linear-gradient(var(--cz-bg-color) calc(var(--cz-dot-space) - var(--cz-dot-size)), transparent 1%) center, var(--cz-dot-color);
  background-size: var(--cz-dot-space) var(--cz-dot-space);
}

.border-dashed {
  --cz-border-style: dashed;
}

.border-dotted {
  --cz-border-style: dotted;
}

.transition-none {
  transition: none !important;
}

.transform-none {
  transform: none !important;
}

.bg-image-none {
  background-image: none !important;
}

[dir=rtl] .rtl-flip {
  transform: rotateY(180deg);
}

[dir=rtl] .d-none-rtl {
  display: none !important;
}

[dir=rtl] .d-block-rtl {
  display: block !important;
}

[dir=rtl] .d-inline-rtl {
  display: inline !important;
}

[dir=rtl] .d-inline-block-rtl {
  display: inline-block !important;
}

[dir=rtl] .d-flex-rtl {
  display: flex !important;
}

[dir=rtl] .d-inline-flex-rtl {
  display: inline-flex !important;
}

[data-sticky-element] {
  top: -1px !important;
}

[data-bs-theme=dark] .d-none-dark {
  display: none !important;
}
[data-bs-theme=dark] .d-block-dark {
  display: block !important;
}
[data-bs-theme=dark] .d-inline-dark {
  display: inline !important;
}
[data-bs-theme=dark] .d-inline-block-dark {
  display: inline-block !important;
}
[data-bs-theme=dark] .d-inline-flex-dark {
  display: inline-flex !important;
}
[data-bs-theme=dark] .d-flex-dark {
  display: flex !important;
}
[data-bs-theme=dark] .shadow:not([data-bs-theme=light]) {
  box-shadow: 0 0.5rem 1.875rem -0.25rem rgba(8, 11, 18, 0.25) !important;
}
[data-bs-theme=dark] .shadow-sm:not([data-bs-theme=light]) {
  box-shadow: 0 0.375rem 1.375rem -0.25rem rgba(8, 11, 18, 0.25) !important;
}
[data-bs-theme=dark] .shadow-lg:not([data-bs-theme=light]) {
  box-shadow: 0 0.75rem 3rem -0.5rem rgba(8, 11, 18, 0.25) !important;
}

/* stylelint-enable @stylistic/value-list-max-empty-lines, @stylistic/function-max-empty-lines */
:root,
[data-bs-theme=light] {
  --cz-primary: #2e6da4;
  --cz-secondary: #6c727f;
  --cz-success: #33b36b;
  --cz-info: #2f6ed5;
  --cz-warning: #fc9231;
  --cz-danger: #f03d3d;
  --cz-light: #fff;
  --cz-dark: #222934;
  --cz-primary-rgb: 46, 109, 164;
  --cz-secondary-rgb: 108, 114, 127;
  --cz-success-rgb: 51, 179, 107;
  --cz-info-rgb: 47, 110, 213;
  --cz-warning-rgb: 252, 146, 49;
  --cz-danger-rgb: 240, 61, 61;
  --cz-light-rgb: 255, 255, 255;
  --cz-dark-rgb: 34, 41, 52;
  --cz-primary-text-emphasis: #23537c;
  --cz-secondary-text-emphasis: #333d4c;
  --cz-success-text-emphasis: #288b53;
  --cz-info-text-emphasis: #2358ae;
  --cz-warning-text-emphasis: #f67804;
  --cz-danger-text-emphasis: #e81212;
  --cz-light-text-emphasis: #333d4c;
  --cz-dark-text-emphasis: #181d25;
  --cz-primary-bg-subtle: #eaf0f6;
  --cz-secondary-bg-subtle: #f5f7fa;
  --cz-success-bg-subtle: #ebf7f0;
  --cz-info-bg-subtle: #eaf1fb;
  --cz-warning-bg-subtle: #fff4ea;
  --cz-danger-bg-subtle: #feecec;
  --cz-light-bg-subtle: #fff;
  --cz-dark-bg-subtle: #edeeef;
  --cz-primary-border-subtle: #d5e2ed;
  --cz-secondary-border-subtle: #e0e5eb;
  --cz-success-border-subtle: #d6f0e1;
  --cz-info-border-subtle: #d5e2f7;
  --cz-warning-border-subtle: #fee9d6;
  --cz-danger-border-subtle: #fcd8d8;
  --cz-light-border-subtle: #eef1f6;
  --cz-dark-border-subtle: #e0e5eb;
  --cz-white-rgb: 255, 255, 255;
  --cz-black-rgb: 0, 0, 0;
  --cz-font-sans-serif: "Inter", sans-serif;
  --cz-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --cz-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --cz-root-font-size: 1rem;
  --cz-body-font-family: var(--cz-font-sans-serif);
  --cz-body-font-size: 1rem;
  --cz-body-font-weight: 400;
  --cz-body-line-height: 1.5;
  --cz-body-color: #4e5562;
  --cz-body-color-rgb: 78, 85, 98;
  --cz-body-bg: #fff;
  --cz-body-bg-rgb: 255, 255, 255;
  --cz-emphasis-color: #222934;
  --cz-emphasis-color-rgb: 34, 41, 52;
  --cz-secondary-color: #6c727f;
  --cz-secondary-color-rgb: 108, 114, 127;
  --cz-secondary-bg: #eef1f6;
  --cz-secondary-bg-rgb: 238, 241, 246;
  --cz-tertiary-color: #9ca3af;
  --cz-tertiary-color-rgb: 156, 163, 175;
  --cz-tertiary-bg: #f5f7fa;
  --cz-tertiary-bg-rgb: 245, 247, 250;
  --cz-heading-color: #181d25;
  --cz-link-color: #2e6da4;
  --cz-link-color-rgb: 46, 109, 164;
  --cz-link-decoration: underline;
  --cz-link-hover-color: #2e6da4;
  --cz-link-hover-color-rgb: 46, 109, 164;
  --cz-link-hover-decoration: none;
  --cz-code-color: #d63384;
  --cz-highlight-color: #4e5562;
  --cz-highlight-bg: #fff3cd;
  --cz-border-width: 1px;
  --cz-border-style: solid;
  --cz-border-color: #e0e5eb;
  --cz-border-color-translucent: rgba(0, 0, 0, 0.1);
  --cz-border-radius: 0.5rem;
  --cz-border-radius-sm: calc(var(--cz-border-radius) * 0.75);
  --cz-border-radius-lg: calc(var(--cz-border-radius) * 1.5);
  --cz-border-radius-xl: calc(var(--cz-border-radius) * 2);
  --cz-border-radius-xxl: calc(var(--cz-border-radius) * 2.5);
  --cz-border-radius-2xl: var(--cz-border-radius-xxl);
  --cz-border-radius-pill: 50rem;
  --cz-box-shadow: 0 0.5rem 2rem -0.25rem rgba(103, 111, 123, 0.1);
  --cz-box-shadow-sm: 0 0.375rem 1.5rem rgba(103, 111, 123, 0.06);
  --cz-box-shadow-lg: 0 1.125rem 3rem -0.375rem rgba(103, 111, 123, 0.12);
  --cz-box-shadow-inset: unset;
  --cz-focus-ring-width: 0.25rem;
  --cz-focus-ring-opacity: 0.1;
  --cz-focus-ring-color: rgba(24, 29, 37, 0.1);
  --cz-form-valid-color: #33b36b;
  --cz-form-valid-border-color: #33b36b;
  --cz-form-invalid-color: #f03d3d;
  --cz-form-invalid-border-color: #f03d3d;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --cz-body-color: #cad0d9;
  --cz-body-color-rgb: 202, 208, 217;
  --cz-body-bg: #181d25;
  --cz-body-bg-rgb: 24, 29, 37;
  --cz-emphasis-color: #eef1f6;
  --cz-emphasis-color-rgb: 238, 241, 246;
  --cz-secondary-color: #9ca3af;
  --cz-secondary-color-rgb: 156, 163, 175;
  --cz-secondary-bg: #333d4c;
  --cz-secondary-bg-rgb: 51, 61, 76;
  --cz-tertiary-color: #6c727f;
  --cz-tertiary-color-rgb: 108, 114, 127;
  --cz-tertiary-bg: #222934;
  --cz-tertiary-bg-rgb: 34, 41, 52;
  --cz-primary-text-emphasis: #286090;
  --cz-secondary-text-emphasis: #e0e5eb;
  --cz-success-text-emphasis: #2d9f5f;
  --cz-info-text-emphasis: #2762c3;
  --cz-warning-text-emphasis: #fc8518;
  --cz-danger-text-emphasis: #ee2525;
  --cz-light-text-emphasis: #e0e5eb;
  --cz-dark-text-emphasis: #fff;
  --cz-primary-bg-subtle: #1a2532;
  --cz-secondary-bg-subtle: #222934;
  --cz-success-bg-subtle: #1b2c2c;
  --cz-info-bg-subtle: #1a2537;
  --cz-warning-bg-subtle: #2f2926;
  --cz-danger-bg-subtle: #2e2027;
  --cz-light-bg-subtle: #181d25;
  --cz-dark-bg-subtle: #131920;
  --cz-primary-border-subtle: #1c2d3e;
  --cz-secondary-border-subtle: #333d4c;
  --cz-success-border-subtle: #1d3b33;
  --cz-info-border-subtle: #1d2d48;
  --cz-warning-border-subtle: #463427;
  --cz-danger-border-subtle: #43232a;
  --cz-light-border-subtle: #333d4c;
  --cz-dark-border-subtle: #131920;
  --cz-heading-color: #fff;
  --cz-link-color: #82a7c8;
  --cz-link-hover-color: #82a7c8;
  --cz-link-color-rgb: 130, 167, 200;
  --cz-link-hover-color-rgb: 130, 167, 200;
  --cz-code-color: #e685b5;
  --cz-highlight-color: #cad0d9;
  --cz-highlight-bg: #664d03;
  --cz-border-color: #333d4c;
  --cz-border-color-translucent: rgba(255, 255, 255, 0.15);
  --cz-form-valid-color: #33b36b;
  --cz-form-valid-border-color: #33b36b;
  --cz-form-invalid-color: #f03d3d;
  --cz-form-invalid-border-color: #f03d3d;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  font-size: var(--cz-root-font-size);
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--cz-body-font-family);
  font-size: var(--cz-body-font-size);
  font-weight: var(--cz-body-font-weight);
  line-height: var(--cz-body-line-height);
  color: var(--cz-body-color);
  text-align: var(--cz-body-text-align);
  background-color: var(--cz-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1.5rem 0;
  color: var(--cz-border-color);
  border: 0;
  border-top: var(--cz-border-width) solid;
  opacity: 1;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 600;
  line-height: 1.2;
  color: var(--cz-heading-color);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--cz-highlight-color);
  background-color: var(--cz-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--cz-link-color-rgb), var(--cz-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --cz-link-color-rgb: var(--cz-link-hover-color-rgb);
  text-decoration: none;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--cz-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
  color: #fff;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--cz-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--cz-body-bg);
  background-color: var(--cz-emphasis-color);
  border-radius: calc(var(--cz-border-radius) * 0.75);
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: var(--cz-secondary-color);
  text-align: left;
}

th {
  font-weight: 600;
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 1rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: inherit;
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 400;
}
@media (min-width: 1200px) {
  .lead {
    font-size: 1.5rem;
  }
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 700;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 700;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 700;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 700;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 700;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.3875rem + 1.65vw);
  font-weight: 700;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.625rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 1rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1.5rem;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
  color: var(--cz-body-color);
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.375rem;
  background-color: var(--cz-body-bg);
  border: var(--cz-border-width) solid var(--cz-border-color);
  border-radius: var(--cz-border-radius-lg);
  box-shadow: none;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.75rem;
  color: var(--cz-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --cz-gutter-x: 2rem;
  --cz-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--cz-gutter-x) * 0.5);
  padding-left: calc(var(--cz-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 500px) {
  .container-sm, .container {
    max-width: 95%;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 95%;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 95%;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 95%;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1328px;
  }
}
:root {
  --cz-breakpoint-xs: 0;
  --cz-breakpoint-sm: 500px;
  --cz-breakpoint-md: 768px;
  --cz-breakpoint-lg: 992px;
  --cz-breakpoint-xl: 1200px;
  --cz-breakpoint-xxl: 1400px;
}

.row {
  --cz-gutter-x: 1.5rem;
  --cz-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--cz-gutter-y));
  margin-right: calc(-0.5 * var(--cz-gutter-x));
  margin-left: calc(-0.5 * var(--cz-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--cz-gutter-x) * 0.5);
  padding-left: calc(var(--cz-gutter-x) * 0.5);
  margin-top: var(--cz-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --cz-gutter-x: 0;
}

.g-0,
.gy-0 {
  --cz-gutter-y: 0;
}

.g-1,
.gx-1 {
  --cz-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --cz-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --cz-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --cz-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --cz-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --cz-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --cz-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --cz-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --cz-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --cz-gutter-y: 3rem;
}

@media (min-width: 500px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --cz-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --cz-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --cz-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --cz-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --cz-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --cz-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --cz-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --cz-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --cz-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --cz-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --cz-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --cz-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --cz-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --cz-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --cz-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --cz-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --cz-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --cz-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --cz-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --cz-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --cz-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --cz-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --cz-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --cz-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --cz-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --cz-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --cz-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --cz-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --cz-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --cz-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --cz-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --cz-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --cz-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --cz-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --cz-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --cz-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --cz-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --cz-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --cz-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --cz-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --cz-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --cz-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --cz-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --cz-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --cz-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --cz-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --cz-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --cz-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --cz-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --cz-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --cz-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --cz-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --cz-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --cz-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --cz-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --cz-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --cz-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --cz-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --cz-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --cz-gutter-y: 3rem;
  }
}
.table {
  --cz-table-color-type: initial;
  --cz-table-bg-type: initial;
  --cz-table-color-state: initial;
  --cz-table-bg-state: initial;
  --cz-table-color: var(--cz-emphasis-color);
  --cz-table-bg: var(--cz-body-bg);
  --cz-table-border-color: var(--cz-border-color);
  --cz-table-accent-bg: transparent;
  --cz-table-striped-color: var(--cz-emphasis-color);
  --cz-table-striped-bg: rgba(51, 61, 76, 0.05);
  --cz-table-active-color: var(--cz-emphasis-color);
  --cz-table-active-bg: rgba(51, 61, 76, 0.1);
  --cz-table-hover-color: var(--cz-emphasis-color);
  --cz-table-hover-bg: rgba(51, 61, 76, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--cz-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.75rem 0.75rem;
  color: var(--cz-table-color-state, var(--cz-table-color-type, var(--cz-table-color)));
  background-color: var(--cz-table-bg);
  border-bottom-width: var(--cz-border-width);
  box-shadow: inset 0 0 0 9999px var(--cz-table-bg-state, var(--cz-table-bg-type, var(--cz-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--cz-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.375rem 0.375rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--cz-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--cz-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --cz-table-color-type: var(--cz-table-striped-color);
  --cz-table-bg-type: var(--cz-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --cz-table-color-type: var(--cz-table-striped-color);
  --cz-table-bg-type: var(--cz-table-striped-bg);
}

.table-active {
  --cz-table-color-state: var(--cz-table-active-color);
  --cz-table-bg-state: var(--cz-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --cz-table-color-state: var(--cz-table-hover-color);
  --cz-table-bg-state: var(--cz-table-hover-bg);
}

.table-dark {
  --cz-table-color: #fff;
  --cz-table-bg: #222934;
  --cz-table-border-color: #4e545d;
  --cz-table-striped-bg: #2d343e;
  --cz-table-striped-color: #fff;
  --cz-table-active-bg: #383e48;
  --cz-table-active-color: #fff;
  --cz-table-hover-bg: #333943;
  --cz-table-hover-color: #fff;
  color: var(--cz-table-color);
  border-color: var(--cz-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 499.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--cz-heading-color);
}

.col-form-label {
  padding-top: calc(0.53rem + var(--cz-border-width));
  padding-bottom: calc(0.53rem + var(--cz-border-width));
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 500;
  line-height: 1.5;
  color: var(--cz-heading-color);
}

.col-form-label-lg {
  padding-top: calc(0.685rem + var(--cz-border-width));
  padding-bottom: calc(0.685rem + var(--cz-border-width));
  font-size: 1rem;
}

.col-form-label-sm {
  padding-top: calc(0.375rem + var(--cz-border-width));
  padding-bottom: calc(0.375rem + var(--cz-border-width));
  font-size: 0.75rem;
}

.form-text {
  margin-top: 0.375rem;
  font-size: 0.75rem;
  color: var(--cz-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.53rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--cz-body-color);
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: var(--cz-border-width) solid #cad0d9;
  border-radius: var(--cz-border-radius);
  box-shadow: var(--cz-box-shadow-inset);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--cz-body-color);
  background-color: #fff;
  border-color: #181d25;
  outline: 0;
  box-shadow: var(--cz-box-shadow-inset), unset;
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: var(--cz-tertiary-color);
  opacity: 1;
}
.form-control:disabled {
  color: var(--cz-tertiary-color);
  background-color: var(--cz-tertiary-bg);
  border-color: var(--cz-border-color);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.53rem 1rem;
  margin: -0.53rem -1rem;
  margin-inline-end: 1rem;
  color: var(--cz-body-color);
  background-color: var(--cz-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--cz-border-width);
  border-radius: 0;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--cz-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.53rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--cz-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--cz-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.75rem + calc(var(--cz-border-width) * 2));
  padding: 0.375rem 0.875rem;
  font-size: 0.75rem;
  border-radius: var(--cz-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.375rem 0.875rem;
  margin: -0.375rem -0.875rem;
  margin-inline-end: 0.875rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1.37rem + calc(var(--cz-border-width) * 2));
  padding: 0.685rem 1.125rem;
  font-size: 1rem;
  border-radius: var(--cz-border-radius);
}
.form-control-lg::file-selector-button {
  padding: 0.685rem 1.125rem;
  margin: -0.685rem -1.125rem;
  margin-inline-end: 1.125rem;
}

textarea.form-control {
  min-height: calc(1.5em + 1.06rem + calc(var(--cz-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.75rem + calc(var(--cz-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1.37rem + calc(var(--cz-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 1.06rem + calc(var(--cz-border-width) * 2));
  padding: 0.53rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--cz-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--cz-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.75rem + calc(var(--cz-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1.37rem + calc(var(--cz-border-width) * 2));
}

.form-select {
  --cz-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%234e5562' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.53rem 2.25rem 0.53rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--cz-body-color);
  appearance: none;
  background-color: #fff;
  background-image: var(--cz-form-select-bg-img), var(--cz-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 15px 11px;
  border: var(--cz-border-width) solid #cad0d9;
  border-radius: var(--cz-border-radius);
  box-shadow: var(--cz-box-shadow-inset);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #181d25;
  outline: 0;
  box-shadow: var(--cz-box-shadow-inset), 0 0 0 0 rgba(24, 29, 37, 0.1);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--cz-tertiary-bg);
  border-color: var(--cz-border-color);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--cz-body-color);
}

.form-select-sm {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.875rem;
  font-size: 0.75rem;
  border-radius: var(--cz-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.685rem;
  padding-bottom: 0.685rem;
  padding-left: 1.125rem;
  font-size: 1rem;
  border-radius: var(--cz-border-radius);
}

[data-bs-theme=dark] .form-select {
  --cz-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23cad0d9' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.25rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --cz-form-check-bg: #fff;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--cz-form-check-bg);
  background-image: var(--cz-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--cz-border-width) solid #cad0d9;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #181d25;
  outline: 0;
  box-shadow: 0 0 0 0.25rem var(--cz-focus-ring-color);
}
.form-check-input:checked {
  background-color: #fff;
  border-color: #181d25;
}
.form-check-input:checked[type=checkbox] {
  --cz-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23181d25' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --cz-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23181d25'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #fff;
  border-color: #181d25;
  --cz-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23181d25' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 3.375rem;
}
.form-switch .form-check-input {
  --cz-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  width: 2.75rem;
  margin-left: -3.375rem;
  background-image: var(--cz-form-switch-bg);
  background-position: left center;
  border-radius: 2.75rem;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --cz-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --cz-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 3.375rem;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -3.375rem;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.55;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --cz-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 0.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, unset;
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, unset;
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 0.5rem;
  height: 0.5rem;
  margin-top: -0.1875rem;
  appearance: none;
  background-color: var(--cz-body-bg);
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0 0 0.125rem var(--cz-emphasis-color);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: var(--cz-emphasis-color);
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.125rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--cz-border-color);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: var(--cz-box-shadow-inset);
}
.form-range::-moz-range-thumb {
  width: 0.5rem;
  height: 0.5rem;
  appearance: none;
  background-color: var(--cz-body-bg);
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0 0 0.125rem var(--cz-emphasis-color);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: var(--cz-emphasis-color);
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.125rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--cz-border-color);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: var(--cz-box-shadow-inset);
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--cz-secondary-bg);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--cz-secondary-bg);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--cz-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--cz-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 1rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--cz-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 1rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--cz-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.5rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: #fff;
  border-radius: var(--cz-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--cz-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--cz-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #4e5562;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--cz-tertiary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.53rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--cz-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--cz-tertiary-bg);
  border: var(--cz-border-width) solid #cad0d9;
  border-radius: var(--cz-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.685rem 1.125rem;
  font-size: 1rem;
  border-radius: var(--cz-border-radius);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.375rem 0.875rem;
  font-size: 0.75rem;
  border-radius: var(--cz-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3.25rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--cz-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.375rem;
  font-size: 0.75rem;
  color: var(--cz-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.75rem;
  color: #fff;
  background-color: var(--cz-success);
  border-radius: var(--cz-border-radius-sm);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--cz-form-valid-border-color);
  padding-right: calc(1.5em + 1.06rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%2333b36b'%3e%3cpath d='M10.2,3.8c1.8-0.4,3.6-0.2,5.3,0.5c0.4,0.2,0.9,0,1.1-0.4s0-0.9-0.4-1.1c0,0,0,0,0,0c-5-2.2-10.9,0-13.2,5.1s0,10.9,5.1,13.2s10.9,0,13.2-5.1c0.6-1.3,0.9-2.7,0.9-4.1v-0.8c0-0.4-0.4-0.8-0.8-0.8s-0.8,0.4-0.8,0.8l0,0V12c0,4.6-3.8,8.4-8.4,8.4S3.6,16.6,3.6,12C3.6,8.1,6.3,4.7,10.2,3.8z'/%3e%3cpath d='M21.8,5.2c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-0.8-0.3-1.1,0c0,0,0,0,0,0L12,12.7l-2.2-2.2c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1l2.8,2.8c0.3,0.3,0.8,0.3,1.1,0c0,0,0,0,0,0L21.8,5.2L21.8,5.2z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.265rem) center;
  background-size: calc(0.75em + 0.53rem) calc(0.75em + 0.53rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--cz-form-valid-border-color);
  box-shadow: var(--cz-box-shadow-inset), 0 0 0 0 rgba(var(--cz-success-rgb), 0.1);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.06rem);
  background-position: top calc(0.375em + 0.265rem) right calc(0.375em + 0.265rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--cz-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --cz-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%2333b36b'%3e%3cpath d='M10.2,3.8c1.8-0.4,3.6-0.2,5.3,0.5c0.4,0.2,0.9,0,1.1-0.4s0-0.9-0.4-1.1c0,0,0,0,0,0c-5-2.2-10.9,0-13.2,5.1s0,10.9,5.1,13.2s10.9,0,13.2-5.1c0.6-1.3,0.9-2.7,0.9-4.1v-0.8c0-0.4-0.4-0.8-0.8-0.8s-0.8,0.4-0.8,0.8l0,0V12c0,4.6-3.8,8.4-8.4,8.4S3.6,16.6,3.6,12C3.6,8.1,6.3,4.7,10.2,3.8z'/%3e%3cpath d='M21.8,5.2c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-0.8-0.3-1.1,0c0,0,0,0,0,0L12,12.7l-2.2-2.2c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1l2.8,2.8c0.3,0.3,0.8,0.3,1.1,0c0,0,0,0,0,0L21.8,5.2L21.8,5.2z'/%3e%3c/svg%3e");
  padding-right: 4.75rem;
  background-position: right 1rem center, center right 2.25rem;
  background-size: 15px 11px, calc(0.75em + 0.53rem) calc(0.75em + 0.53rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--cz-form-valid-border-color);
  box-shadow: var(--cz-box-shadow-inset), 0 0 0 0 rgba(var(--cz-success-rgb), 0.1);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 1.06rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--cz-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--cz-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0 rgba(var(--cz-success-rgb), 0.1);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--cz-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.375rem;
  font-size: 0.75rem;
  color: var(--cz-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.75rem;
  color: #fff;
  background-color: var(--cz-danger);
  border-radius: var(--cz-border-radius-sm);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--cz-form-invalid-border-color);
  padding-right: calc(1.5em + 1.06rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23f03d3d'%3e%3cpath d='M12,1.5C6.2,1.5,1.5,6.2,1.5,12S6.2,22.5,12,22.5S22.5,17.8,22.5,12S17.8,1.5,12,1.5z M12,20.7c-4.8,0-8.7-4-8.7-8.7S7.2,3.3,12,3.3s8.7,4,8.7,8.7S16.8,20.7,12,20.7z'/%3e%3cpath d='M15.7,14.5c0.3,0.3,0.3,0.9,0,1.2s-0.9,0.3-1.2,0L12,13.2l-2.5,2.5c-0.3,0.3-0.9,0.3-1.2,0s-0.3-0.9,0-1.2l2.5-2.5L8.3,9.5c-0.4-0.3-0.4-0.9,0-1.2c0.3-0.4,0.9-0.4,1.2,0l2.5,2.5l2.5-2.5c0.3-0.3,0.9-0.3,1.2,0s0.3,0.9,0,1.2L13.2,12L15.7,14.5z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.265rem) center;
  background-size: calc(0.75em + 0.53rem) calc(0.75em + 0.53rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--cz-form-invalid-border-color);
  box-shadow: var(--cz-box-shadow-inset), 0 0 0 0 rgba(var(--cz-danger-rgb), 0.1);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.06rem);
  background-position: top calc(0.375em + 0.265rem) right calc(0.375em + 0.265rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--cz-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --cz-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23f03d3d'%3e%3cpath d='M12,1.5C6.2,1.5,1.5,6.2,1.5,12S6.2,22.5,12,22.5S22.5,17.8,22.5,12S17.8,1.5,12,1.5z M12,20.7c-4.8,0-8.7-4-8.7-8.7S7.2,3.3,12,3.3s8.7,4,8.7,8.7S16.8,20.7,12,20.7z'/%3e%3cpath d='M15.7,14.5c0.3,0.3,0.3,0.9,0,1.2s-0.9,0.3-1.2,0L12,13.2l-2.5,2.5c-0.3,0.3-0.9,0.3-1.2,0s-0.3-0.9,0-1.2l2.5-2.5L8.3,9.5c-0.4-0.3-0.4-0.9,0-1.2c0.3-0.4,0.9-0.4,1.2,0l2.5,2.5l2.5-2.5c0.3-0.3,0.9-0.3,1.2,0s0.3,0.9,0,1.2L13.2,12L15.7,14.5z'/%3e%3c/svg%3e");
  padding-right: 4.75rem;
  background-position: right 1rem center, center right 2.25rem;
  background-size: 15px 11px, calc(0.75em + 0.53rem) calc(0.75em + 0.53rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--cz-form-invalid-border-color);
  box-shadow: var(--cz-box-shadow-inset), 0 0 0 0 rgba(var(--cz-danger-rgb), 0.1);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 1.06rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--cz-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--cz-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0 rgba(var(--cz-danger-rgb), 0.1);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--cz-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --cz-btn-padding-x: 1.25rem;
  --cz-btn-padding-y: 0.625rem;
  --cz-btn-font-family: ;
  --cz-btn-font-size: 0.875rem;
  --cz-btn-font-weight: 500;
  --cz-btn-line-height: 1.125rem;
  --cz-btn-color: var(--cz-component-color);
  --cz-btn-bg: transparent;
  --cz-btn-border-width: var(--cz-border-width);
  --cz-btn-border-color: transparent;
  --cz-btn-border-radius: var(--cz-border-radius);
  --cz-btn-hover-border-color: transparent;
  --cz-btn-box-shadow: none;
  --cz-btn-disabled-opacity: 0.55;
  --cz-btn-focus-box-shadow: 0 0 0 0 rgba(var(--cz-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--cz-btn-padding-y) var(--cz-btn-padding-x);
  font-family: var(--cz-btn-font-family);
  font-size: var(--cz-btn-font-size);
  font-weight: var(--cz-btn-font-weight);
  line-height: var(--cz-btn-line-height);
  color: var(--cz-btn-color);
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--cz-btn-border-width) solid var(--cz-btn-border-color);
  border-radius: var(--cz-btn-border-radius);
  background-color: var(--cz-btn-bg);
  box-shadow: var(--cz-btn-box-shadow);
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--cz-btn-hover-color);
  background-color: var(--cz-btn-hover-bg);
  border-color: var(--cz-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--cz-btn-color);
  background-color: var(--cz-btn-bg);
  border-color: var(--cz-btn-border-color);
}
.btn:focus-visible {
  color: var(--cz-btn-hover-color);
  background-color: var(--cz-btn-hover-bg);
  border-color: var(--cz-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--cz-btn-box-shadow), var(--cz-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--cz-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--cz-btn-box-shadow), var(--cz-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--cz-btn-active-color);
  background-color: var(--cz-btn-active-bg);
  border-color: var(--cz-btn-active-border-color);
  box-shadow: var(--cz-btn-active-shadow);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--cz-btn-active-shadow), var(--cz-btn-focus-box-shadow);
}
.btn-check:checked:focus-visible + .btn {
  box-shadow: var(--cz-btn-active-shadow), var(--cz-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--cz-btn-disabled-color);
  pointer-events: none;
  background-color: var(--cz-btn-disabled-bg);
  border-color: var(--cz-btn-disabled-border-color);
  opacity: var(--cz-btn-disabled-opacity);
  box-shadow: none;
}

.btn-primary, [data-bs-theme=dark] .product-card:hover .product-card-button, .product-card:hover .product-card-button,
.product-card .count-input:not(.collapsed) .product-card-button {
  --cz-btn-color: #fff;
  --cz-btn-bg: #2e6da4;
  --cz-btn-border-color: #2e6da4;
  --cz-btn-hover-color: #fff;
  --cz-btn-hover-bg: #275d8b;
  --cz-btn-hover-border-color: #255783;
  --cz-btn-focus-shadow-rgb: 77, 131, 178;
  --cz-btn-active-color: #fff;
  --cz-btn-active-bg: #255783;
  --cz-btn-active-border-color: #23527b;
  --cz-btn-active-shadow: none;
  --cz-btn-disabled-color: #fff;
  --cz-btn-disabled-bg: #2e6da4;
  --cz-btn-disabled-border-color: #2e6da4;
}

.btn-secondary {
  --cz-btn-color: #fff;
  --cz-btn-bg: #6c727f;
  --cz-btn-border-color: #6c727f;
  --cz-btn-hover-color: #fff;
  --cz-btn-hover-bg: #5c616c;
  --cz-btn-hover-border-color: #565b66;
  --cz-btn-focus-shadow-rgb: 130, 135, 146;
  --cz-btn-active-color: #fff;
  --cz-btn-active-bg: #565b66;
  --cz-btn-active-border-color: #51565f;
  --cz-btn-active-shadow: none;
  --cz-btn-disabled-color: #fff;
  --cz-btn-disabled-bg: #6c727f;
  --cz-btn-disabled-border-color: #6c727f;
}

.btn-success {
  --cz-btn-color: #fff;
  --cz-btn-bg: #33b36b;
  --cz-btn-border-color: #33b36b;
  --cz-btn-hover-color: #fff;
  --cz-btn-hover-bg: #2b985b;
  --cz-btn-hover-border-color: #298f56;
  --cz-btn-focus-shadow-rgb: 82, 190, 129;
  --cz-btn-active-color: #fff;
  --cz-btn-active-bg: #298f56;
  --cz-btn-active-border-color: #268650;
  --cz-btn-active-shadow: none;
  --cz-btn-disabled-color: #fff;
  --cz-btn-disabled-bg: #33b36b;
  --cz-btn-disabled-border-color: #33b36b;
}

.btn-info {
  --cz-btn-color: #fff;
  --cz-btn-bg: #2f6ed5;
  --cz-btn-border-color: #2f6ed5;
  --cz-btn-hover-color: #fff;
  --cz-btn-hover-bg: #285eb5;
  --cz-btn-hover-border-color: #2658aa;
  --cz-btn-focus-shadow-rgb: 78, 132, 219;
  --cz-btn-active-color: #fff;
  --cz-btn-active-bg: #2658aa;
  --cz-btn-active-border-color: #2353a0;
  --cz-btn-active-shadow: none;
  --cz-btn-disabled-color: #fff;
  --cz-btn-disabled-bg: #2f6ed5;
  --cz-btn-disabled-border-color: #2f6ed5;
}

.btn-warning {
  --cz-btn-color: #fff;
  --cz-btn-bg: #fc9231;
  --cz-btn-border-color: #fc9231;
  --cz-btn-hover-color: #fff;
  --cz-btn-hover-bg: #d67c2a;
  --cz-btn-hover-border-color: #ca7527;
  --cz-btn-focus-shadow-rgb: 252, 162, 80;
  --cz-btn-active-color: #fff;
  --cz-btn-active-bg: #ca7527;
  --cz-btn-active-border-color: #bd6e25;
  --cz-btn-active-shadow: none;
  --cz-btn-disabled-color: #fff;
  --cz-btn-disabled-bg: #fc9231;
  --cz-btn-disabled-border-color: #fc9231;
}

.btn-danger {
  --cz-btn-color: #fff;
  --cz-btn-bg: #f03d3d;
  --cz-btn-border-color: #f03d3d;
  --cz-btn-hover-color: #fff;
  --cz-btn-hover-bg: #cc3434;
  --cz-btn-hover-border-color: #c03131;
  --cz-btn-focus-shadow-rgb: 242, 90, 90;
  --cz-btn-active-color: #fff;
  --cz-btn-active-bg: #c03131;
  --cz-btn-active-border-color: #b42e2e;
  --cz-btn-active-shadow: none;
  --cz-btn-disabled-color: #fff;
  --cz-btn-disabled-bg: #f03d3d;
  --cz-btn-disabled-border-color: #f03d3d;
}

.btn-light, [data-bs-theme=dark] .btn-dark:not([data-bs-theme=light]) {
  --cz-btn-color: #000;
  --cz-btn-bg: #fff;
  --cz-btn-border-color: #fff;
  --cz-btn-hover-color: #000;
  --cz-btn-hover-bg: #d9d9d9;
  --cz-btn-hover-border-color: #cccccc;
  --cz-btn-focus-shadow-rgb: 217, 217, 217;
  --cz-btn-active-color: #000;
  --cz-btn-active-bg: #cccccc;
  --cz-btn-active-border-color: #bfbfbf;
  --cz-btn-active-shadow: none;
  --cz-btn-disabled-color: #000;
  --cz-btn-disabled-bg: #fff;
  --cz-btn-disabled-border-color: #fff;
}

.btn-dark {
  --cz-btn-color: #fff;
  --cz-btn-bg: #222934;
  --cz-btn-border-color: #222934;
  --cz-btn-hover-color: #fff;
  --cz-btn-hover-bg: #434952;
  --cz-btn-hover-border-color: #383e48;
  --cz-btn-focus-shadow-rgb: 67, 73, 82;
  --cz-btn-active-color: #fff;
  --cz-btn-active-bg: #4e545d;
  --cz-btn-active-border-color: #383e48;
  --cz-btn-active-shadow: none;
  --cz-btn-disabled-color: #fff;
  --cz-btn-disabled-bg: #222934;
  --cz-btn-disabled-border-color: #222934;
}

.btn-outline-primary {
  --cz-btn-color: #2e6da4;
  --cz-btn-border-color: #2e6da4;
  --cz-btn-hover-color: #fff;
  --cz-btn-hover-bg: #2e6da4;
  --cz-btn-hover-border-color: #2e6da4;
  --cz-btn-focus-shadow-rgb: 46, 109, 164;
  --cz-btn-active-color: #fff;
  --cz-btn-active-bg: #2e6da4;
  --cz-btn-active-border-color: #2e6da4;
  --cz-btn-active-shadow: none;
  --cz-btn-disabled-color: #2e6da4;
  --cz-btn-disabled-bg: transparent;
  --cz-btn-disabled-border-color: #2e6da4;
  --cz-gradient: none;
}

.btn-outline-secondary {
  --cz-btn-color: #6c727f;
  --cz-btn-border-color: #6c727f;
  --cz-btn-hover-color: #fff;
  --cz-btn-hover-bg: #6c727f;
  --cz-btn-hover-border-color: #6c727f;
  --cz-btn-focus-shadow-rgb: 108, 114, 127;
  --cz-btn-active-color: #fff;
  --cz-btn-active-bg: #6c727f;
  --cz-btn-active-border-color: #6c727f;
  --cz-btn-active-shadow: none;
  --cz-btn-disabled-color: #6c727f;
  --cz-btn-disabled-bg: transparent;
  --cz-btn-disabled-border-color: #6c727f;
  --cz-gradient: none;
}

.btn-outline-success {
  --cz-btn-color: #33b36b;
  --cz-btn-border-color: #33b36b;
  --cz-btn-hover-color: #fff;
  --cz-btn-hover-bg: #33b36b;
  --cz-btn-hover-border-color: #33b36b;
  --cz-btn-focus-shadow-rgb: 51, 179, 107;
  --cz-btn-active-color: #fff;
  --cz-btn-active-bg: #33b36b;
  --cz-btn-active-border-color: #33b36b;
  --cz-btn-active-shadow: none;
  --cz-btn-disabled-color: #33b36b;
  --cz-btn-disabled-bg: transparent;
  --cz-btn-disabled-border-color: #33b36b;
  --cz-gradient: none;
}

.btn-outline-info {
  --cz-btn-color: #2f6ed5;
  --cz-btn-border-color: #2f6ed5;
  --cz-btn-hover-color: #fff;
  --cz-btn-hover-bg: #2f6ed5;
  --cz-btn-hover-border-color: #2f6ed5;
  --cz-btn-focus-shadow-rgb: 47, 110, 213;
  --cz-btn-active-color: #fff;
  --cz-btn-active-bg: #2f6ed5;
  --cz-btn-active-border-color: #2f6ed5;
  --cz-btn-active-shadow: none;
  --cz-btn-disabled-color: #2f6ed5;
  --cz-btn-disabled-bg: transparent;
  --cz-btn-disabled-border-color: #2f6ed5;
  --cz-gradient: none;
}

.btn-outline-warning {
  --cz-btn-color: #fc9231;
  --cz-btn-border-color: #fc9231;
  --cz-btn-hover-color: #fff;
  --cz-btn-hover-bg: #fc9231;
  --cz-btn-hover-border-color: #fc9231;
  --cz-btn-focus-shadow-rgb: 252, 146, 49;
  --cz-btn-active-color: #fff;
  --cz-btn-active-bg: #fc9231;
  --cz-btn-active-border-color: #fc9231;
  --cz-btn-active-shadow: none;
  --cz-btn-disabled-color: #fc9231;
  --cz-btn-disabled-bg: transparent;
  --cz-btn-disabled-border-color: #fc9231;
  --cz-gradient: none;
}

.btn-outline-danger {
  --cz-btn-color: #f03d3d;
  --cz-btn-border-color: #f03d3d;
  --cz-btn-hover-color: #fff;
  --cz-btn-hover-bg: #f03d3d;
  --cz-btn-hover-border-color: #f03d3d;
  --cz-btn-focus-shadow-rgb: 240, 61, 61;
  --cz-btn-active-color: #fff;
  --cz-btn-active-bg: #f03d3d;
  --cz-btn-active-border-color: #f03d3d;
  --cz-btn-active-shadow: none;
  --cz-btn-disabled-color: #f03d3d;
  --cz-btn-disabled-bg: transparent;
  --cz-btn-disabled-border-color: #f03d3d;
  --cz-gradient: none;
}

.btn-outline-light {
  --cz-btn-color: #fff;
  --cz-btn-border-color: #fff;
  --cz-btn-hover-color: #000;
  --cz-btn-hover-bg: #fff;
  --cz-btn-hover-border-color: #fff;
  --cz-btn-focus-shadow-rgb: 255, 255, 255;
  --cz-btn-active-color: #000;
  --cz-btn-active-bg: #fff;
  --cz-btn-active-border-color: #fff;
  --cz-btn-active-shadow: none;
  --cz-btn-disabled-color: #fff;
  --cz-btn-disabled-bg: transparent;
  --cz-btn-disabled-border-color: #fff;
  --cz-gradient: none;
}

.btn-outline-dark {
  --cz-btn-color: #222934;
  --cz-btn-border-color: #222934;
  --cz-btn-hover-color: #fff;
  --cz-btn-hover-bg: #222934;
  --cz-btn-hover-border-color: #222934;
  --cz-btn-focus-shadow-rgb: 34, 41, 52;
  --cz-btn-active-color: #fff;
  --cz-btn-active-bg: #222934;
  --cz-btn-active-border-color: #222934;
  --cz-btn-active-shadow: none;
  --cz-btn-disabled-color: #222934;
  --cz-btn-disabled-bg: transparent;
  --cz-btn-disabled-border-color: #222934;
  --cz-gradient: none;
}

.btn-link {
  --cz-btn-font-weight: 400;
  --cz-btn-color: var(--cz-link-color);
  --cz-btn-bg: transparent;
  --cz-btn-border-color: transparent;
  --cz-btn-hover-color: var(--cz-link-hover-color);
  --cz-btn-hover-border-color: transparent;
  --cz-btn-active-color: var(--cz-link-hover-color);
  --cz-btn-active-border-color: transparent;
  --cz-btn-disabled-color: var(--cz-component-disabled-color);
  --cz-btn-disabled-border-color: transparent;
  --cz-btn-box-shadow: 0 0 0 #000;
  --cz-btn-focus-shadow-rgb: 77, 131, 178;
  text-decoration: underline;
}
.btn-link:hover, .btn-link:focus-visible {
  text-decoration: none;
}
.btn-link:focus-visible {
  color: var(--cz-btn-color);
}
.btn-link:hover {
  color: var(--cz-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --cz-btn-padding-y: 0.875rem;
  --cz-btn-padding-x: 1.5rem;
  --cz-btn-font-size: 1rem;
  --cz-btn-border-radius: var(--cz-border-radius);
}

.btn-sm, .btn-group-sm > .btn {
  --cz-btn-padding-y: 0.375rem;
  --cz-btn-padding-x: 1rem;
  --cz-btn-font-size: 0.75rem;
  --cz-btn-border-radius: var(--cz-border-radius-sm);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.15em;
  vertical-align: 1.08375em;
  content: "";
  border-top: 1.275em solid;
  border-right: 1.275em solid transparent;
  border-bottom: 0;
  border-left: 1.275em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --cz-dropdown-zindex: 1000;
  --cz-dropdown-min-width: 12.5rem;
  --cz-dropdown-padding-x: 0.75rem;
  --cz-dropdown-padding-y: 0.75rem;
  --cz-dropdown-spacer: 0.25rem;
  --cz-dropdown-font-size: 0.875rem;
  --cz-dropdown-color: var(--cz-component-color);
  --cz-dropdown-bg: var(--cz-body-bg);
  --cz-dropdown-border-color: var(--cz-light-border-subtle);
  --cz-dropdown-border-radius: var(--cz-border-radius);
  --cz-dropdown-border-width: var(--cz-border-width);
  --cz-dropdown-inner-border-radius: calc(var(--cz-border-radius) - var(--cz-border-width));
  --cz-dropdown-divider-bg: var(--cz-border-color);
  --cz-dropdown-divider-margin-y: 0.375rem;
  --cz-dropdown-box-shadow: var(--cz-box-shadow);
  --cz-dropdown-link-color: var(--cz-component-color);
  --cz-dropdown-link-hover-color: var(--cz-component-hover-color);
  --cz-dropdown-link-hover-bg: var(--cz-component-hover-bg);
  --cz-dropdown-link-active-color: var(--cz-component-active-color);
  --cz-dropdown-link-active-bg: var(--cz-component-active-bg);
  --cz-dropdown-link-disabled-color: var(--cz-component-disabled-color);
  --cz-dropdown-item-padding-x: 0.75rem;
  --cz-dropdown-item-padding-y: 0.5rem;
  --cz-dropdown-header-color: var(--cz-heading-color);
  --cz-dropdown-header-padding-x: 0.75rem;
  --cz-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--cz-dropdown-zindex);
  display: none;
  min-width: var(--cz-dropdown-min-width);
  padding: var(--cz-dropdown-padding-y) var(--cz-dropdown-padding-x);
  margin: 0;
  font-size: var(--cz-dropdown-font-size);
  color: var(--cz-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--cz-dropdown-bg);
  background-clip: padding-box;
  border: var(--cz-dropdown-border-width) solid var(--cz-dropdown-border-color);
  border-radius: var(--cz-dropdown-border-radius);
  box-shadow: var(--cz-dropdown-box-shadow);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--cz-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 500px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--cz-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.15em;
  vertical-align: 1.08375em;
  content: "";
  border-top: 0;
  border-right: 1.275em solid transparent;
  border-bottom: 1.275em solid;
  border-left: 1.275em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--cz-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.15em;
  vertical-align: 1.08375em;
  content: "";
  border-top: 1.275em solid transparent;
  border-right: 0;
  border-bottom: 1.275em solid transparent;
  border-left: 1.275em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--cz-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.15em;
  vertical-align: 1.08375em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.15em;
  vertical-align: 1.08375em;
  content: "";
  border-top: 1.275em solid transparent;
  border-right: 1.275em solid;
  border-bottom: 1.275em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--cz-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--cz-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--cz-dropdown-item-padding-y) var(--cz-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--cz-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--cz-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--cz-dropdown-link-hover-color);
  background-color: var(--cz-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--cz-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--cz-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--cz-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--cz-dropdown-header-padding-y) var(--cz-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--cz-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--cz-dropdown-item-padding-y) var(--cz-dropdown-item-padding-x);
  color: var(--cz-dropdown-link-color);
}

.dropdown-menu-dark {
  --cz-dropdown-color: #e0e5eb;
  --cz-dropdown-bg: #181d25;
  --cz-dropdown-border-color: #333d4c;
  --cz-dropdown-box-shadow: 0 0.5rem 1.875rem -0.25rem rgba(8, 11, 18, 0.35);
  --cz-dropdown-link-color: #e0e5eb;
  --cz-dropdown-link-hover-color: #fff;
  --cz-dropdown-divider-bg: var(--cz-border-color);
  --cz-dropdown-link-hover-bg: #222934;
  --cz-dropdown-link-active-color: #fff;
  --cz-dropdown-link-active-bg: #333d4c;
  --cz-dropdown-link-disabled-color: #6c727f;
  --cz-dropdown-header-color: #fff;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--cz-border-radius);
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--cz-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: none;
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--cz-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --cz-nav-link-padding-x: 1.25rem;
  --cz-nav-link-padding-y: 0.5rem;
  --cz-nav-link-font-size: 0.875rem;
  --cz-nav-link-font-weight: 500;
  --cz-nav-link-color: var(--cz-component-color);
  --cz-nav-link-hover-color: var(--cz-component-hover-color);
  --cz-nav-link-disabled-color: var(--cz-component-disabled-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--cz-nav-link-padding-y) var(--cz-nav-link-padding-x);
  font-size: var(--cz-nav-link-font-size);
  font-weight: var(--cz-nav-link-font-weight);
  color: var(--cz-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--cz-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem var(--cz-focus-ring-color);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--cz-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --cz-nav-tabs-border-width: 0;
  --cz-nav-tabs-border-color: transparent;
  --cz-nav-tabs-border-radius: var(--cz-border-radius);
  --cz-nav-tabs-link-hover-border-color: transparent;
  --cz-nav-tabs-link-active-color: var(--cz-emphasis-color);
  --cz-nav-tabs-link-active-bg: #fff;
  --cz-nav-tabs-link-active-border-color: transparent;
  border-bottom: var(--cz-nav-tabs-border-width) solid var(--cz-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--cz-nav-tabs-border-width));
  border: var(--cz-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--cz-nav-tabs-border-radius);
  border-top-right-radius: var(--cz-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--cz-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--cz-nav-tabs-link-active-color);
  background-color: var(--cz-nav-tabs-link-active-bg);
  border-color: var(--cz-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--cz-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --cz-nav-pills-border-radius: 50rem;
  --cz-nav-pills-link-active-color: var(--cz-component-active-color);
  --cz-nav-pills-link-active-bg: transparent;
}
.nav-pills .nav-link {
  border-radius: var(--cz-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--cz-nav-pills-link-active-color);
  background-color: var(--cz-nav-pills-link-active-bg);
}

.nav-underline {
  --cz-nav-underline-gap: 0.5rem 1.75rem;
  --cz-nav-underline-border-width: var(--cz-border-width);
  --cz-nav-underline-link-active-color: var(--cz-component-active-color);
  gap: var(--cz-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--cz-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--cz-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --cz-navbar-padding-x: 0.625rem;
  --cz-navbar-padding-y: 0.625rem;
  --cz-navbar-color: #333d4c;
  --cz-navbar-hover-color: #181d25;
  --cz-navbar-disabled-color: #9ca3af;
  --cz-navbar-active-color: #181d25;
  --cz-navbar-brand-padding-y: 0.125rem;
  --cz-navbar-brand-margin-end: 1rem;
  --cz-navbar-brand-font-size: 1.5rem;
  --cz-navbar-brand-color: #181d25;
  --cz-navbar-brand-hover-color: #181d25;
  --cz-navbar-nav-link-padding-x: 1.125rem;
  --cz-navbar-toggler-padding-y: 0.625rem;
  --cz-navbar-toggler-padding-x: 0.25rem;
  --cz-navbar-toggler-font-size: 1.125rem;
  --cz-navbar-toggler-icon-bg: initial;
  --cz-navbar-toggler-border-color: transparent;
  --cz-navbar-toggler-border-radius: 0;
  --cz-navbar-toggler-focus-width: 0;
  --cz-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--cz-navbar-padding-y) var(--cz-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--cz-navbar-brand-padding-y);
  padding-bottom: var(--cz-navbar-brand-padding-y);
  margin-right: var(--cz-navbar-brand-margin-end);
  font-size: var(--cz-navbar-brand-font-size);
  color: var(--cz-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--cz-navbar-brand-hover-color);
}

.navbar-nav {
  --cz-nav-link-padding-x: 0;
  --cz-nav-link-padding-y: 0.5rem;
  --cz-nav-link-font-size: 0.875rem;
  --cz-nav-link-font-weight: 500;
  --cz-nav-link-color: var(--cz-navbar-color);
  --cz-nav-link-hover-color: var(--cz-navbar-hover-color);
  --cz-nav-link-disabled-color: var(--cz-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--cz-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--cz-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--cz-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--cz-navbar-toggler-padding-y) var(--cz-navbar-toggler-padding-x);
  font-size: var(--cz-navbar-toggler-font-size);
  line-height: 1;
  color: var(--cz-navbar-color);
  background-color: transparent;
  border: var(--cz-border-width) solid var(--cz-navbar-toggler-border-color);
  border-radius: var(--cz-navbar-toggler-border-radius);
  transition: var(--cz-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--cz-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--cz-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--cz-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 500px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--cz-navbar-nav-link-padding-x);
    padding-left: var(--cz-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--cz-navbar-nav-link-padding-x);
    padding-left: var(--cz-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--cz-navbar-nav-link-padding-x);
    padding-left: var(--cz-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--cz-navbar-nav-link-padding-x);
    padding-left: var(--cz-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--cz-navbar-nav-link-padding-x);
    padding-left: var(--cz-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--cz-navbar-nav-link-padding-x);
  padding-left: var(--cz-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  box-shadow: none;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --cz-navbar-color: rgba(255, 255, 255, 0.8);
  --cz-navbar-hover-color: #fff;
  --cz-navbar-disabled-color: rgba(255, 255, 255, 0.4);
  --cz-navbar-active-color: #fff;
  --cz-navbar-brand-color: #fff;
  --cz-navbar-brand-hover-color: #fff;
  --cz-navbar-toggler-border-color: transparent;
  --cz-navbar-toggler-icon-bg: initial;
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --cz-navbar-toggler-icon-bg: initial;
}

.card {
  --cz-card-spacer-y: 1.5rem;
  --cz-card-spacer-x: 1.5rem;
  --cz-card-title-spacer-y: 0.75rem;
  --cz-card-title-color: var(--cz-heading-color);
  --cz-card-subtitle-color: ;
  --cz-card-border-width: var(--cz-border-width);
  --cz-card-border-color: var(--cz-border-color-translucent);
  --cz-card-border-radius: var(--cz-border-radius);
  --cz-card-box-shadow: ;
  --cz-card-inner-border-radius: calc(var(--cz-border-radius) - (var(--cz-border-width)));
  --cz-card-cap-padding-y: 0.75rem;
  --cz-card-cap-padding-x: 1.5rem;
  --cz-card-cap-bg: rgba(var(--cz-body-color-rgb), 0.03);
  --cz-card-cap-color: ;
  --cz-card-height: ;
  --cz-card-color: ;
  --cz-card-bg: var(--cz-body-bg);
  --cz-card-img-overlay-padding: 1.5rem;
  --cz-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--cz-card-height);
  color: var(--cz-body-color);
  word-wrap: break-word;
  background-color: var(--cz-card-bg);
  background-clip: border-box;
  border: var(--cz-card-border-width) solid var(--cz-card-border-color);
  border-radius: var(--cz-card-border-radius);
  box-shadow: var(--cz-card-box-shadow);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--cz-card-inner-border-radius);
  border-top-right-radius: var(--cz-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--cz-card-inner-border-radius);
  border-bottom-left-radius: var(--cz-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--cz-card-spacer-y) var(--cz-card-spacer-x);
  color: var(--cz-card-color);
}

.card-title {
  margin-bottom: var(--cz-card-title-spacer-y);
  color: var(--cz-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--cz-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--cz-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--cz-card-spacer-x);
}

.card-header {
  padding: var(--cz-card-cap-padding-y) var(--cz-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--cz-card-cap-color);
  background-color: var(--cz-card-cap-bg);
  border-bottom: var(--cz-card-border-width) solid var(--cz-card-border-color);
}
.card-header:first-child {
  border-radius: var(--cz-card-inner-border-radius) var(--cz-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--cz-card-cap-padding-y) var(--cz-card-cap-padding-x);
  color: var(--cz-card-cap-color);
  background-color: var(--cz-card-cap-bg);
  border-top: var(--cz-card-border-width) solid var(--cz-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--cz-card-inner-border-radius) var(--cz-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--cz-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--cz-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--cz-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--cz-card-bg);
  border-bottom-color: var(--cz-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--cz-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--cz-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--cz-card-img-overlay-padding);
  border-radius: var(--cz-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--cz-card-inner-border-radius);
  border-top-right-radius: var(--cz-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--cz-card-inner-border-radius);
  border-bottom-left-radius: var(--cz-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--cz-card-group-margin);
}
@media (min-width: 500px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --cz-accordion-color: var(--cz-body-color);
  --cz-accordion-bg: transparent;
  --cz-accordion-transition: none;
  --cz-accordion-border-color: var(--cz-border-color);
  --cz-accordion-border-width: var(--cz-border-width);
  --cz-accordion-border-radius: 0;
  --cz-accordion-inner-border-radius: 0;
  --cz-accordion-btn-padding-x: 0;
  --cz-accordion-btn-padding-y: 1.25rem;
  --cz-accordion-btn-color: var(--cz-heading-color);
  --cz-accordion-btn-bg: var(--cz-accordion-bg);
  --cz-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23181d25'%3e%3cpath d='M3.8,7.9c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7l6.7-6.7c0.4-0.4,1.1-0.4,1.5,0c0.4,0.4,0.4,1.1,0,1.5l-7.4,7.4c-0.4,0.4-1.1,0.4-1.5,0L3.8,9.4C3.3,9.1,3.3,8.4,3.8,7.9z'/%3e%3c/svg%3e");
  --cz-accordion-btn-icon-width: 1em;
  --cz-accordion-btn-icon-transform: rotate(-180deg);
  --cz-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --cz-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23181d25'%3e%3cpath d='M3.8,7.9c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7l6.7-6.7c0.4-0.4,1.1-0.4,1.5,0c0.4,0.4,0.4,1.1,0,1.5l-7.4,7.4c-0.4,0.4-1.1,0.4-1.5,0L3.8,9.4C3.3,9.1,3.3,8.4,3.8,7.9z'/%3e%3c/svg%3e");
  --cz-accordion-btn-focus-box-shadow: unset;
  --cz-accordion-body-padding-x: 0;
  --cz-accordion-body-padding-y: 0;
  --cz-accordion-active-color: var(--cz-heading-color);
  --cz-accordion-active-bg: transparent;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--cz-accordion-btn-padding-y) var(--cz-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--cz-accordion-btn-color);
  text-align: left;
  background-color: var(--cz-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--cz-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--cz-accordion-active-color);
  background-color: var(--cz-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--cz-accordion-border-width)) 0 var(--cz-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--cz-accordion-btn-active-icon);
  transform: var(--cz-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--cz-accordion-btn-icon-width);
  height: var(--cz-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--cz-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--cz-accordion-btn-icon-width);
  transition: var(--cz-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: var(--cz-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--cz-accordion-color);
  background-color: var(--cz-accordion-bg);
  border: var(--cz-accordion-border-width) solid var(--cz-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--cz-accordion-border-radius);
  border-top-right-radius: var(--cz-accordion-border-radius);
}
.accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-left-radius: var(--cz-accordion-inner-border-radius);
  border-top-right-radius: var(--cz-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--cz-accordion-border-radius);
  border-bottom-left-radius: var(--cz-accordion-border-radius);
}
.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--cz-accordion-inner-border-radius);
  border-bottom-left-radius: var(--cz-accordion-inner-border-radius);
}
.accordion-item:last-of-type > .accordion-collapse {
  border-bottom-right-radius: var(--cz-accordion-border-radius);
  border-bottom-left-radius: var(--cz-accordion-border-radius);
}

.accordion-body {
  padding: var(--cz-accordion-body-padding-y) var(--cz-accordion-body-padding-x);
}

.accordion-flush > .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush > .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush > .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush > .accordion-item > .accordion-header .accordion-button, .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed {
  border-radius: 0;
}
.accordion-flush > .accordion-item > .accordion-collapse {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --cz-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23fff'%3e%3cpath d='M3.8,7.9c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7l6.7-6.7c0.4-0.4,1.1-0.4,1.5,0c0.4,0.4,0.4,1.1,0,1.5l-7.4,7.4c-0.4,0.4-1.1,0.4-1.5,0L3.8,9.4C3.3,9.1,3.3,8.4,3.8,7.9z'/%3e%3c/svg%3e");
  --cz-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23fff'%3e%3cpath d='M3.8,7.9c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7l6.7-6.7c0.4-0.4,1.1-0.4,1.5,0c0.4,0.4,0.4,1.1,0,1.5l-7.4,7.4c-0.4,0.4-1.1,0.4-1.5,0L3.8,9.4C3.3,9.1,3.3,8.4,3.8,7.9z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --cz-breadcrumb-padding-x: 0;
  --cz-breadcrumb-padding-y: 0;
  --cz-breadcrumb-margin-bottom: 1rem;
  --cz-breadcrumb-font-size: 0.875rem;
  --cz-breadcrumb-bg: ;
  --cz-breadcrumb-border-radius: ;
  --cz-breadcrumb-divider-color: #333d4c;
  --cz-breadcrumb-item-padding-x: 0.375rem;
  --cz-breadcrumb-item-active-color: var(--cz-component-disabled-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--cz-breadcrumb-padding-y) var(--cz-breadcrumb-padding-x);
  margin-bottom: var(--cz-breadcrumb-margin-bottom);
  font-size: var(--cz-breadcrumb-font-size);
  list-style: none;
  background-color: var(--cz-breadcrumb-bg);
  border-radius: var(--cz-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--cz-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--cz-breadcrumb-item-padding-x);
  color: var(--cz-breadcrumb-divider-color);
  content: var(--cz-breadcrumb-divider, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23333d4c'%3e%3cpath d='M8.381 5.381c.342-.342.896-.342 1.237 0l6 6c.342.342.342.896 0 1.237l-6 6c-.342.342-.896.342-1.237 0s-.342-.896 0-1.237L13.763 12 8.381 6.619c-.342-.342-.342-.896 0-1.237z'/%3e%3c/svg%3e")) /* rtl: var(--cz-breadcrumb-divider, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23333d4c'%3e%3cpath d='M15.3,5.4c0.3,0.3,0.3,0.9,0,1.2L9.9,12l5.4,5.4c0.3,0.3,0.3,0.9,0,1.2s-0.9,0.3-1.2,0l-6-6c-0.3-0.3-0.3-0.9,0-1.2l6-6C14.4,5,14.9,5,15.3,5.4L15.3,5.4z'/%3e%3c/svg%3e")) */;
}
.breadcrumb-item.active {
  color: var(--cz-breadcrumb-item-active-color);
}

.pagination {
  --cz-pagination-padding-x: 0.75rem;
  --cz-pagination-padding-y: 0.375rem;
  --cz-pagination-font-size: 0.875rem;
  --cz-pagination-color: var(--cz-component-color);
  --cz-pagination-bg: transparent;
  --cz-pagination-border-width: 0;
  --cz-pagination-border-color: var(--cz-border-color);
  --cz-pagination-border-radius: var(--cz-border-radius-sm);
  --cz-pagination-hover-color: var(--cz-component-hover-color);
  --cz-pagination-hover-bg: var(--cz-component-hover-bg);
  --cz-pagination-hover-border-color: var(--cz-border-color);
  --cz-pagination-focus-color: var(--cz-component-hover-color);
  --cz-pagination-focus-bg: var(--cz-component-hover-bg);
  --cz-pagination-focus-box-shadow: 0 0 0 0.25rem var(--cz-focus-ring-color);
  --cz-pagination-active-color: var(--cz-component-active-color);
  --cz-pagination-active-bg: var(--cz-component-active-bg);
  --cz-pagination-active-border-color: #eef1f6;
  --cz-pagination-disabled-color: var(--cz-component-disabled-color);
  --cz-pagination-disabled-bg: transparent;
  --cz-pagination-disabled-border-color: var(--cz-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--cz-pagination-padding-y) var(--cz-pagination-padding-x);
  font-size: var(--cz-pagination-font-size);
  color: var(--cz-pagination-color);
  text-decoration: none;
  background-color: var(--cz-pagination-bg);
  border: var(--cz-pagination-border-width) solid var(--cz-pagination-border-color);
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--cz-pagination-hover-color);
  background-color: var(--cz-pagination-hover-bg);
  border-color: var(--cz-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--cz-pagination-focus-color);
  background-color: var(--cz-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--cz-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--cz-pagination-active-color);
  background-color: var(--cz-pagination-active-bg);
  border-color: var(--cz-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--cz-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--cz-pagination-disabled-bg);
  border-color: var(--cz-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: 0;
}
.page-item .page-link {
  border-radius: var(--cz-pagination-border-radius);
}

.pagination-lg {
  --cz-pagination-padding-x: 0.9375rem;
  --cz-pagination-padding-y: 0.5rem;
  --cz-pagination-font-size: 1.125rem;
  --cz-pagination-border-radius: var(--cz-border-radius);
}

.pagination-sm {
  --cz-pagination-padding-x: 0.5rem;
  --cz-pagination-padding-y: 0.1875rem;
  --cz-pagination-font-size: 0.875rem;
  --cz-pagination-border-radius: var(--cz-border-radius-xs);
}

.badge {
  --cz-badge-padding-x: 0.65em;
  --cz-badge-padding-y: 0.35em;
  --cz-badge-font-size: 0.75em;
  --cz-badge-font-weight: 500;
  --cz-badge-color: #fff;
  --cz-badge-border-radius: 0.33em;
  display: inline-block;
  padding: var(--cz-badge-padding-y) var(--cz-badge-padding-x);
  font-size: var(--cz-badge-font-size);
  font-weight: var(--cz-badge-font-weight);
  line-height: 1;
  color: var(--cz-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--cz-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --cz-alert-bg: transparent;
  --cz-alert-padding-x: 1rem;
  --cz-alert-padding-y: 1rem;
  --cz-alert-margin-bottom: 1.25rem;
  --cz-alert-color: inherit;
  --cz-alert-border-color: transparent;
  --cz-alert-border: var(--cz-border-width) solid var(--cz-alert-border-color);
  --cz-alert-border-radius: var(--cz-border-radius);
  --cz-alert-link-color: inherit;
  position: relative;
  padding: var(--cz-alert-padding-y) var(--cz-alert-padding-x);
  margin-bottom: var(--cz-alert-margin-bottom);
  color: var(--cz-alert-color);
  background-color: var(--cz-alert-bg);
  border: var(--cz-alert-border);
  border-radius: var(--cz-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
  color: var(--cz-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --cz-alert-color: var(--cz-primary-text-emphasis);
  --cz-alert-bg: var(--cz-primary-bg-subtle);
  --cz-alert-border-color: var(--cz-primary-border-subtle);
  --cz-alert-link-color: var(--cz-primary-text-emphasis);
}

.alert-secondary {
  --cz-alert-color: var(--cz-secondary-text-emphasis);
  --cz-alert-bg: var(--cz-secondary-bg-subtle);
  --cz-alert-border-color: var(--cz-secondary-border-subtle);
  --cz-alert-link-color: var(--cz-secondary-text-emphasis);
}

.alert-success {
  --cz-alert-color: var(--cz-success-text-emphasis);
  --cz-alert-bg: var(--cz-success-bg-subtle);
  --cz-alert-border-color: var(--cz-success-border-subtle);
  --cz-alert-link-color: var(--cz-success-text-emphasis);
}

.alert-info {
  --cz-alert-color: var(--cz-info-text-emphasis);
  --cz-alert-bg: var(--cz-info-bg-subtle);
  --cz-alert-border-color: var(--cz-info-border-subtle);
  --cz-alert-link-color: var(--cz-info-text-emphasis);
}

.alert-warning {
  --cz-alert-color: var(--cz-warning-text-emphasis);
  --cz-alert-bg: var(--cz-warning-bg-subtle);
  --cz-alert-border-color: var(--cz-warning-border-subtle);
  --cz-alert-link-color: var(--cz-warning-text-emphasis);
}

.alert-danger {
  --cz-alert-color: var(--cz-danger-text-emphasis);
  --cz-alert-bg: var(--cz-danger-bg-subtle);
  --cz-alert-border-color: var(--cz-danger-border-subtle);
  --cz-alert-link-color: var(--cz-danger-text-emphasis);
}

.alert-light {
  --cz-alert-color: var(--cz-light-text-emphasis);
  --cz-alert-bg: var(--cz-light-bg-subtle);
  --cz-alert-border-color: var(--cz-light-border-subtle);
  --cz-alert-link-color: var(--cz-light-text-emphasis);
}

.alert-dark {
  --cz-alert-color: var(--cz-dark-text-emphasis);
  --cz-alert-bg: var(--cz-dark-bg-subtle);
  --cz-alert-border-color: var(--cz-dark-border-subtle);
  --cz-alert-link-color: var(--cz-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --cz-progress-height: 1rem;
  --cz-progress-font-size: 0.75rem;
  --cz-progress-bg: var(--cz-secondary-bg);
  --cz-progress-border-radius: 50rem;
  --cz-progress-box-shadow: none;
  --cz-progress-bar-color: #fff;
  --cz-progress-bar-bg: #2e6da4;
  --cz-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--cz-progress-height);
  overflow: hidden;
  font-size: var(--cz-progress-font-size);
  background-color: var(--cz-progress-bg);
  border-radius: var(--cz-progress-border-radius);
  box-shadow: var(--cz-progress-box-shadow);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--cz-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--cz-progress-bar-bg);
  transition: var(--cz-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--cz-progress-height) var(--cz-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --cz-list-group-color: var(--cz-body-color);
  --cz-list-group-bg: transparent;
  --cz-list-group-border-color: var(--cz-border-color);
  --cz-list-group-border-width: var(--cz-border-width);
  --cz-list-group-border-radius: var(--cz-border-radius);
  --cz-list-group-item-padding-x: 0.75rem;
  --cz-list-group-item-padding-y: 0.625rem;
  --cz-list-group-action-color: var(--cz-component-color);
  --cz-list-group-action-hover-color: var(--cz-component-hover-color);
  --cz-list-group-action-hover-bg: var(--cz-component-hover-bg);
  --cz-list-group-action-active-color: var(--cz-component-active-color);
  --cz-list-group-action-active-bg: var(--cz-component-active-bg);
  --cz-list-group-disabled-color: var(--cz-component-disabled-color);
  --cz-list-group-disabled-bg: transparent;
  --cz-list-group-active-color: var(--cz-component-active-color);
  --cz-list-group-active-bg: var(--cz-component-active-bg);
  --cz-list-group-active-border-color: var(--cz-border-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--cz-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--cz-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--cz-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--cz-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--cz-list-group-action-active-color);
  background-color: var(--cz-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--cz-list-group-item-padding-y) var(--cz-list-group-item-padding-x);
  color: var(--cz-list-group-color);
  text-decoration: none;
  background-color: var(--cz-list-group-bg);
  border: var(--cz-list-group-border-width) solid var(--cz-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--cz-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--cz-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--cz-list-group-active-color);
  background-color: var(--cz-list-group-active-bg);
  border-color: var(--cz-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--cz-list-group-border-width));
  border-top-width: var(--cz-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--cz-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--cz-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--cz-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--cz-list-group-border-width));
  border-left-width: var(--cz-list-group-border-width);
}

@media (min-width: 500px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--cz-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--cz-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--cz-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--cz-list-group-border-width));
    border-left-width: var(--cz-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--cz-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--cz-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--cz-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--cz-list-group-border-width));
    border-left-width: var(--cz-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--cz-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--cz-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--cz-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--cz-list-group-border-width));
    border-left-width: var(--cz-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--cz-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--cz-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--cz-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--cz-list-group-border-width));
    border-left-width: var(--cz-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--cz-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--cz-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--cz-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--cz-list-group-border-width));
    border-left-width: var(--cz-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--cz-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --cz-list-group-color: var(--cz-primary-text-emphasis);
  --cz-list-group-bg: var(--cz-primary-bg-subtle);
  --cz-list-group-border-color: var(--cz-primary-border-subtle);
  --cz-list-group-action-hover-color: var(--cz-emphasis-color);
  --cz-list-group-action-hover-bg: var(--cz-primary-border-subtle);
  --cz-list-group-action-active-color: var(--cz-emphasis-color);
  --cz-list-group-action-active-bg: var(--cz-primary-border-subtle);
  --cz-list-group-active-color: var(--cz-primary-bg-subtle);
  --cz-list-group-active-bg: var(--cz-primary-text-emphasis);
  --cz-list-group-active-border-color: var(--cz-primary-text-emphasis);
}

.list-group-item-secondary {
  --cz-list-group-color: var(--cz-secondary-text-emphasis);
  --cz-list-group-bg: var(--cz-secondary-bg-subtle);
  --cz-list-group-border-color: var(--cz-secondary-border-subtle);
  --cz-list-group-action-hover-color: var(--cz-emphasis-color);
  --cz-list-group-action-hover-bg: var(--cz-secondary-border-subtle);
  --cz-list-group-action-active-color: var(--cz-emphasis-color);
  --cz-list-group-action-active-bg: var(--cz-secondary-border-subtle);
  --cz-list-group-active-color: var(--cz-secondary-bg-subtle);
  --cz-list-group-active-bg: var(--cz-secondary-text-emphasis);
  --cz-list-group-active-border-color: var(--cz-secondary-text-emphasis);
}

.list-group-item-success {
  --cz-list-group-color: var(--cz-success-text-emphasis);
  --cz-list-group-bg: var(--cz-success-bg-subtle);
  --cz-list-group-border-color: var(--cz-success-border-subtle);
  --cz-list-group-action-hover-color: var(--cz-emphasis-color);
  --cz-list-group-action-hover-bg: var(--cz-success-border-subtle);
  --cz-list-group-action-active-color: var(--cz-emphasis-color);
  --cz-list-group-action-active-bg: var(--cz-success-border-subtle);
  --cz-list-group-active-color: var(--cz-success-bg-subtle);
  --cz-list-group-active-bg: var(--cz-success-text-emphasis);
  --cz-list-group-active-border-color: var(--cz-success-text-emphasis);
}

.list-group-item-info {
  --cz-list-group-color: var(--cz-info-text-emphasis);
  --cz-list-group-bg: var(--cz-info-bg-subtle);
  --cz-list-group-border-color: var(--cz-info-border-subtle);
  --cz-list-group-action-hover-color: var(--cz-emphasis-color);
  --cz-list-group-action-hover-bg: var(--cz-info-border-subtle);
  --cz-list-group-action-active-color: var(--cz-emphasis-color);
  --cz-list-group-action-active-bg: var(--cz-info-border-subtle);
  --cz-list-group-active-color: var(--cz-info-bg-subtle);
  --cz-list-group-active-bg: var(--cz-info-text-emphasis);
  --cz-list-group-active-border-color: var(--cz-info-text-emphasis);
}

.list-group-item-warning {
  --cz-list-group-color: var(--cz-warning-text-emphasis);
  --cz-list-group-bg: var(--cz-warning-bg-subtle);
  --cz-list-group-border-color: var(--cz-warning-border-subtle);
  --cz-list-group-action-hover-color: var(--cz-emphasis-color);
  --cz-list-group-action-hover-bg: var(--cz-warning-border-subtle);
  --cz-list-group-action-active-color: var(--cz-emphasis-color);
  --cz-list-group-action-active-bg: var(--cz-warning-border-subtle);
  --cz-list-group-active-color: var(--cz-warning-bg-subtle);
  --cz-list-group-active-bg: var(--cz-warning-text-emphasis);
  --cz-list-group-active-border-color: var(--cz-warning-text-emphasis);
}

.list-group-item-danger {
  --cz-list-group-color: var(--cz-danger-text-emphasis);
  --cz-list-group-bg: var(--cz-danger-bg-subtle);
  --cz-list-group-border-color: var(--cz-danger-border-subtle);
  --cz-list-group-action-hover-color: var(--cz-emphasis-color);
  --cz-list-group-action-hover-bg: var(--cz-danger-border-subtle);
  --cz-list-group-action-active-color: var(--cz-emphasis-color);
  --cz-list-group-action-active-bg: var(--cz-danger-border-subtle);
  --cz-list-group-active-color: var(--cz-danger-bg-subtle);
  --cz-list-group-active-bg: var(--cz-danger-text-emphasis);
  --cz-list-group-active-border-color: var(--cz-danger-text-emphasis);
}

.list-group-item-light {
  --cz-list-group-color: var(--cz-light-text-emphasis);
  --cz-list-group-bg: var(--cz-light-bg-subtle);
  --cz-list-group-border-color: var(--cz-light-border-subtle);
  --cz-list-group-action-hover-color: var(--cz-emphasis-color);
  --cz-list-group-action-hover-bg: var(--cz-light-border-subtle);
  --cz-list-group-action-active-color: var(--cz-emphasis-color);
  --cz-list-group-action-active-bg: var(--cz-light-border-subtle);
  --cz-list-group-active-color: var(--cz-light-bg-subtle);
  --cz-list-group-active-bg: var(--cz-light-text-emphasis);
  --cz-list-group-active-border-color: var(--cz-light-text-emphasis);
}

.list-group-item-dark {
  --cz-list-group-color: var(--cz-dark-text-emphasis);
  --cz-list-group-bg: var(--cz-dark-bg-subtle);
  --cz-list-group-border-color: var(--cz-dark-border-subtle);
  --cz-list-group-action-hover-color: var(--cz-emphasis-color);
  --cz-list-group-action-hover-bg: var(--cz-dark-border-subtle);
  --cz-list-group-action-active-color: var(--cz-emphasis-color);
  --cz-list-group-action-active-bg: var(--cz-dark-border-subtle);
  --cz-list-group-active-color: var(--cz-dark-bg-subtle);
  --cz-list-group-active-bg: var(--cz-dark-text-emphasis);
  --cz-list-group-active-border-color: var(--cz-dark-text-emphasis);
}

.btn-close {
  --cz-btn-close-color: #181d25;
  --cz-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23181d25'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --cz-btn-close-opacity: 0.6;
  --cz-btn-close-hover-opacity: 0.9;
  --cz-btn-close-focus-shadow: 0 0 0 0.25rem var(--cz-focus-ring-color);
  --cz-btn-close-focus-opacity: 1;
  --cz-btn-close-disabled-opacity: 0.3;
  --cz-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 0.75em;
  height: 0.75em;
  padding: 0.25em 0.25em;
  color: var(--cz-btn-close-color);
  background: transparent var(--cz-btn-close-bg) center/0.75em auto no-repeat;
  border: 0;
  border-radius: 0.5rem;
  opacity: var(--cz-btn-close-opacity);
}
.btn-close:hover {
  color: var(--cz-btn-close-color);
  text-decoration: none;
  opacity: var(--cz-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--cz-btn-close-focus-shadow);
  opacity: var(--cz-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--cz-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--cz-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--cz-btn-close-white-filter);
}

.toast {
  --cz-toast-zindex: 1090;
  --cz-toast-padding-x: 0.75rem;
  --cz-toast-padding-y: 0.75rem;
  --cz-toast-spacing: 1.5rem;
  --cz-toast-max-width: 350px;
  --cz-toast-font-size: 0.875rem;
  --cz-toast-color: ;
  --cz-toast-bg: var(--cz-body-bg);
  --cz-toast-border-width: var(--cz-border-width);
  --cz-toast-border-color: var(--cz-light-border-subtle);
  --cz-toast-border-radius: var(--cz-border-radius);
  --cz-toast-box-shadow: var(--cz-box-shadow);
  --cz-toast-header-color: var(--cz-heading-color);
  --cz-toast-header-bg: transparent;
  --cz-toast-header-border-color: var(--cz-light-border-subtle);
  width: var(--cz-toast-max-width);
  max-width: 100%;
  font-size: var(--cz-toast-font-size);
  color: var(--cz-toast-color);
  pointer-events: auto;
  background-color: var(--cz-toast-bg);
  background-clip: padding-box;
  border: var(--cz-toast-border-width) solid var(--cz-toast-border-color);
  box-shadow: var(--cz-toast-box-shadow);
  border-radius: var(--cz-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --cz-toast-zindex: 1090;
  position: absolute;
  z-index: var(--cz-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--cz-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--cz-toast-padding-y) var(--cz-toast-padding-x);
  color: var(--cz-toast-header-color);
  background-color: var(--cz-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--cz-toast-border-width) solid var(--cz-toast-header-border-color);
  border-top-left-radius: calc(var(--cz-toast-border-radius) - var(--cz-toast-border-width));
  border-top-right-radius: calc(var(--cz-toast-border-radius) - var(--cz-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--cz-toast-padding-x));
  margin-left: var(--cz-toast-padding-x);
}

.toast-body {
  padding: var(--cz-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --cz-modal-zindex: 1055;
  --cz-modal-width: 500px;
  --cz-modal-padding: 1.5rem;
  --cz-modal-margin: 0.5rem;
  --cz-modal-color: ;
  --cz-modal-bg: var(--cz-body-bg);
  --cz-modal-border-color: var(--cz-border-color);
  --cz-modal-border-width: var(--cz-border-width);
  --cz-modal-border-radius: var(--cz-border-radius-lg);
  --cz-modal-box-shadow: var(--cz-box-shadow);
  --cz-modal-inner-border-radius: calc(var(--cz-border-radius-lg) - (var(--cz-border-width)));
  --cz-modal-header-padding-x: 1.5rem;
  --cz-modal-header-padding-y: 1.3125rem;
  --cz-modal-header-padding: 1.3125rem 1.5rem;
  --cz-modal-header-border-color: var(--cz-border-color);
  --cz-modal-header-border-width: var(--cz-border-width);
  --cz-modal-title-line-height: 1.5;
  --cz-modal-footer-gap: 0.75rem;
  --cz-modal-footer-bg: ;
  --cz-modal-footer-border-color: var(--cz-border-color);
  --cz-modal-footer-border-width: var(--cz-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--cz-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--cz-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.2s ease-out;
  transform: scale(0.9);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--cz-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--cz-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--cz-modal-color);
  pointer-events: auto;
  background-color: var(--cz-modal-bg);
  background-clip: padding-box;
  border: var(--cz-modal-border-width) solid var(--cz-modal-border-color);
  border-radius: var(--cz-modal-border-radius);
  box-shadow: var(--cz-modal-box-shadow);
  outline: 0;
}

.modal-backdrop {
  --cz-backdrop-zindex: 1050;
  --cz-backdrop-bg: #131920;
  --cz-backdrop-opacity: 0.75;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--cz-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--cz-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--cz-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--cz-modal-header-padding);
  border-bottom: var(--cz-modal-header-border-width) solid var(--cz-modal-header-border-color);
  border-top-left-radius: var(--cz-modal-inner-border-radius);
  border-top-right-radius: var(--cz-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--cz-modal-header-padding-y) * 0.5) calc(var(--cz-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--cz-modal-header-padding-y)) calc(-0.5 * var(--cz-modal-header-padding-x)) calc(-0.5 * var(--cz-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--cz-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--cz-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--cz-modal-padding) - var(--cz-modal-footer-gap) * 0.5);
  background-color: var(--cz-modal-footer-bg);
  border-top: var(--cz-modal-footer-border-width) solid var(--cz-modal-footer-border-color);
  border-bottom-right-radius: var(--cz-modal-inner-border-radius);
  border-bottom-left-radius: var(--cz-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--cz-modal-footer-gap) * 0.5);
}

@media (min-width: 500px) {
  .modal {
    --cz-modal-margin: 1.75rem;
    --cz-modal-box-shadow: var(--cz-box-shadow);
  }
  .modal-dialog {
    max-width: var(--cz-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --cz-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --cz-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --cz-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 499.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --cz-tooltip-zindex: 1080;
  --cz-tooltip-max-width: 200px;
  --cz-tooltip-padding-x: 0.625rem;
  --cz-tooltip-padding-y: 0.375rem;
  --cz-tooltip-margin: ;
  --cz-tooltip-font-size: 0.875rem;
  --cz-tooltip-color: var(--cz-body-bg);
  --cz-tooltip-bg: var(--cz-emphasis-color);
  --cz-tooltip-border-radius: var(--cz-border-radius-sm);
  --cz-tooltip-opacity: 1;
  --cz-tooltip-arrow-width: 0.8rem;
  --cz-tooltip-arrow-height: 0.4rem;
  z-index: var(--cz-tooltip-zindex);
  display: block;
  margin: var(--cz-tooltip-margin);
  font-family: var(--cz-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--cz-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--cz-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--cz-tooltip-arrow-width);
  height: var(--cz-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--cz-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--cz-tooltip-arrow-height) calc(var(--cz-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--cz-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--cz-tooltip-arrow-height));
  width: var(--cz-tooltip-arrow-height);
  height: var(--cz-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--cz-tooltip-arrow-width) * 0.5) var(--cz-tooltip-arrow-height) calc(var(--cz-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--cz-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--cz-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--cz-tooltip-arrow-width) * 0.5) var(--cz-tooltip-arrow-height);
  border-bottom-color: var(--cz-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--cz-tooltip-arrow-height));
  width: var(--cz-tooltip-arrow-height);
  height: var(--cz-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--cz-tooltip-arrow-width) * 0.5) 0 calc(var(--cz-tooltip-arrow-width) * 0.5) var(--cz-tooltip-arrow-height);
  border-left-color: var(--cz-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--cz-tooltip-max-width);
  padding: var(--cz-tooltip-padding-y) var(--cz-tooltip-padding-x);
  color: var(--cz-tooltip-color);
  text-align: center;
  background-color: var(--cz-tooltip-bg);
  border-radius: var(--cz-tooltip-border-radius);
}

.popover {
  --cz-popover-zindex: 1070;
  --cz-popover-max-width: 276px;
  --cz-popover-font-size: 0.875rem;
  --cz-popover-bg: var(--cz-body-bg);
  --cz-popover-border-width: var(--cz-border-width);
  --cz-popover-border-color: var(--cz-light-border-subtle);
  --cz-popover-border-radius: var(--cz-border-radius);
  --cz-popover-inner-border-radius: calc(var(--cz-border-radius) - var(--cz-border-width));
  --cz-popover-box-shadow: var(--cz-box-shadow);
  --cz-popover-header-padding-x: 1rem;
  --cz-popover-header-padding-y: 0.75rem;
  --cz-popover-header-font-size: 0.875rem;
  --cz-popover-header-color: var(--cz-heading-color);
  --cz-popover-header-bg: transparent;
  --cz-popover-body-padding-x: 1rem;
  --cz-popover-body-padding-y: 1rem;
  --cz-popover-body-color: var(--cz-secondary-color);
  --cz-popover-arrow-width: 1rem;
  --cz-popover-arrow-height: 0.5rem;
  --cz-popover-arrow-border: var(--cz-popover-border-color);
  z-index: var(--cz-popover-zindex);
  display: block;
  max-width: var(--cz-popover-max-width);
  font-family: var(--cz-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--cz-popover-font-size);
  word-wrap: break-word;
  background-color: var(--cz-popover-bg);
  background-clip: padding-box;
  border: var(--cz-popover-border-width) solid var(--cz-popover-border-color);
  border-radius: var(--cz-popover-border-radius);
  box-shadow: var(--cz-popover-box-shadow);
}
.popover .popover-arrow {
  display: block;
  width: var(--cz-popover-arrow-width);
  height: var(--cz-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--cz-popover-arrow-height)) - var(--cz-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--cz-popover-arrow-height) calc(var(--cz-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--cz-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--cz-popover-border-width);
  border-top-color: var(--cz-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--cz-popover-arrow-height)) - var(--cz-popover-border-width));
  width: var(--cz-popover-arrow-height);
  height: var(--cz-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--cz-popover-arrow-width) * 0.5) var(--cz-popover-arrow-height) calc(var(--cz-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--cz-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--cz-popover-border-width);
  border-right-color: var(--cz-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--cz-popover-arrow-height)) - var(--cz-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--cz-popover-arrow-width) * 0.5) var(--cz-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--cz-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--cz-popover-border-width);
  border-bottom-color: var(--cz-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--cz-popover-arrow-width);
  margin-left: calc(-0.5 * var(--cz-popover-arrow-width));
  content: "";
  border-bottom: var(--cz-popover-border-width) solid var(--cz-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--cz-popover-arrow-height)) - var(--cz-popover-border-width));
  width: var(--cz-popover-arrow-height);
  height: var(--cz-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--cz-popover-arrow-width) * 0.5) 0 calc(var(--cz-popover-arrow-width) * 0.5) var(--cz-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--cz-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--cz-popover-border-width);
  border-left-color: var(--cz-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--cz-popover-header-padding-y) var(--cz-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--cz-popover-header-font-size);
  color: var(--cz-popover-header-color);
  background-color: var(--cz-popover-header-bg);
  border-bottom: var(--cz-popover-border-width) solid var(--cz-popover-border-color);
  border-top-left-radius: var(--cz-popover-inner-border-radius);
  border-top-right-radius: var(--cz-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--cz-popover-body-padding-y) var(--cz-popover-body-padding-x);
  color: var(--cz-popover-body-color);
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--cz-spinner-width);
  height: var(--cz-spinner-height);
  vertical-align: var(--cz-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--cz-spinner-animation-speed) linear infinite var(--cz-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --cz-spinner-width: 2rem;
  --cz-spinner-height: 2rem;
  --cz-spinner-vertical-align: -0.125em;
  --cz-spinner-border-width: 0.15em;
  --cz-spinner-animation-speed: 0.75s;
  --cz-spinner-animation-name: spinner-border;
  border: var(--cz-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --cz-spinner-width: 1rem;
  --cz-spinner-height: 1rem;
  --cz-spinner-border-width: 0.1em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --cz-spinner-width: 2rem;
  --cz-spinner-height: 2rem;
  --cz-spinner-vertical-align: -0.125em;
  --cz-spinner-animation-speed: 0.75s;
  --cz-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --cz-spinner-width: 1rem;
  --cz-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --cz-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --cz-offcanvas-zindex: 1045;
  --cz-offcanvas-width: 350px;
  --cz-offcanvas-height: 350px;
  --cz-offcanvas-padding-x: 1.5rem;
  --cz-offcanvas-padding-y: 1.5rem;
  --cz-offcanvas-color: var(--cz-body-color);
  --cz-offcanvas-bg: var(--cz-body-bg);
  --cz-offcanvas-border-width: var(--cz-border-width);
  --cz-offcanvas-border-color: var(--cz-border-color);
  --cz-offcanvas-box-shadow: var(--cz-box-shadow);
  --cz-offcanvas-transition: transform 0.3s ease-in-out;
  --cz-offcanvas-title-line-height: 1.5;
}

@media (max-width: 499.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--cz-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--cz-offcanvas-color);
    visibility: hidden;
    background-color: var(--cz-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--cz-offcanvas-box-shadow);
    transition: var(--cz-offcanvas-transition);
  }
}
@media (max-width: 499.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 499.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--cz-offcanvas-width);
    border-right: var(--cz-offcanvas-border-width) solid var(--cz-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--cz-offcanvas-width);
    border-left: var(--cz-offcanvas-border-width) solid var(--cz-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--cz-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--cz-offcanvas-border-width) solid var(--cz-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--cz-offcanvas-height);
    max-height: 100%;
    border-top: var(--cz-offcanvas-border-width) solid var(--cz-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 500px) {
  .offcanvas-sm {
    --cz-offcanvas-height: auto;
    --cz-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--cz-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--cz-offcanvas-color);
    visibility: hidden;
    background-color: var(--cz-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--cz-offcanvas-box-shadow);
    transition: var(--cz-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--cz-offcanvas-width);
    border-right: var(--cz-offcanvas-border-width) solid var(--cz-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--cz-offcanvas-width);
    border-left: var(--cz-offcanvas-border-width) solid var(--cz-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--cz-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--cz-offcanvas-border-width) solid var(--cz-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--cz-offcanvas-height);
    max-height: 100%;
    border-top: var(--cz-offcanvas-border-width) solid var(--cz-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --cz-offcanvas-height: auto;
    --cz-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--cz-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--cz-offcanvas-color);
    visibility: hidden;
    background-color: var(--cz-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--cz-offcanvas-box-shadow);
    transition: var(--cz-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--cz-offcanvas-width);
    border-right: var(--cz-offcanvas-border-width) solid var(--cz-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--cz-offcanvas-width);
    border-left: var(--cz-offcanvas-border-width) solid var(--cz-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--cz-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--cz-offcanvas-border-width) solid var(--cz-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--cz-offcanvas-height);
    max-height: 100%;
    border-top: var(--cz-offcanvas-border-width) solid var(--cz-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --cz-offcanvas-height: auto;
    --cz-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--cz-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--cz-offcanvas-color);
    visibility: hidden;
    background-color: var(--cz-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--cz-offcanvas-box-shadow);
    transition: var(--cz-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--cz-offcanvas-width);
    border-right: var(--cz-offcanvas-border-width) solid var(--cz-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--cz-offcanvas-width);
    border-left: var(--cz-offcanvas-border-width) solid var(--cz-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--cz-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--cz-offcanvas-border-width) solid var(--cz-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--cz-offcanvas-height);
    max-height: 100%;
    border-top: var(--cz-offcanvas-border-width) solid var(--cz-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --cz-offcanvas-height: auto;
    --cz-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--cz-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--cz-offcanvas-color);
    visibility: hidden;
    background-color: var(--cz-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--cz-offcanvas-box-shadow);
    transition: var(--cz-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--cz-offcanvas-width);
    border-right: var(--cz-offcanvas-border-width) solid var(--cz-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--cz-offcanvas-width);
    border-left: var(--cz-offcanvas-border-width) solid var(--cz-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--cz-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--cz-offcanvas-border-width) solid var(--cz-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--cz-offcanvas-height);
    max-height: 100%;
    border-top: var(--cz-offcanvas-border-width) solid var(--cz-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --cz-offcanvas-height: auto;
    --cz-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--cz-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--cz-offcanvas-color);
  visibility: hidden;
  background-color: var(--cz-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  box-shadow: var(--cz-offcanvas-box-shadow);
  transition: var(--cz-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--cz-offcanvas-width);
  border-right: var(--cz-offcanvas-border-width) solid var(--cz-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--cz-offcanvas-width);
  border-left: var(--cz-offcanvas-border-width) solid var(--cz-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--cz-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--cz-offcanvas-border-width) solid var(--cz-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--cz-offcanvas-height);
  max-height: 100%;
  border-top: var(--cz-offcanvas-border-width) solid var(--cz-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #131920;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.75;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  padding: var(--cz-offcanvas-padding-y) var(--cz-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--cz-offcanvas-padding-y) * 0.5) calc(var(--cz-offcanvas-padding-x) * 0.5);
  margin: calc(-0.5 * var(--cz-offcanvas-padding-y)) calc(-0.5 * var(--cz-offcanvas-padding-x)) calc(-0.5 * var(--cz-offcanvas-padding-y)) auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--cz-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--cz-offcanvas-padding-y) var(--cz-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.35;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.15;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.85) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--cz-primary-rgb), var(--cz-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--cz-secondary-rgb), var(--cz-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--cz-success-rgb), var(--cz-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(var(--cz-info-rgb), var(--cz-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #fff !important;
  background-color: RGBA(var(--cz-warning-rgb), var(--cz-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--cz-danger-rgb), var(--cz-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--cz-light-rgb), var(--cz-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--cz-dark-rgb), var(--cz-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--cz-primary-rgb), var(--cz-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--cz-primary-rgb), var(--cz-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(46, 109, 164, var(--cz-link-opacity, 1)) !important;
  text-decoration-color: RGBA(46, 109, 164, var(--cz-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--cz-secondary-rgb), var(--cz-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--cz-secondary-rgb), var(--cz-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(108, 114, 127, var(--cz-link-opacity, 1)) !important;
  text-decoration-color: RGBA(108, 114, 127, var(--cz-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--cz-success-rgb), var(--cz-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--cz-success-rgb), var(--cz-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(51, 179, 107, var(--cz-link-opacity, 1)) !important;
  text-decoration-color: RGBA(51, 179, 107, var(--cz-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--cz-info-rgb), var(--cz-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--cz-info-rgb), var(--cz-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(47, 110, 213, var(--cz-link-opacity, 1)) !important;
  text-decoration-color: RGBA(47, 110, 213, var(--cz-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--cz-warning-rgb), var(--cz-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--cz-warning-rgb), var(--cz-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(252, 146, 49, var(--cz-link-opacity, 1)) !important;
  text-decoration-color: RGBA(252, 146, 49, var(--cz-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--cz-danger-rgb), var(--cz-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--cz-danger-rgb), var(--cz-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(240, 61, 61, var(--cz-link-opacity, 1)) !important;
  text-decoration-color: RGBA(240, 61, 61, var(--cz-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--cz-light-rgb), var(--cz-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--cz-light-rgb), var(--cz-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(255, 255, 255, var(--cz-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--cz-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--cz-dark-rgb), var(--cz-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--cz-dark-rgb), var(--cz-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(34, 41, 52, var(--cz-link-opacity, 1)) !important;
  text-decoration-color: RGBA(34, 41, 52, var(--cz-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--cz-emphasis-color-rgb), var(--cz-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--cz-emphasis-color-rgb), var(--cz-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--cz-emphasis-color-rgb), var(--cz-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--cz-emphasis-color-rgb), var(--cz-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--cz-focus-ring-x, 0) var(--cz-focus-ring-y, 0) var(--cz-focus-ring-blur, 0) var(--cz-focus-ring-width) var(--cz-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--cz-link-color-rgb), var(--cz-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--cz-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--cz-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --cz-aspect-ratio: 100%;
}

.ratio-4x3 {
  --cz-aspect-ratio: 75%;
}

.ratio-16x9 {
  --cz-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --cz-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 500px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--cz-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 1;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-40 {
  opacity: 0.4 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-60 {
  opacity: 0.6 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--cz-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--cz-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--cz-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --cz-focus-ring-color: rgba(var(--cz-primary-rgb), var(--cz-focus-ring-opacity));
}

.focus-ring-secondary {
  --cz-focus-ring-color: rgba(var(--cz-secondary-rgb), var(--cz-focus-ring-opacity));
}

.focus-ring-success {
  --cz-focus-ring-color: rgba(var(--cz-success-rgb), var(--cz-focus-ring-opacity));
}

.focus-ring-info {
  --cz-focus-ring-color: rgba(var(--cz-info-rgb), var(--cz-focus-ring-opacity));
}

.focus-ring-warning {
  --cz-focus-ring-color: rgba(var(--cz-warning-rgb), var(--cz-focus-ring-opacity));
}

.focus-ring-danger {
  --cz-focus-ring-color: rgba(var(--cz-danger-rgb), var(--cz-focus-ring-opacity));
}

.focus-ring-light {
  --cz-focus-ring-color: rgba(var(--cz-light-rgb), var(--cz-focus-ring-opacity));
}

.focus-ring-dark {
  --cz-focus-ring-color: rgba(var(--cz-dark-rgb), var(--cz-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--cz-border-width) var(--cz-border-style) var(--cz-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--cz-border-width) var(--cz-border-style) var(--cz-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--cz-border-width) var(--cz-border-style) var(--cz-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--cz-border-width) var(--cz-border-style) var(--cz-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--cz-border-width) var(--cz-border-style) var(--cz-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --cz-border-opacity: 1;
  border-color: rgba(var(--cz-primary-rgb), var(--cz-border-opacity)) !important;
}

.border-secondary {
  --cz-border-opacity: 1;
  border-color: rgba(var(--cz-secondary-rgb), var(--cz-border-opacity)) !important;
}

.border-success {
  --cz-border-opacity: 1;
  border-color: rgba(var(--cz-success-rgb), var(--cz-border-opacity)) !important;
}

.border-info {
  --cz-border-opacity: 1;
  border-color: rgba(var(--cz-info-rgb), var(--cz-border-opacity)) !important;
}

.border-warning {
  --cz-border-opacity: 1;
  border-color: rgba(var(--cz-warning-rgb), var(--cz-border-opacity)) !important;
}

.border-danger {
  --cz-border-opacity: 1;
  border-color: rgba(var(--cz-danger-rgb), var(--cz-border-opacity)) !important;
}

.border-light {
  --cz-border-opacity: 1;
  border-color: rgba(var(--cz-light-rgb), var(--cz-border-opacity)) !important;
}

.border-dark {
  --cz-border-opacity: 1;
  border-color: rgba(var(--cz-dark-rgb), var(--cz-border-opacity)) !important;
}

.border-black {
  --cz-border-opacity: 1;
  border-color: rgba(var(--cz-black-rgb), var(--cz-border-opacity)) !important;
}

.border-white {
  --cz-border-opacity: 1;
  border-color: rgba(var(--cz-white-rgb), var(--cz-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--cz-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--cz-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--cz-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--cz-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--cz-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--cz-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--cz-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--cz-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --cz-border-opacity: 0.1;
}

.border-opacity-25 {
  --cz-border-opacity: 0.25;
}

.border-opacity-50 {
  --cz-border-opacity: 0.5;
}

.border-opacity-75 {
  --cz-border-opacity: 0.75;
}

.border-opacity-100 {
  --cz-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--cz-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fs-xl {
  font-size: 1.25rem !important;
}

.fs-lg {
  font-size: 1.125rem !important;
}

.fs-base {
  font-size: 1rem !important;
}

.fs-sm {
  font-size: 0.875rem !important;
}

.fs-xs {
  font-size: 0.75rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-0 {
  line-height: 0 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --cz-text-opacity: 1;
  color: rgba(var(--cz-primary-rgb), var(--cz-text-opacity)) !important;
}

.text-secondary {
  --cz-text-opacity: 1;
  color: rgba(var(--cz-secondary-rgb), var(--cz-text-opacity)) !important;
}

.text-success {
  --cz-text-opacity: 1;
  color: rgba(var(--cz-success-rgb), var(--cz-text-opacity)) !important;
}

.text-info {
  --cz-text-opacity: 1;
  color: rgba(var(--cz-info-rgb), var(--cz-text-opacity)) !important;
}

.text-warning {
  --cz-text-opacity: 1;
  color: rgba(var(--cz-warning-rgb), var(--cz-text-opacity)) !important;
}

.text-danger {
  --cz-text-opacity: 1;
  color: rgba(var(--cz-danger-rgb), var(--cz-text-opacity)) !important;
}

.text-light {
  --cz-text-opacity: 1;
  color: rgba(var(--cz-light-rgb), var(--cz-text-opacity)) !important;
}

.text-dark {
  --cz-text-opacity: 1;
  color: rgba(var(--cz-dark-rgb), var(--cz-text-opacity)) !important;
}

.text-black {
  --cz-text-opacity: 1;
  color: rgba(var(--cz-black-rgb), var(--cz-text-opacity)) !important;
}

.text-white {
  --cz-text-opacity: 1;
  color: rgba(var(--cz-white-rgb), var(--cz-text-opacity)) !important;
}

.text-body {
  --cz-text-opacity: 1;
  color: rgba(var(--cz-body-color-rgb), var(--cz-text-opacity)) !important;
}

.text-muted {
  --cz-text-opacity: 1;
  color: var(--cz-secondary-color) !important;
}

.text-black-50 {
  --cz-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --cz-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --cz-text-opacity: 1;
  color: var(--cz-secondary-color) !important;
}

.text-body-tertiary {
  --cz-text-opacity: 1;
  color: var(--cz-tertiary-color) !important;
}

.text-body-emphasis {
  --cz-text-opacity: 1;
  color: var(--cz-emphasis-color) !important;
}

.text-reset {
  --cz-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --cz-text-opacity: 0.25;
}

.text-opacity-50 {
  --cz-text-opacity: 0.5;
}

.text-opacity-75 {
  --cz-text-opacity: 0.75;
}

.text-opacity-100 {
  --cz-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--cz-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--cz-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--cz-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--cz-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--cz-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--cz-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--cz-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--cz-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --cz-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --cz-link-opacity: 0.1;
}

.link-opacity-25 {
  --cz-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --cz-link-opacity: 0.25;
}

.link-opacity-50 {
  --cz-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --cz-link-opacity: 0.5;
}

.link-opacity-75 {
  --cz-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --cz-link-opacity: 0.75;
}

.link-opacity-100 {
  --cz-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --cz-link-opacity: 1;
}

.bg-primary {
  --cz-bg-opacity: 1;
  background-color: rgba(var(--cz-primary-rgb), var(--cz-bg-opacity)) !important;
}

.bg-secondary {
  --cz-bg-opacity: 1;
  background-color: rgba(var(--cz-secondary-rgb), var(--cz-bg-opacity)) !important;
}

.bg-success {
  --cz-bg-opacity: 1;
  background-color: rgba(var(--cz-success-rgb), var(--cz-bg-opacity)) !important;
}

.bg-info {
  --cz-bg-opacity: 1;
  background-color: rgba(var(--cz-info-rgb), var(--cz-bg-opacity)) !important;
}

.bg-warning {
  --cz-bg-opacity: 1;
  background-color: rgba(var(--cz-warning-rgb), var(--cz-bg-opacity)) !important;
}

.bg-danger {
  --cz-bg-opacity: 1;
  background-color: rgba(var(--cz-danger-rgb), var(--cz-bg-opacity)) !important;
}

.bg-light {
  --cz-bg-opacity: 1;
  background-color: rgba(var(--cz-light-rgb), var(--cz-bg-opacity)) !important;
}

.bg-dark {
  --cz-bg-opacity: 1;
  background-color: rgba(var(--cz-dark-rgb), var(--cz-bg-opacity)) !important;
}

.bg-black {
  --cz-bg-opacity: 1;
  background-color: rgba(var(--cz-black-rgb), var(--cz-bg-opacity)) !important;
}

.bg-white {
  --cz-bg-opacity: 1;
  background-color: rgba(var(--cz-white-rgb), var(--cz-bg-opacity)) !important;
}

.bg-body {
  --cz-bg-opacity: 1;
  background-color: rgba(var(--cz-body-bg-rgb), var(--cz-bg-opacity)) !important;
}

.bg-transparent {
  --cz-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --cz-bg-opacity: 1;
  background-color: rgba(var(--cz-secondary-bg-rgb), var(--cz-bg-opacity)) !important;
}

.bg-body-tertiary {
  --cz-bg-opacity: 1;
  background-color: rgba(var(--cz-tertiary-bg-rgb), var(--cz-bg-opacity)) !important;
}

.bg-opacity-10 {
  --cz-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --cz-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --cz-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --cz-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --cz-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--cz-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--cz-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--cz-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--cz-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--cz-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--cz-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--cz-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--cz-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--cz-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--cz-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--cz-border-radius-xs) !important;
}

.rounded-2 {
  border-radius: var(--cz-border-radius-sm) !important;
}

.rounded-3 {
  border-radius: var(--cz-border-radius) !important;
}

.rounded-4 {
  border-radius: var(--cz-border-radius-lg) !important;
}

.rounded-5 {
  border-radius: var(--cz-border-radius-xl) !important;
}

.rounded-6 {
  border-radius: var(--cz-border-radius-xxl) !important;
}

.rounded-7 {
  border-radius: var(--cz-border-radius-xxxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--cz-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--cz-border-radius) !important;
  border-top-right-radius: var(--cz-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--cz-border-radius-xs) !important;
  border-top-right-radius: var(--cz-border-radius-xs) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--cz-border-radius-sm) !important;
  border-top-right-radius: var(--cz-border-radius-sm) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--cz-border-radius) !important;
  border-top-right-radius: var(--cz-border-radius) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--cz-border-radius-lg) !important;
  border-top-right-radius: var(--cz-border-radius-lg) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--cz-border-radius-xl) !important;
  border-top-right-radius: var(--cz-border-radius-xl) !important;
}

.rounded-top-6 {
  border-top-left-radius: var(--cz-border-radius-xxl) !important;
  border-top-right-radius: var(--cz-border-radius-xxl) !important;
}

.rounded-top-7 {
  border-top-left-radius: var(--cz-border-radius-xxxl) !important;
  border-top-right-radius: var(--cz-border-radius-xxxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--cz-border-radius-pill) !important;
  border-top-right-radius: var(--cz-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--cz-border-radius) !important;
  border-bottom-right-radius: var(--cz-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--cz-border-radius-xs) !important;
  border-bottom-right-radius: var(--cz-border-radius-xs) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--cz-border-radius-sm) !important;
  border-bottom-right-radius: var(--cz-border-radius-sm) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--cz-border-radius) !important;
  border-bottom-right-radius: var(--cz-border-radius) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--cz-border-radius-lg) !important;
  border-bottom-right-radius: var(--cz-border-radius-lg) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--cz-border-radius-xl) !important;
  border-bottom-right-radius: var(--cz-border-radius-xl) !important;
}

.rounded-end-6 {
  border-top-right-radius: var(--cz-border-radius-xxl) !important;
  border-bottom-right-radius: var(--cz-border-radius-xxl) !important;
}

.rounded-end-7 {
  border-top-right-radius: var(--cz-border-radius-xxxl) !important;
  border-bottom-right-radius: var(--cz-border-radius-xxxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--cz-border-radius-pill) !important;
  border-bottom-right-radius: var(--cz-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--cz-border-radius) !important;
  border-bottom-left-radius: var(--cz-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--cz-border-radius-xs) !important;
  border-bottom-left-radius: var(--cz-border-radius-xs) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--cz-border-radius-sm) !important;
  border-bottom-left-radius: var(--cz-border-radius-sm) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--cz-border-radius) !important;
  border-bottom-left-radius: var(--cz-border-radius) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--cz-border-radius-lg) !important;
  border-bottom-left-radius: var(--cz-border-radius-lg) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--cz-border-radius-xl) !important;
  border-bottom-left-radius: var(--cz-border-radius-xl) !important;
}

.rounded-bottom-6 {
  border-bottom-right-radius: var(--cz-border-radius-xxl) !important;
  border-bottom-left-radius: var(--cz-border-radius-xxl) !important;
}

.rounded-bottom-7 {
  border-bottom-right-radius: var(--cz-border-radius-xxxl) !important;
  border-bottom-left-radius: var(--cz-border-radius-xxxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--cz-border-radius-pill) !important;
  border-bottom-left-radius: var(--cz-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--cz-border-radius) !important;
  border-top-left-radius: var(--cz-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--cz-border-radius-xs) !important;
  border-top-left-radius: var(--cz-border-radius-xs) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--cz-border-radius-sm) !important;
  border-top-left-radius: var(--cz-border-radius-sm) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--cz-border-radius) !important;
  border-top-left-radius: var(--cz-border-radius) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--cz-border-radius-lg) !important;
  border-top-left-radius: var(--cz-border-radius-lg) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--cz-border-radius-xl) !important;
  border-top-left-radius: var(--cz-border-radius-xl) !important;
}

.rounded-start-6 {
  border-bottom-left-radius: var(--cz-border-radius-xxl) !important;
  border-top-left-radius: var(--cz-border-radius-xxl) !important;
}

.rounded-start-7 {
  border-bottom-left-radius: var(--cz-border-radius-xxxl) !important;
  border-top-left-radius: var(--cz-border-radius-xxxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--cz-border-radius-pill) !important;
  border-top-left-radius: var(--cz-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.z-sticky {
  z-index: 1020 !important;
}

.z-fixed {
  z-index: 1030 !important;
}

.min-w-0 {
  min-width: 0 !important;
}

.min-w-100 {
  min-width: 100 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-help {
  cursor: help !important;
}

.cursor-wait {
  cursor: wait !important;
}

.cursor-crosshair {
  cursor: crosshair !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-zoom-in {
  cursor: zoom-in !important;
}

.cursor-zoom-out {
  cursor: zoom-out !important;
}

.cursor-grab {
  cursor: grab !important;
}

@media (min-width: 500px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -3rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -3rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -3rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
:root,
[data-bs-theme=light] {
  --cz-link-color: var(--cz-info);
  --cz-link-color-rgb: var(--cz-info-rgb);
  --cz-link-hover-color: var(--cz-info);
  --cz-link-hover-color-rgb: var(--cz-info-rgb);
  --cz-component-hover-color: #181d25;
  --cz-component-color: #333d4c;
  --cz-component-hover-color: #181d25;
  --cz-component-hover-bg: #f5f7fa;
  --cz-component-active-color: #181d25;
  --cz-component-active-bg: #eef1f6;
  --cz-component-disabled-color: #9ca3af;
  --cz-user-selection-color: rgba(24, 29, 37, 0.1);
  --cz-border-radius-xs: calc(var(--cz-border-radius) * 0.5);
  --cz-border-radius-xxxl: calc(var(--cz-border-radius) * 3);
  --cz-underline-thickness: 1px;
  --plyr-color-main: #2e6da4;
}

[data-bs-theme=dark] {
  --cz-link-color: var(--cz-info);
  --cz-link-color-rgb: var(--cz-info-rgb);
  --cz-link-hover-color: var(--cz-info);
  --cz-link-hover-color-rgb: var(--cz-info-rgb);
  --cz-component-color: #e0e5eb;
  --cz-component-hover-color: #fff;
  --cz-component-hover-bg: #222934;
  --cz-component-active-color: #fff;
  --cz-component-active-bg: #333d4c;
  --cz-component-disabled-color: #6c727f;
  --cz-user-selection-color-dark: rgba(255, 255, 255, 0.12);
  --cz-focus-ring-opacity: 0.12;
  --cz-focus-ring-color: rgba(255, 255, 255, 0.1);
  --cz-box-shadow: 0 0.5rem 1.875rem -0.25rem rgba(8, 11, 18, 0.25);
  --cz-box-shadow-sm: 0 0.375rem 1.375rem -0.25rem rgba(8, 11, 18, 0.25);
  --cz-box-shadow-lg: 0 0.75rem 3rem -0.5rem rgba(8, 11, 18, 0.25);
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html *:focus-visible {
  outline: var(--cz-focus-ring-width) solid var(--cz-focus-ring-color);
}

.offcanvas:focus-visible {
  outline: none;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrapper {
  flex: 1;
}

.footer {
  flex-shrink: 0;
}

img,
figure {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

svg {
  max-width: 100%;
}

iframe {
  width: 100%;
}

/* stylelint-disable selector-no-qualifying-type */
a,
button {
  text-underline-offset: 0.25em;
}
a.text-decoration-underline:hover,
button.text-decoration-underline:hover {
  text-decoration: none !important;
}

/* stylelint-enable selector-no-qualifying-type */
.link-body-emphasis {
  --cz-link-opacity: 1;
}

::selection {
  background: var(--cz-user-selection-color);
}

[data-bs-theme=dark] ::selection {
  background: var(--cz-user-selection-color-dark);
}

ol,
ul {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-left: 1.5rem;
}
ol ul,
ol ol,
ul ul,
ul ol {
  margin-top: 0.5rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

dt {
  color: var(--cz-heading-color);
}

dd {
  margin-bottom: 0.75rem;
}

legend {
  color: var(--cz-heading-color);
}

[class*=" ci-"],
[class^=ci-] {
  display: inline-flex;
}
a [class*=" ci-"]:not(.animate-target), button [class*=" ci-"]:not(.animate-target),
a [class^=ci-]:not(.animate-target),
button [class^=ci-]:not(.animate-target) {
  transition: opacity 0.2s ease-in-out;
}
a:hover [class*=" ci-"]:not(.animate-target), a:focus-visible [class*=" ci-"]:not(.animate-target), a.active [class*=" ci-"]:not(.animate-target), a.show [class*=" ci-"]:not(.animate-target), button:hover [class*=" ci-"]:not(.animate-target), button:focus-visible [class*=" ci-"]:not(.animate-target), button.active [class*=" ci-"]:not(.animate-target), button.show [class*=" ci-"]:not(.animate-target),
a:hover [class^=ci-]:not(.animate-target),
a:focus-visible [class^=ci-]:not(.animate-target),
a.active [class^=ci-]:not(.animate-target),
a.show [class^=ci-]:not(.animate-target),
button:hover [class^=ci-]:not(.animate-target),
button:focus-visible [class^=ci-]:not(.animate-target),
button.active [class^=ci-]:not(.animate-target),
button.show [class^=ci-]:not(.animate-target) {
  opacity: 1 !important;
}

pre,
.hljs {
  padding: 1.25rem 1rem;
  margin: 0;
  background-color: #222934;
  border-radius: var(--cz-border-radius);
}

kbd {
  border-radius: calc(var(--cz-border-radius) * 0.5);
}

.hljs-comment,
.hljs-quote {
  font-style: normal;
}

.container-start {
  margin-left: calc((100% - (1328px - 1.5rem)) / 2);
}

.container-end {
  margin-right: calc((100% - (1328px - 1.5rem)) / 2);
}

@media (max-width: 1399.98px) {
  .container-start {
    margin-left: calc((100% - (95% - 1.5rem)) / 2);
  }
  .container-end {
    margin-right: calc((100% - (95% - 1.5rem)) / 2);
  }
}
@media (max-width: 499.98px) {
  .container-start {
    padding-left: 1rem;
    margin-left: 0;
  }
  .container-end {
    padding-right: 1rem;
    margin-right: 0;
  }
}
.animate-underline.animate-target,
.animate-underline .animate-target {
  text-decoration: none;
  position: relative;
}
.animate-underline.animate-target::after,
.animate-underline .animate-target::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--cz-underline-thickness);
  content: "";
  background-color: currentcolor;
  transition: transform 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: bottom right;
}
.animate-underline:hover.animate-target::after,
.animate-underline:hover .animate-target::after, .animate-underline.show.animate-target::after,
.animate-underline.show .animate-target::after, .animate-underline.active.animate-target::after,
.animate-underline.active .animate-target::after, .animate-underline:focus-visible.animate-target::after,
.animate-underline:focus-visible .animate-target::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.animate-shake:hover .animate-target, .animate-shake:focus-visible .animate-target {
  animation: shake 0.8s;
}

@keyframes shake {
  0% {
    transform: scale3d(1, 1, 1);
  }
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -5deg);
  }
  50%, 70%, 90% {
    transform: scale3d(1.25, 1.25, 1.25) rotate3d(0, 0, 1, 5deg);
  }
  60%, 80% {
    transform: scale3d(1.25, 1.25, 1.25) rotate3d(0, 0, 1, -5deg);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
.animate-pulse:hover .animate-target, .animate-pulse:focus-visible .animate-target {
  animation: pulse 0.9s;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  14% {
    transform: scale(1.25);
  }
  28% {
    transform: scale(1);
  }
  42% {
    transform: scale(1.25);
  }
  70% {
    transform: scale(1);
  }
}
.animate-rotate:hover .animate-target, .animate-rotate:focus-visible .animate-target {
  animation: rotate 0.45s ease-in-out;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.animate-scale:hover .animate-target, .animate-scale:focus-visible .animate-target {
  animation: scale 0.35s ease-in-out;
}

@keyframes scale {
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.2, 1.2, 1.2);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
.animate-slide-end {
  overflow: hidden;
}
.animate-slide-end:hover .animate-target, .animate-slide-end:focus-visible .animate-target {
  animation: slide-end 0.3s forwards;
}

.animate-slide-start {
  overflow: hidden;
}
.animate-slide-start:hover .animate-target, .animate-slide-start:focus-visible .animate-target {
  animation: slide-start 0.3s forwards;
}

.animate-slide-up {
  overflow: hidden;
}
.animate-slide-up:hover .animate-target, .animate-slide-up:focus-visible .animate-target {
  animation: slide-up 0.3s forwards;
}

.animate-slide-down {
  overflow: hidden;
}
.animate-slide-down:hover .animate-target, .animate-slide-down:focus-visible .animate-target {
  animation: slide-down 0.3s forwards;
}

@keyframes slide-end {
  49% {
    transform: translate(100%);
  }
  50% {
    opacity: 0;
    transform: translate(-100%);
  }
  51% {
    opacity: 1;
  }
}
@keyframes slide-start {
  49% {
    transform: translate(-100%);
  }
  50% {
    opacity: 0;
    transform: translate(100%);
  }
  51% {
    opacity: 1;
  }
}
@keyframes slide-up {
  49% {
    transform: translateY(-100%);
  }
  50% {
    opacity: 0;
    transform: translateY(100%);
  }
  51% {
    opacity: 1;
  }
}
@keyframes slide-down {
  49% {
    transform: translateY(100%);
  }
  50% {
    opacity: 0;
    transform: translateY(-100%);
  }
  51% {
    opacity: 1;
  }
}
.animate-blinking {
  animation: blinking 1s infinite;
}

@keyframes blinking {
  from {
    opacity: 0;
  }
}
.animate-shake .animate-target::after,
.animate-pulse .animate-target::after,
.animate-rotate .animate-target::after,
.animate-scale .animate-target::after,
.animate-slide-end .animate-target::after,
.animate-slide-start .animate-target::after,
.animate-slide-up .animate-target::after,
.animate-slide-down .animate-target::after {
  display: none;
}

.animate-up-down {
  animation: move-up-down 5s linear infinite;
}

@keyframes move-up-down {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-1rem);
  }
}
.animate-down-up {
  animation: move-down-up 5s linear infinite;
}

@keyframes move-down-up {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1rem);
  }
}
.animate-spin {
  animation: spin 10s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.animate-blink {
  animation: blink 1.75s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-up {
  from {
    opacity: 0;
    transform: translateY(0.5rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.hover-effect-scale {
  --cz-transition-duration: .35s;
  --cz-transform-scale: 1.06;
}
.hover-effect-scale .hover-effect-target {
  transition: transform var(--cz-transition-duration) ease-in-out;
}
.hover-effect-scale:hover .hover-effect-target, .hover-effect-scale:focus-visible .hover-effect-target, .hover-effect-scale:focus-within .hover-effect-target {
  transform: scale(var(--cz-transform-scale));
}

.hover-effect-opacity {
  --cz-transition-duration: .25s;
}
.hover-effect-opacity .hover-effect-target {
  transition: visibility var(--cz-transition-duration) ease-in-out, opacity var(--cz-transition-duration) ease-in-out;
}
.hover-effect-opacity:hover .hover-effect-target.opacity-0, .hover-effect-opacity:focus-visible .hover-effect-target.opacity-0, .hover-effect-opacity:focus-within .hover-effect-target.opacity-0 {
  visibility: visible !important;
  opacity: 1 !important;
}
.hover-effect-opacity:hover .hover-effect-target.opacity-100, .hover-effect-opacity:focus-visible .hover-effect-target.opacity-100, .hover-effect-opacity:focus-within .hover-effect-target.opacity-100 {
  visibility: hidden !important;
  opacity: 0 !important;
}

.hover-effect-scale.hover-effect-opacity .hover-effect-target {
  transition: all var(--cz-transition-duration) ease-in-out;
}

.hover-effect-underline:hover {
  text-decoration: underline !important;
  text-decoration-thickness: var(--cz-underline-thickness) !important;
}

/* stylelint-disable @stylistic/selector-list-comma-newline-after */
h1, .h1 {
  line-height: 1.2;
}

h2, .h2 {
  line-height: 1.25;
}

h3, .h3 {
  line-height: 1.3;
}

h4, .h4 {
  line-height: 1.35;
}

h5, .h5 {
  line-height: 1.35;
}

h6, .h6 {
  line-height: 1.35;
}

h1 a, .h1 a, h2 a, .h2 a, h3 a, .h3 a,
h4 a, .h4 a, h5 a, .h5 a, h6 a, .h6 a {
  color: var(--cz-heading-color);
  text-decoration: none;
}

/* stylelint-enable @stylistic/selector-list-comma-newline-after */
.blockquote {
  font-weight: 600;
  color: var(--cz-heading-color);
}

.blockquote-footer {
  margin-top: -0.75rem;
}

.list-inline {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.625rem 1rem;
  margin-bottom: 0.75rem;
}

.table {
  --cz-table-th-color: var(--cz-heading-color);
}
.table thead th,
.table tbody th {
  color: var(--cz-table-th-color) !important;
}
.table.table-dark th,
.table .table-dark th {
  color: #fff !important;
}

[data-bs-theme=dark] .table:not([data-bs-theme=light]) {
  --cz-table-striped-bg: rgba(255, 255, 255, 0.05);
  --cz-table-active-bg: rgba(255, 255, 255, 0.1);
  --cz-table-hover-bg: rgba(255, 255, 255, 0.075);
}
[data-bs-theme=dark] .table-dark {
  --cz-table-bg: var(--cz-dark);
  --cz-table-border-color: var(--cz-border-color);
}

.form-control,
.form-select {
  --cz-form-control-bg: #fff;
  --cz-form-control-border-color: #cad0d9;
  --cz-form-control-focus-bg: #fff;
  --cz-form-control-focus-border-color: #181d25;
  background-color: var(--cz-form-control-bg);
  border-color: var(--cz-form-control-border-color);
}
.form-control:focus,
.form-select:focus {
  background-color: var(--cz-form-control-focus-bg);
  border-color: var(--cz-form-control-focus-border-color);
}
.form-control:disabled,
.form-select:disabled {
  border-style: dashed;
}

.form-select.form-select-sm {
  background-position: right 0.765625rem center;
}

.form-control::-webkit-search-decoration, .form-control::-webkit-search-cancel-button, .form-control::-webkit-search-results-button, .form-control::-webkit-search-results-decoration {
  display: none;
}

.form-control::-webkit-calendar-picker-indicator {
  background: none;
}

.form-floating > label::after {
  background-color: transparent !important;
}

.form-select-flush {
  background-position: right center;
  border: 0;
  border-bottom: var(--cz-border-width) solid var(--cz-border-color) !important;
  border-radius: 0;
}
.form-select-flush.form-select-lg {
  background-size: 17px 13px;
}

.form-icon-start {
  padding-left: calc(1rem + var(--cz-border-width) + 1rem + 0.375rem);
}
.form-icon-start.form-control-lg {
  padding-left: calc(1.125rem + var(--cz-border-width) + 1.125rem + 0.25rem);
}
.form-icon-start.form-control-sm {
  padding-left: calc(0.875rem + var(--cz-border-width) + 0.875rem);
}

.form-icon-end {
  padding-right: calc(1rem + var(--cz-border-width) + 1rem + 0.375rem);
}
.form-icon-end.form-control-lg {
  padding-right: calc(1.125rem + var(--cz-border-width) + 1.125rem + 0.25rem);
}
.form-icon-end.form-control-sm {
  padding-right: calc(0.875rem + var(--cz-border-width) + 0.875rem);
}

.form-check-label {
  font-size: 0.875rem;
}

.form-check-input:focus {
  box-shadow: none;
}
.form-check-input:focus-visible {
  box-shadow: 0 0 0 0.25rem var(--cz-focus-ring-color);
}
.form-check-input:active {
  background-color: transparent;
  filter: none;
}

.form-switch {
  min-height: 1.5rem;
  margin-bottom: 0.75rem;
}
.form-switch .form-check-input {
  height: 1.5rem;
  margin-top: 0;
  background-color: #cad0d9;
  border: 0 !important;
}
.form-switch .form-check-input:checked {
  background-color: #33b36b !important;
}

.password-toggle {
  position: relative;
}
.password-toggle .form-control {
  padding-right: 2.875rem;
}

.password-toggle-button {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
  color: var(--cz-body-color);
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}
.password-toggle-button:hover {
  color: var(--cz-emphasis-color);
}

.count-input {
  display: inline-flex;
  overflow: hidden;
  background-color: #fff;
  border: var(--cz-border-width) solid #cad0d9;
  border-radius: var(--cz-border-radius);
  transform: translateZ(0);
}
.count-input .form-control {
  width: 2.5rem;
  padding: 0 0.25rem;
  font-weight: 500;
  text-align: center;
  /* stylelint-disable property-no-vendor-prefix */
  -moz-appearance: textfield;
  appearance: textfield;
  /* stylelint-enable property-no-vendor-prefix */
  background-color: transparent;
  border: 0;
  border-radius: 0;
}
.count-input .form-control::-webkit-outer-spin-button, .count-input .form-control::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
.count-input .btn {
  border: 0;
  border-radius: 0;
}
.count-input .btn:not(.btn-primary) {
  --cz-btn-hover-color: var(--cz-component-hover-color);
  --cz-btn-hover-bg: var(--cz-secondary-bg);
  --cz-btn-active-bg: var(--cz-secondary-bg);
}
.count-input .btn-sm + .form-control, .count-input .btn-group-sm > .btn + .form-control {
  width: 2rem;
}
.count-input .btn-lg + .form-control, .count-input .btn-group-lg > .btn + .form-control {
  width: 3rem;
}
.count-input.disabled {
  background-color: var(--cz-tertiary-bg);
  border-color: var(--cz-border-color);
  border-style: dashed;
}

.count-input-collapsible.collapsed [data-decrement],
.count-input-collapsible.collapsed .form-control {
  display: none;
}

.range-slider {
  --cz-range-slider-height: 0.125rem;
  --cz-range-slider-bg: var(--cz-border-color);
  --cz-range-slider-connect-bg: var(--cz-emphasis-color);
  --cz-range-slider-handle-size: 0.8125rem;
  --cz-range-slider-handle-bg: var(--cz-body-bg);
  --cz-range-slider-handle-active-bg: var(--cz-emphasis-color);
  --cz-range-slider-handle-border-width: 2px;
  --cz-range-slider-handle-border-color: var(--cz-emphasis-color);
  --cz-range-slider-handle-border-radius: 50%;
  --cz-range-slider-pips-color: var(--cz-body-color);
  --cz-range-slider-pips-font-size: 0.75rem;
  --cz-range-slider-pips-border-width: var(--cz-border-width);
  --cz-range-slider-pips-border-color: #cdd5df;
  --cz-range-slider-tooltip-padding-y: 0.375rem;
  --cz-range-slider-tooltip-padding-x: 0.625rem;
  --cz-range-slider-tooltip-bg: transparent;
  --cz-range-slider-tooltip-color: var(--cz-emphasis-color);
  --cz-range-slider-tooltip-font-size: 0.75rem;
  --cz-range-slider-tooltip-border-radius: var(--cz-border-radius-sm);
}

/* stylelint-disable selector-class-pattern */
.range-slider-ui {
  height: var(--cz-range-slider-height);
  margin: 0;
  margin-top: 2.25rem;
  margin-right: 0;
  margin-bottom: 1.75rem;
  margin-left: 0;
  background-color: var(--cz-range-slider-bg);
  border: 0;
  box-shadow: none;
}
.range-slider-ui .noUi-connect {
  background-color: var(--cz-range-slider-connect-bg);
}
.range-slider-ui .noUi-handle {
  top: 50%;
  width: var(--cz-range-slider-handle-size);
  height: var(--cz-range-slider-handle-size);
  margin-top: calc(var(--cz-range-slider-handle-size) * -0.5);
  background-color: var(--cz-range-slider-handle-bg);
  border: var(--cz-range-slider-handle-border-width) solid var(--cz-range-slider-handle-border-color);
  border-radius: var(--cz-range-slider-handle-border-radius);
  box-shadow: none;
}
.range-slider-ui .noUi-handle::before, .range-slider-ui .noUi-handle::after {
  display: none;
}
.range-slider-ui .noUi-handle:active, .range-slider-ui .noUi-handle:focus-visible {
  background-color: var(--cz-range-slider-handle-active-bg);
}
.range-slider-ui .noUi-handle:focus {
  outline: none;
}
.range-slider-ui .noUi-handle:focus-visible {
  box-shadow: 0 0 0 0.25rem var(--cz-focus-ring-color);
}
.range-slider-ui .noUi-marker-normal {
  display: none;
}
.range-slider-ui .noUi-marker-horizontal.noUi-marker {
  width: var(--cz-range-slider-pips-border-width);
  background-color: var(--cz-range-slider-pips-border-color);
}
.range-slider-ui .noUi-marker-horizontal.noUi-marker-large {
  height: 0.75rem;
}
.range-slider-ui .noUi-value {
  padding-top: 0.125rem;
  font-size: var(--cz-range-slider-pips-font-size);
  color: var(--cz-range-slider-pips-color);
}
.range-slider-ui .noUi-tooltip {
  padding: var(--cz-range-slider-tooltip-padding-y) var(--cz-range-slider-tooltip-padding-x);
  line-height: 1.2;
  font-size: var(--cz-range-slider-tooltip-font-size);
  font-weight: 500;
  color: var(--cz-range-slider-tooltip-color);
  background-color: var(--cz-range-slider-tooltip-bg);
  border: 0;
  border-radius: var(--cz-range-slider-tooltip-border-radius);
}

html:not([dir=rtl]) .range-slider-ui.noUi-horizontal .noUi-handle {
  right: calc(var(--cz-range-slider-handle-size) * -0.5);
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  /* rtl:begin:ignore */
  left: -0.5rem;
  /* rtl:end:ignore */
}

/* stylelint-enable selector-class-pattern */
.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: var(--cz-form-control-border-color);
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: var(--cz-form-control-focus-border-color);
}

.was-validated .password-toggle .form-control:valid,
.password-toggle .form-control.is-valid,
.was-validated .password-toggle .form-control:invalid,
.password-toggle .form-control.is-invalid {
  background-position: center right 2.25rem;
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border: var(--cz-border-width) solid #cad0d9;
}
.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: #fff;
  border-color: #181d25;
}
.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: none;
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: var(--cz-body-color);
}

.was-validated .form-switch .form-check-input:invalid,
.form-switch .form-check-input.is-invalid {
  background-color: var(--cz-form-invalid-color) !important;
}

.valid-tooltip {
  color: var(--cz-form-valid-color);
  background-color: var(--cz-success-bg-subtle);
}

.invalid-tooltip {
  color: var(--cz-form-invalid-color);
  background-color: var(--cz-danger-bg-subtle);
}

[data-input-format] {
  direction: ltr;
}

[data-bs-theme=dark] .form-control:not([data-bs-theme=light]),
[data-bs-theme=dark] .form-select:not([data-bs-theme=light]) {
  --cz-form-control-bg: transparent;
  --cz-form-control-border-color: #4e5562;
  --cz-form-control-focus-bg: transparent;
  --cz-form-control-focus-border-color: #fff;
}
[data-bs-theme=dark] .form-select:not([data-bs-theme=light]) option {
  background-color: #181d25;
}
[data-bs-theme=dark] .form-check-input:not([data-bs-theme=light]) {
  background-color: transparent;
  border: var(--cz-border-width) solid #4e5562;
}
[data-bs-theme=dark] .form-check-input:not([data-bs-theme=light]):checked {
  background-color: transparent;
  border-color: #fff;
}
[data-bs-theme=dark] .form-check-input:not([data-bs-theme=light]):checked[type=checkbox] {
  --cz-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
[data-bs-theme=dark] .form-check-input:not([data-bs-theme=light]):checked[type=radio] {
  --cz-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
[data-bs-theme=dark] .form-check-input:not([data-bs-theme=light])[type=checkbox]:indeterminate {
  background-color: transparent;
  border-color: #fff;
  --cz-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
[data-bs-theme=dark] .was-validated .form-check-input:invalid,
[data-bs-theme=dark] .form-check-input.is-invalid {
  border-color: var(--cz-form-invalid-border-color);
}
[data-bs-theme=dark] .form-switch:not([data-bs-theme=light]) .form-check-input {
  background-color: #4e5562;
  border: 0;
}
[data-bs-theme=dark] .form-switch:not([data-bs-theme=light]) .form-check-input:checked {
  background-color: #33b36b;
}
[data-bs-theme=dark] .form-switch:not([data-bs-theme=light]) .form-check-input:not(:checked):not(:focus) {
  --cz-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
[data-bs-theme=dark] .count-input:not([data-bs-theme=light]) {
  background-color: transparent;
  border-color: #4e5562;
}
[data-bs-theme=dark] .input-group:not([data-bs-theme=light]) .input-group-text {
  border-color: #4e5562;
}

.btn {
  --cz-btn-active-border-color: transparent;
  --cz-btn-disabled-border-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn:focus-visible {
  box-shadow: 0 0 0 0.25rem var(--cz-focus-ring-color);
}
.btn:not(.btn-icon) [class*=" ci-chevron"], .btn:not(.btn-icon) [class^=ci-chevron] {
  margin-top: 0.125rem;
}

.btn-primary, [data-bs-theme=dark] .product-card:hover .product-card-button, .product-card:hover .product-card-button,
.product-card .count-input:not(.collapsed) .product-card-button {
  --cz-btn-hover-bg: #23537c;
  --cz-btn-active-bg: #23537c;
  --cz-btn-hover-border-color: #23537c;
  --cz-btn-active-border-color: #23537c;
}

.btn-success {
  --cz-btn-hover-bg: #288b53;
  --cz-btn-active-bg: #288b53;
  --cz-btn-hover-border-color: #288b53;
  --cz-btn-active-border-color: #288b53;
}

.btn-info {
  --cz-btn-hover-bg: #2358ae;
  --cz-btn-active-bg: #2358ae;
  --cz-btn-hover-border-color: #2358ae;
  --cz-btn-active-border-color: #2358ae;
}

.btn-warning {
  --cz-btn-hover-bg: #f67804;
  --cz-btn-active-bg: #f67804;
  --cz-btn-hover-border-color: #f67804;
  --cz-btn-active-border-color: #f67804;
}

.btn-danger {
  --cz-btn-hover-bg: #e81212;
  --cz-btn-active-bg: #e81212;
  --cz-btn-hover-border-color: #e81212;
  --cz-btn-active-border-color: #e81212;
}

.btn-secondary {
  --cz-btn-color: #333d4c;
  --cz-btn-bg: #eef1f6;
  --cz-btn-border-color: #eef1f6;
  --cz-btn-hover-color: #181d25;
  --cz-btn-hover-bg: #e0e5eb;
  --cz-btn-hover-border-color: #e0e5eb;
  --cz-btn-active-color: #333d4c;
  --cz-btn-active-bg: #e0e5eb;
  --cz-btn-active-border-color: #e0e5eb;
  --cz-btn-disabled-color: #333d4c;
  --cz-btn-disabled-bg: #eef1f6;
  --cz-btn-disabled-border-color: #eef1f6;
}

.btn-dark {
  --cz-btn-hover-bg: #131920;
  --cz-btn-hover-border-color: #131920;
  --cz-btn-active-bg: #131920;
  --cz-btn-active-border-color: #131920;
}

.btn-light, [data-bs-theme=dark] .btn-dark:not([data-bs-theme=light]) {
  --cz-btn-color: #181d25;
  --cz-btn-hover-color: #181d25;
  --cz-btn-hover-bg: #e0e5eb;
  --cz-btn-hover-border-color: #e0e5eb;
  --cz-btn-active-color: #181d25;
  --cz-btn-active-bg: #e0e5eb;
  --cz-btn-active-border-color: #e0e5eb;
}

.btn-link {
  font-weight: 500;
}

.btn-outline-secondary {
  --cz-btn-color: #333d4c;
  --cz-btn-border-color: #e0e5eb;
  --cz-btn-hover-color: #181d25;
  --cz-btn-hover-bg: transparent;
  --cz-btn-hover-border-color: #6c727f;
  --cz-btn-active-color: #181d25;
  --cz-btn-active-bg: transparent;
  --cz-btn-active-border-color: #181d25;
  --cz-btn-disabled-color: #333d4c;
  --cz-btn-disabled-border-color: #e0e5eb;
}

.btn-ghost {
  background-color: transparent;
  border-color: transparent;
}
.btn-ghost:hover, .btn-ghost:focus-visible, .btn-ghost.show {
  background-color: var(--cz-btn-bg);
  border-color: var(--cz-btn-border-color);
}
.btn-ghost.active {
  background-color: var(--cz-btn-active-bg);
  border-color: var(--cz-btn-active-border-color);
}

.btn-icon {
  --cz-btn-size: 2.5rem;
  flex-shrink: 0;
  width: var(--cz-btn-size);
  height: var(--cz-btn-size);
  padding: 0;
}
.btn-icon.btn-lg, .btn-group-lg > .btn-icon.btn {
  --cz-btn-size: 3rem;
}
.btn-icon.btn-sm, .btn-group-sm > .btn-icon.btn {
  --cz-btn-size: 2rem;
}

.btn-market {
  --cz-btn-padding-y: .625rem;
  --cz-btn-padding-x: 1rem;
  --cz-btn-border-width: 0;
}
.btn-market.btn-lg, .btn-group-lg > .btn-market.btn {
  --cz-btn-padding-y: .75rem;
  --cz-btn-padding-x: 1.125rem;
}

.btn-color {
  --cz-btn-active-border-color: var(--cz-tertiary-color);
  display: inline-block;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  padding: 0.125rem;
  border-radius: 50%;
}
.btn-color::before {
  display: flex;
  width: 100%;
  height: 100%;
  content: "";
  background-color: currentcolor;
  border-radius: 50%;
}

.btn-image {
  --cz-btn-active-border-color: var(--cz-component-active-color);
}

.btn-scroll-top {
  --cz-btn-padding-x: .75rem;
  --cz-btn-padding-y: calc(0.375rem + var(--cz-border-width));
  position: relative;
  font-size: 0.625rem;
  text-transform: uppercase;
  visibility: hidden;
  opacity: 0;
  transition: transform 0.25s ease-in-out, opacity 0.25s, visibility 0.25s;
  transform: scale(0);
}
.btn-scroll-top.show {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

[data-sort] {
  display: inline-flex;
  align-items: center;
}
[data-sort]::after {
  width: 1.25em;
  margin-top: 0.125em;
  font-size: 1.25em;
  content: "↕";
}
[data-sort].asc::after {
  content: "↑";
  transform: scale(0.75);
}
[data-sort].desc::after {
  content: "↓";
  transform: scale(0.75);
}

.btn .badge {
  top: 0;
}

.floating-buttons {
  transform: translateX(50%) rotate(-90deg);
}

[data-bs-theme=dark] .btn-secondary:not([data-bs-theme=light]) {
  --cz-btn-color: #e0e5eb;
  --cz-btn-bg: #333d4c;
  --cz-btn-border-color: #333d4c;
  --cz-btn-hover-color: #fff;
  --cz-btn-hover-bg: #4e5562;
  --cz-btn-hover-border-color: #4e5562;
  --cz-btn-active-color: #e0e5eb;
  --cz-btn-active-bg: #4e5562;
  --cz-btn-active-border-color: #4e5562;
  --cz-btn-disabled-color: #e0e5eb;
  --cz-btn-disabled-bg: #333d4c;
  --cz-btn-disabled-border-color: #333d4c;
}
[data-bs-theme=dark] .btn-link:not([data-bs-theme=light]) {
  --cz-btn-disabled-color: #9ca3af;
}
[data-bs-theme=dark] .btn-outline-secondary:not([data-bs-theme=light]) {
  --cz-btn-color: #e0e5eb;
  --cz-btn-border-color: #333d4c;
  --cz-btn-hover-color: #fff;
  --cz-btn-hover-border-color: #9ca3af;
  --cz-btn-active-color: #fff;
  --cz-btn-active-border-color: #fff;
  --cz-btn-disabled-color: #e0e5eb;
  --cz-btn-disabled-border-color: #333d4c;
}
[data-bs-theme=dark] .btn-outline-dark:not([data-bs-theme=light]) {
  --cz-btn-color: #f5f7fa;
  --cz-btn-border-color: #f5f7fa;
  --cz-btn-hover-color: #181d25;
  --cz-btn-hover-bg: #fff;
  --cz-btn-hover-border-color: #fff;
  --cz-btn-active-color: #181d25;
  --cz-btn-active-bg: #fff;
  --cz-btn-active-border-color: #fff;
  --cz-btn-disabled-color: #f5f7fa;
  --cz-btn-disabled-border-color: #f5f7fa;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
}
.dropdown-toggle::after {
  flex-shrink: 0;
  width: 1.275em;
  height: 1.275em;
  margin-right: -0.25rem;
  content: "";
  background-color: currentcolor;
  border: 0 !important;
  -webkit-mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M8.7,13.7c0.4-0.4,1.1-0.4,1.5,0l6,6l6-6c0.4-0.4,1.1-0.4,1.5,0s0.4,1.1,0,1.5l-6.7,6.7c-0.4,0.4-1.1,0.4-1.5,0l-6.7-6.7C8.3,14.6,8.3,14.1,8.7,13.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M8.7,13.7c0.4-0.4,1.1-0.4,1.5,0l6,6l6-6c0.4-0.4,1.1-0.4,1.5,0s0.4,1.1,0,1.5l-6.7,6.7c-0.4,0.4-1.1,0.4-1.5,0l-6.7-6.7C8.3,14.6,8.3,14.1,8.7,13.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
}

.dropup .dropdown-toggle::after {
  -webkit-mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M15.3,13.3c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7c0.4,0.4,0.4,1.1,0,1.5s-1.1,0.4-1.5,0l-6-6l-6,6c-0.4,0.4-1.1,0.4-1.5,0s-0.4-1.1,0-1.5L15.3,13.3z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M15.3,13.3c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7c0.4,0.4,0.4,1.1,0,1.5s-1.1,0.4-1.5,0l-6-6l-6,6c-0.4,0.4-1.1,0.4-1.5,0s-0.4-1.1,0-1.5L15.3,13.3z'/%3e%3c/svg%3e") no-repeat 50% 50%;
}

.dropend .dropdown-toggle::after {
  -webkit-mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M12.7,9.7c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7c0.4,0.4,0.4,1.1,0,1.5l-6.7,6.7c-0.4,0.4-1.1,0.4-1.5,0s-0.4-1.1,0-1.5l6-6l-6-6C12.3,10.6,12.3,10.1,12.7,9.7L12.7,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M12.7,9.7c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7c0.4,0.4,0.4,1.1,0,1.5l-6.7,6.7c-0.4,0.4-1.1,0.4-1.5,0s-0.4-1.1,0-1.5l6-6l-6-6C12.3,10.6,12.3,10.1,12.7,9.7L12.7,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
}

[dir=rtl] .dropend .dropdown-toggle::after {
  transform: rotate(180deg);
}

.dropstart .dropdown-toggle::before {
  flex-shrink: 0;
  width: 1.275em;
  height: 1.275em;
  margin-left: -0.25rem;
  content: "";
  background-color: currentcolor;
  border: 0 !important;
  -webkit-mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M19.3,9.7c0.4,0.4,0.4,1.1,0,1.5L13.5,17l6,6c0.4,0.4,0.4,1.1,0,1.5s-1.1,0.4-1.5,0l-6.7-6.7c-0.4-0.4-0.4-1.1,0-1.5L18,9.7C18.4,9.3,18.9,9.3,19.3,9.7L19.3,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M19.3,9.7c0.4,0.4,0.4,1.1,0,1.5L13.5,17l6,6c0.4,0.4,0.4,1.1,0,1.5s-1.1,0.4-1.5,0l-6.7-6.7c-0.4-0.4-0.4-1.1,0-1.5L18,9.7C18.4,9.3,18.9,9.3,19.3,9.7L19.3,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
}

[dir=rtl] .dropstart .dropdown-toggle::before {
  transform: rotate(180deg);
}

.dropdown-toggle-split::after, .dropdown-toggle-split::before {
  margin: 0 -0.25rem;
}

.dropdown:has(:checked) .filter-select {
  --cz-btn-border-color: #181d25;
  --cz-btn-hover-border-color: #181d25;
}

.dropdown-menu {
  --cz-dropdown-item-border-radius: calc(var(--cz-border-radius) * 0.75);
  --cz-dropdown-item-spacer: 0.125rem;
  padding-bottom: calc(var(--cz-dropdown-padding-y) - var(--cz-dropdown-item-spacer));
}
.dropdown-menu.show {
  animation: fade-in 0.15s ease-in-out;
}
.dropdown-menu::before, .dropdown-menu::after {
  position: absolute;
  display: block;
  content: "";
}

.dropdown .dropdown-menu,
.dropup .dropdown-menu {
  margin-top: var(--cz-dropdown-spacer) !important;
  margin-bottom: var(--cz-dropdown-spacer) !important;
}
.dropdown .dropdown-menu::before, .dropdown .dropdown-menu::after,
.dropup .dropdown-menu::before,
.dropup .dropdown-menu::after {
  left: 0;
  width: 100%;
  height: calc(var(--cz-dropdown-spacer) * 1.5);
}
.dropdown .dropdown-menu::before,
.dropup .dropdown-menu::before {
  bottom: 100%;
}
.dropdown .dropdown-menu::after,
.dropup .dropdown-menu::after {
  top: 100%;
}

.dropstart .dropdown-menu,
.dropend .dropdown-menu {
  margin-right: var(--cz-dropdown-spacer) !important;
  margin-left: var(--cz-dropdown-spacer) !important;
}
.dropstart .dropdown-menu::before, .dropstart .dropdown-menu::after,
.dropend .dropdown-menu::before,
.dropend .dropdown-menu::after {
  top: 0;
  width: calc(var(--cz-dropdown-spacer) * 2);
  height: 100%;
}
.dropstart .dropdown-menu::before,
.dropend .dropdown-menu::before {
  left: calc(var(--cz-dropdown-spacer) * -2);
}
.dropstart .dropdown-menu::after,
.dropend .dropdown-menu::after {
  left: 100%;
}

.dropdown-item {
  display: flex;
  align-items: center;
  margin-bottom: var(--cz-dropdown-item-spacer);
  border-radius: var(--cz-dropdown-item-border-radius);
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
.dropdown-item.show, [data-bs-toggle].show > .dropdown-item, [data-bs-toggle]:hover > .dropdown-item, .dropdown-item:active {
  color: var(--cz-dropdown-link-hover-color);
  background-color: var(--cz-dropdown-link-hover-bg);
}
.dropdown-item:focus-visible {
  outline: none;
  box-shadow: 0 0 0 0.25rem var(--cz-focus-ring-color);
}
.dropdown-item.dropdown-toggle::after {
  margin-left: auto;
}
.dropdown-item .item-active-indicator {
  display: none;
}
.dropdown-item.active .item-active-indicator {
  display: flex;
  padding-left: 0.5rem;
}

[data-bs-theme=dark] .dropdown:not([data-bs-theme=light]):has(:checked) .filter-select {
  --cz-btn-border-color: #fff;
  --cz-btn-hover-border-color: #fff;
}

.nav {
  --cz-nav-link-line-height: 1.375rem;
  --cz-nav-link-active-color: var(--cz-component-active-color);
  flex-direction: row;
  gap: 0;
}

.nav-link {
  display: flex;
  align-items: center;
}
.nav-link.show {
  color: var(--cz-nav-link-hover-color);
}
.nav-link.active {
  color: var(--cz-nav-link-active-color);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--cz-nav-link-disabled-color);
}
.nav-link.text-body:hover, .nav-link.text-body:focus-visible, .nav-link.text-body.show, .nav-link.text-body-secondary:hover, .nav-link.text-body-secondary:focus-visible, .nav-link.text-body-secondary.show, .nav-link.text-body-tertiary:hover, .nav-link.text-body-tertiary:focus-visible, .nav-link.text-body-tertiary.show {
  color: var(--cz-nav-link-hover-color) !important;
}
.nav-link.text-body.active, .nav-link.text-body-secondary.active, .nav-link.text-body-tertiary.active {
  color: var(--cz-nav-link-active-color) !important;
}
.nav-link.text-body.disabled, .nav-link.text-body:disabled, .nav-link.text-body-secondary.disabled, .nav-link.text-body-secondary:disabled, .nav-link.text-body-tertiary.disabled, .nav-link.text-body-tertiary:disabled {
  color: var(--cz-nav-link-disabled-color) !important;
}

.nav-tabs {
  --cz-nav-tabs-padding: 0.375rem;
  --cz-nav-tabs-bg: var(--cz-tertiary-bg);
  --cz-nav-tabs-link-hover-bg: #eef1f6;
  --cz-nav-tabs-link-active-box-shadow: 0 0.375rem 1.5rem rgba(103, 111, 123, 0.06);
  gap: var(--cz-nav-tabs-padding);
  padding: var(--cz-nav-tabs-padding);
  background-color: var(--cz-nav-tabs-bg);
  border-radius: var(--cz-nav-tabs-border-radius);
}
.nav-tabs .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.nav-tabs .nav-link {
  justify-content: center;
  width: 100%;
  line-height: var(--cz-nav-link-line-height);
  border-radius: var(--cz-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link.show, .nav-tabs .nav-link:focus-visible {
  background-color: var(--cz-nav-tabs-link-hover-bg);
}
.nav-tabs .nav-link:focus-visible {
  box-shadow: 0 0 0 0.25rem var(--cz-focus-ring-color) !important;
}
.nav-tabs .nav-link.active {
  background-color: var(--cz-nav-tabs-link-active-bg);
  box-shadow: var(--cz-nav-tabs-link-active-box-shadow);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  background: none;
}
.nav-tabs.flex-column .nav-link {
  justify-content: start;
}
.nav-tabs .dropdown-menu {
  border-radius: var(--cz-dropdown-border-radius);
}

.nav-pills {
  --cz-nav-pills-gap: 0.875rem 1.125rem;
  --cz-nav-pills-link-border-width: var(--cz-border-width);
  --cz-nav-pills-link-border-color: var(--cz-border-color);
  --cz-nav-pills-link-hover-color: var(--cz-component-hover-color);
  --cz-nav-pills-link-hover-border-color: #6c727f;
  --cz-nav-pills-link-active-border-color: var(--cz-component-active-color);
  --cz-nav-pills-link-disabled-color: var(--cz-component-disabled-color);
  --cz-nav-pills-link-disabled-border-color: var(--cz-border-color);
  gap: var(--cz-nav-pills-gap);
}
.nav-pills .nav-link {
  line-height: var(--cz-nav-link-line-height);
  border: var(--cz-nav-pills-link-border-width) solid var(--cz-nav-pills-link-border-color);
}
.nav-pills .nav-link:hover, .nav-pills .nav-link.show, .nav-pills .nav-link:focus-visible {
  color: var(--cz-nav-pills-link-hover-color);
  border-color: var(--cz-nav-pills-link-hover-border-color);
}
.nav-pills .nav-link.active {
  border-color: var(--cz-nav-pills-link-active-border-color);
}
.nav-pills .nav-link.disabled, .nav-pills .nav-link:disabled {
  color: var(--cz-nav-pills-link-disabled-color);
  border-color: var(--cz-nav-pills-link-disabled-border-color);
}

.nav-underline {
  gap: var(--cz-nav-underline-gap);
}
.nav-underline .nav-link {
  line-height: var(--cz-nav-link-line-height);
  border: 0;
  position: relative;
}
.nav-underline .nav-link::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--cz-nav-underline-border-width);
  content: "";
  background-color: currentcolor;
  transition: transform 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: bottom right;
}
.nav-underline .nav-link::before {
  bottom: calc(var(--cz-nav-underline-border-width) * -1);
}
.nav-underline .nav-link.active {
  font-weight: 500;
  background-color: transparent;
}
.nav-underline .nav-link.active::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.nav-underline.flex-column .nav-link {
  padding: calc(var(--cz-nav-link-padding-y) * 0.5) var(--cz-nav-link-padding-x);
}
.nav-underline.flex-column .nav-link::before {
  top: 0;
  left: calc(var(--cz-nav-underline-border-width) * -1);
  width: var(--cz-nav-underline-border-width);
  height: 100%;
  transform: scaleY(0);
  transform-origin: bottom left;
}
.nav-underline.flex-column .nav-link.active::before {
  transform: scaleY(1);
  transform-origin: top left;
}

.nav-fill:not(.flex-column) .nav-link,
.nav-justified:not(.flex-column) .nav-link {
  justify-content: center;
}

[data-bs-theme=dark] .nav-tabs:not([data-bs-theme=light]) .nav-link {
  --cz-nav-tabs-link-hover-bg: rgba(51, 61, 76, 0.4);
  --cz-nav-tabs-link-active-bg: #333d4c;
  --cz-nav-tabs-link-active-box-shadow: none;
}

.navbar {
  --cz-navbar-brand-font-weight: 600;
}

.navbar-brand {
  display: inline-flex;
  align-items: center;
  font-weight: var(--cz-navbar-brand-font-weight);
}

.navbar-nav {
  --cz-nav-link-font-size: 1rem;
  --cz-nav-link-underline-border-width: var(--cz-underline-thickness);
  gap: 0;
}
.navbar-nav > .nav-item > .nav-link {
  padding: var(--cz-nav-link-padding-y) var(--cz-navbar-nav-link-padding-x);
  position: relative;
}
.navbar-nav > .nav-item > .nav-link::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--cz-nav-link-underline-border-width);
  content: "";
  background-color: currentcolor;
  transition: transform 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: bottom right;
}
.navbar-nav > .nav-item > .nav-link::before {
  top: calc(var(--cz-nav-link-padding-y) * 0.75);
  width: var(--cz-nav-link-underline-border-width);
  height: calc(100% - var(--cz-nav-link-padding-y) * 1.5);
  transform: scaleY(0);
  transform-origin: bottom left;
}
.navbar-nav > .nav-item > .nav-link:hover::before, .navbar-nav > .nav-item > .nav-link.show::before, .navbar-nav > .nav-item > .nav-link:focus-visible::before, .navbar-nav > .nav-item > .nav-link.active::before {
  transform: scaleY(1);
  transform-origin: top left;
}
.navbar-nav > .nav-item > .nav-link.dropdown-toggle::after {
  margin-left: auto;
}
.navbar-nav:hover > .nav-item > .nav-link.active:not(:hover):not(.show):not(:focus-visible)::before {
  transform: scaleY(0);
  transform-origin: bottom left;
}
.navbar-nav .dropdown-menu {
  --cz-dropdown-box-shadow: none;
  position: static;
}
.navbar-nav .dropdown-menu .dropup .dropdown-toggle::after,
.navbar-nav .dropdown-menu .dropend .dropdown-toggle::after,
.navbar-nav .dropdown-menu .dropstart .dropdown-toggle::after {
  -webkit-mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M8.7,13.7c0.4-0.4,1.1-0.4,1.5,0l6,6l6-6c0.4-0.4,1.1-0.4,1.5,0s0.4,1.1,0,1.5l-6.7,6.7c-0.4,0.4-1.1,0.4-1.5,0l-6.7-6.7C8.3,14.6,8.3,14.1,8.7,13.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M8.7,13.7c0.4-0.4,1.1-0.4,1.5,0l6,6l6-6c0.4-0.4,1.1-0.4,1.5,0s0.4,1.1,0,1.5l-6.7,6.7c-0.4,0.4-1.1,0.4-1.5,0l-6.7-6.7C8.3,14.6,8.3,14.1,8.7,13.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
}
.navbar-nav .dropdown-menu .dropstart .dropdown-toggle::before {
  display: none;
}
.navbar-nav .dropdown-menu .dropstart .dropdown-toggle::after {
  display: inline-block;
  width: 1.275em;
  height: 1.275em;
  margin-right: -0.125rem;
  content: "";
  background-color: currentcolor;
  border: 0 !important;
  -webkit-mask-size: cover;
  mask-size: cover;
}
.navbar-nav .dropdown-menu .dropend .dropdown-menu,
.navbar-nav .dropdown-menu .dropstart .dropdown-menu {
  margin: var(--cz-dropdown-spacer) 0 !important;
}
.navbar-nav .dropdown > .dropdown-menu::before, .navbar-nav .dropdown > .dropdown-menu::after,
.navbar-nav .dropup > .dropdown-menu::before,
.navbar-nav .dropup > .dropdown-menu::after {
  height: calc(var(--cz-dropdown-spacer) * 1.125);
}

[dir=rtl] .navbar-nav .dropdown-menu .dropup .dropdown-toggle::after,
[dir=rtl] .navbar-nav .dropdown-menu .dropend .dropdown-toggle::after,
[dir=rtl] .navbar-nav .dropdown-menu .dropstart .dropdown-toggle::after {
  -webkit-mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M15.3,13.3c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7c0.4,0.4,0.4,1.1,0,1.5s-1.1,0.4-1.5,0l-6-6l-6,6c-0.4,0.4-1.1,0.4-1.5,0s-0.4-1.1,0-1.5L15.3,13.3z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M15.3,13.3c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7c0.4,0.4,0.4,1.1,0,1.5s-1.1,0.4-1.5,0l-6-6l-6,6c-0.4,0.4-1.1,0.4-1.5,0s-0.4-1.1,0-1.5L15.3,13.3z'/%3e%3c/svg%3e") no-repeat 50% 50%;
}

[data-bs-theme=light] .navbar-dark:not([data-bs-theme=dark]) .offcanvas .navbar-nav {
  --cz-nav-link-color: #333d4c;
  --cz-nav-link-hover-color: #181d25;
  --cz-navbar-active-color: #181d25;
  --cz-nav-link-disabled-color: #9ca3af;
}

.navbar-toggler {
  position: relative;
  width: 1.875rem;
  height: 2.25rem;
}

.navbar-toggler-icon {
  top: 50%;
  display: block;
  margin-top: -0.0625rem;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 0.075s;
}
.navbar-toggler-icon, .navbar-toggler-icon::before, .navbar-toggler-icon::after {
  position: absolute;
  width: 1.375rem;
  height: 0.125rem;
  background-color: var(--cz-navbar-color);
  transition-property: transform;
}
.navbar-toggler-icon::before, .navbar-toggler-icon::after {
  display: block;
  content: "";
}
.navbar-toggler-icon::before {
  top: -0.4375rem;
  transition: top 0.075s 0.12s ease, opacity 0.075s ease, width 0.15s ease-in-out;
}
.navbar-toggler-icon::after {
  right: 0;
  bottom: -0.4375rem;
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19), width 0.15s ease-in-out;
}

.navbar-toggler:not(.active):not([aria-expanded=true]):hover .navbar-toggler-icon::before, .navbar-toggler:not(.active):not([aria-expanded=true]):hover .navbar-toggler-icon::after {
  width: 1.03125rem;
}

.navbar-toggler.active .navbar-toggler-icon,
[aria-expanded=true] .navbar-toggler-icon {
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: rotate(45deg);
}
.navbar-toggler.active .navbar-toggler-icon::before, .navbar-toggler.active .navbar-toggler-icon::after,
[aria-expanded=true] .navbar-toggler-icon::before,
[aria-expanded=true] .navbar-toggler-icon::after {
  width: 1.375rem;
}
.navbar-toggler.active .navbar-toggler-icon::before,
[aria-expanded=true] .navbar-toggler-icon::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
.navbar-toggler.active .navbar-toggler-icon::after,
[aria-expanded=true] .navbar-toggler-icon::after {
  bottom: 0;
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: rotate(-90deg);
}

.navbar-stuck-hide.collapse {
  display: block;
}

@media (min-width: 500px) {
  .navbar-expand-sm .navbar-nav > .nav-item > .nav-link::before {
    top: auto;
    bottom: calc(var(--cz-nav-link-padding-y) * 0.875);
    left: var(--cz-navbar-nav-link-padding-x);
    width: calc(100% - var(--cz-navbar-nav-link-padding-x) * 2);
    height: var(--cz-nav-link-underline-border-width);
    transform: scaleX(0);
    transform-origin: bottom right;
  }
  .navbar-expand-sm .navbar-nav > .nav-item > .nav-link.dropdown-toggle::before {
    width: calc(100% - 1.275em - 0.15em - var(--cz-navbar-nav-link-padding-x) * 2);
  }
  .navbar-expand-sm .navbar-nav > .nav-item > .nav-link:hover::before, .navbar-expand-sm .navbar-nav > .nav-item > .nav-link.show::before, .navbar-expand-sm .navbar-nav > .nav-item > .nav-link:focus-visible::before, .navbar-expand-sm .navbar-nav > .nav-item > .nav-link.active::before {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  .navbar-expand-sm .navbar-nav > .nav-item > .nav-link.dropdown-toggle::after {
    margin-left: 0.15em;
  }
  .navbar-expand-sm .navbar-nav:hover > .nav-item > .nav-link.active:not(:hover):not(.show):not(:focus-visible)::before {
    transform: scaleX(0);
    transform-origin: bottom right;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    --cz-dropdown-box-shadow: var(--cz-box-shadow);
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu.show {
    animation: fade-up 0.25s ease-in-out;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu .dropend .dropdown-toggle::after {
    -webkit-mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M12.7,9.7c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7c0.4,0.4,0.4,1.1,0,1.5l-6.7,6.7c-0.4,0.4-1.1,0.4-1.5,0s-0.4-1.1,0-1.5l6-6l-6-6C12.3,10.6,12.3,10.1,12.7,9.7L12.7,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
    mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M12.7,9.7c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7c0.4,0.4,0.4,1.1,0,1.5l-6.7,6.7c-0.4,0.4-1.1,0.4-1.5,0s-0.4-1.1,0-1.5l6-6l-6-6C12.3,10.6,12.3,10.1,12.7,9.7L12.7,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu .dropstart .dropdown-toggle::after {
    display: none;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu .dropstart .dropdown-toggle::before {
    display: inline-block;
    width: 1.275em;
    height: 1.275em;
    margin-left: -0.25rem;
    content: "";
    background-color: currentcolor;
    border: 0 !important;
    -webkit-mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M19.3,9.7c0.4,0.4,0.4,1.1,0,1.5L13.5,17l6,6c0.4,0.4,0.4,1.1,0,1.5s-1.1,0.4-1.5,0l-6.7-6.7c-0.4-0.4-0.4-1.1,0-1.5L18,9.7C18.4,9.3,18.9,9.3,19.3,9.7L19.3,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
    mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M19.3,9.7c0.4,0.4,0.4,1.1,0,1.5L13.5,17l6,6c0.4,0.4,0.4,1.1,0,1.5s-1.1,0.4-1.5,0l-6.7-6.7c-0.4-0.4-0.4-1.1,0-1.5L18,9.7C18.4,9.3,18.9,9.3,19.3,9.7L19.3,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu .dropend .dropdown-menu,
  .navbar-expand-sm .navbar-nav .dropdown-menu .dropstart .dropdown-menu {
    margin: calc(var(--cz-dropdown-padding-y) * -1 + var(--cz-dropdown-border-width) * -1) var(--cz-dropdown-spacer) 0 var(--cz-dropdown-spacer) !important;
  }
  .navbar-expand-sm.navbar-dark:not([data-bs-theme=dark]) .offcanvas .navbar-nav {
    --cz-nav-link-color: var(--cz-navbar-color);
    --cz-nav-link-hover-color: var(--cz-navbar-hover-color);
    --cz-navbar-active-color: #fff;
    --cz-nav-link-disabled-color: var(--cz-navbar-disabled-color);
  }
  .navbar-expand-sm:not(.navbar-stuck) .dropdown-menu-static {
    display: block;
    animation: none !important;
  }
  .navbar-expand-sm.navbar-stuck .navbar-stuck-show {
    display: block !important;
  }
  .navbar-expand-sm.navbar-stuck .navbar-stuck-hide:not(.collapse):not(.collapsing) {
    display: none !important;
  }
  .navbar-expand-sm.navbar-stuck .navbar-stuck-hide.collapse:not(.show) {
    display: none;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav > .nav-item > .nav-link::before {
    top: auto;
    bottom: calc(var(--cz-nav-link-padding-y) * 0.875);
    left: var(--cz-navbar-nav-link-padding-x);
    width: calc(100% - var(--cz-navbar-nav-link-padding-x) * 2);
    height: var(--cz-nav-link-underline-border-width);
    transform: scaleX(0);
    transform-origin: bottom right;
  }
  .navbar-expand-md .navbar-nav > .nav-item > .nav-link.dropdown-toggle::before {
    width: calc(100% - 1.275em - 0.15em - var(--cz-navbar-nav-link-padding-x) * 2);
  }
  .navbar-expand-md .navbar-nav > .nav-item > .nav-link:hover::before, .navbar-expand-md .navbar-nav > .nav-item > .nav-link.show::before, .navbar-expand-md .navbar-nav > .nav-item > .nav-link:focus-visible::before, .navbar-expand-md .navbar-nav > .nav-item > .nav-link.active::before {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  .navbar-expand-md .navbar-nav > .nav-item > .nav-link.dropdown-toggle::after {
    margin-left: 0.15em;
  }
  .navbar-expand-md .navbar-nav:hover > .nav-item > .nav-link.active:not(:hover):not(.show):not(:focus-visible)::before {
    transform: scaleX(0);
    transform-origin: bottom right;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    --cz-dropdown-box-shadow: var(--cz-box-shadow);
  }
  .navbar-expand-md .navbar-nav .dropdown-menu.show {
    animation: fade-up 0.25s ease-in-out;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu .dropend .dropdown-toggle::after {
    -webkit-mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M12.7,9.7c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7c0.4,0.4,0.4,1.1,0,1.5l-6.7,6.7c-0.4,0.4-1.1,0.4-1.5,0s-0.4-1.1,0-1.5l6-6l-6-6C12.3,10.6,12.3,10.1,12.7,9.7L12.7,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
    mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M12.7,9.7c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7c0.4,0.4,0.4,1.1,0,1.5l-6.7,6.7c-0.4,0.4-1.1,0.4-1.5,0s-0.4-1.1,0-1.5l6-6l-6-6C12.3,10.6,12.3,10.1,12.7,9.7L12.7,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu .dropstart .dropdown-toggle::after {
    display: none;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu .dropstart .dropdown-toggle::before {
    display: inline-block;
    width: 1.275em;
    height: 1.275em;
    margin-left: -0.25rem;
    content: "";
    background-color: currentcolor;
    border: 0 !important;
    -webkit-mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M19.3,9.7c0.4,0.4,0.4,1.1,0,1.5L13.5,17l6,6c0.4,0.4,0.4,1.1,0,1.5s-1.1,0.4-1.5,0l-6.7-6.7c-0.4-0.4-0.4-1.1,0-1.5L18,9.7C18.4,9.3,18.9,9.3,19.3,9.7L19.3,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
    mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M19.3,9.7c0.4,0.4,0.4,1.1,0,1.5L13.5,17l6,6c0.4,0.4,0.4,1.1,0,1.5s-1.1,0.4-1.5,0l-6.7-6.7c-0.4-0.4-0.4-1.1,0-1.5L18,9.7C18.4,9.3,18.9,9.3,19.3,9.7L19.3,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu .dropend .dropdown-menu,
  .navbar-expand-md .navbar-nav .dropdown-menu .dropstart .dropdown-menu {
    margin: calc(var(--cz-dropdown-padding-y) * -1 + var(--cz-dropdown-border-width) * -1) var(--cz-dropdown-spacer) 0 var(--cz-dropdown-spacer) !important;
  }
  .navbar-expand-md.navbar-dark:not([data-bs-theme=dark]) .offcanvas .navbar-nav {
    --cz-nav-link-color: var(--cz-navbar-color);
    --cz-nav-link-hover-color: var(--cz-navbar-hover-color);
    --cz-navbar-active-color: #fff;
    --cz-nav-link-disabled-color: var(--cz-navbar-disabled-color);
  }
  .navbar-expand-md:not(.navbar-stuck) .dropdown-menu-static {
    display: block;
    animation: none !important;
  }
  .navbar-expand-md.navbar-stuck .navbar-stuck-show {
    display: block !important;
  }
  .navbar-expand-md.navbar-stuck .navbar-stuck-hide:not(.collapse):not(.collapsing) {
    display: none !important;
  }
  .navbar-expand-md.navbar-stuck .navbar-stuck-hide.collapse:not(.show) {
    display: none;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav > .nav-item > .nav-link::before {
    top: auto;
    bottom: calc(var(--cz-nav-link-padding-y) * 0.875);
    left: var(--cz-navbar-nav-link-padding-x);
    width: calc(100% - var(--cz-navbar-nav-link-padding-x) * 2);
    height: var(--cz-nav-link-underline-border-width);
    transform: scaleX(0);
    transform-origin: bottom right;
  }
  .navbar-expand-lg .navbar-nav > .nav-item > .nav-link.dropdown-toggle::before {
    width: calc(100% - 1.275em - 0.15em - var(--cz-navbar-nav-link-padding-x) * 2);
  }
  .navbar-expand-lg .navbar-nav > .nav-item > .nav-link:hover::before, .navbar-expand-lg .navbar-nav > .nav-item > .nav-link.show::before, .navbar-expand-lg .navbar-nav > .nav-item > .nav-link:focus-visible::before, .navbar-expand-lg .navbar-nav > .nav-item > .nav-link.active::before {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  .navbar-expand-lg .navbar-nav > .nav-item > .nav-link.dropdown-toggle::after {
    margin-left: 0.15em;
  }
  .navbar-expand-lg .navbar-nav:hover > .nav-item > .nav-link.active:not(:hover):not(.show):not(:focus-visible)::before {
    transform: scaleX(0);
    transform-origin: bottom right;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    --cz-dropdown-box-shadow: var(--cz-box-shadow);
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu.show {
    animation: fade-up 0.25s ease-in-out;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu .dropend .dropdown-toggle::after {
    -webkit-mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M12.7,9.7c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7c0.4,0.4,0.4,1.1,0,1.5l-6.7,6.7c-0.4,0.4-1.1,0.4-1.5,0s-0.4-1.1,0-1.5l6-6l-6-6C12.3,10.6,12.3,10.1,12.7,9.7L12.7,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
    mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M12.7,9.7c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7c0.4,0.4,0.4,1.1,0,1.5l-6.7,6.7c-0.4,0.4-1.1,0.4-1.5,0s-0.4-1.1,0-1.5l6-6l-6-6C12.3,10.6,12.3,10.1,12.7,9.7L12.7,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu .dropstart .dropdown-toggle::after {
    display: none;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu .dropstart .dropdown-toggle::before {
    display: inline-block;
    width: 1.275em;
    height: 1.275em;
    margin-left: -0.25rem;
    content: "";
    background-color: currentcolor;
    border: 0 !important;
    -webkit-mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M19.3,9.7c0.4,0.4,0.4,1.1,0,1.5L13.5,17l6,6c0.4,0.4,0.4,1.1,0,1.5s-1.1,0.4-1.5,0l-6.7-6.7c-0.4-0.4-0.4-1.1,0-1.5L18,9.7C18.4,9.3,18.9,9.3,19.3,9.7L19.3,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
    mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M19.3,9.7c0.4,0.4,0.4,1.1,0,1.5L13.5,17l6,6c0.4,0.4,0.4,1.1,0,1.5s-1.1,0.4-1.5,0l-6.7-6.7c-0.4-0.4-0.4-1.1,0-1.5L18,9.7C18.4,9.3,18.9,9.3,19.3,9.7L19.3,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu .dropend .dropdown-menu,
  .navbar-expand-lg .navbar-nav .dropdown-menu .dropstart .dropdown-menu {
    margin: calc(var(--cz-dropdown-padding-y) * -1 + var(--cz-dropdown-border-width) * -1) var(--cz-dropdown-spacer) 0 var(--cz-dropdown-spacer) !important;
  }
  .navbar-expand-lg.navbar-dark:not([data-bs-theme=dark]) .offcanvas .navbar-nav {
    --cz-nav-link-color: var(--cz-navbar-color);
    --cz-nav-link-hover-color: var(--cz-navbar-hover-color);
    --cz-navbar-active-color: #fff;
    --cz-nav-link-disabled-color: var(--cz-navbar-disabled-color);
  }
  .navbar-expand-lg:not(.navbar-stuck) .dropdown-menu-static {
    display: block;
    animation: none !important;
  }
  .navbar-expand-lg.navbar-stuck .navbar-stuck-show {
    display: block !important;
  }
  .navbar-expand-lg.navbar-stuck .navbar-stuck-hide:not(.collapse):not(.collapsing) {
    display: none !important;
  }
  .navbar-expand-lg.navbar-stuck .navbar-stuck-hide.collapse:not(.show) {
    display: none;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav > .nav-item > .nav-link::before {
    top: auto;
    bottom: calc(var(--cz-nav-link-padding-y) * 0.875);
    left: var(--cz-navbar-nav-link-padding-x);
    width: calc(100% - var(--cz-navbar-nav-link-padding-x) * 2);
    height: var(--cz-nav-link-underline-border-width);
    transform: scaleX(0);
    transform-origin: bottom right;
  }
  .navbar-expand-xl .navbar-nav > .nav-item > .nav-link.dropdown-toggle::before {
    width: calc(100% - 1.275em - 0.15em - var(--cz-navbar-nav-link-padding-x) * 2);
  }
  .navbar-expand-xl .navbar-nav > .nav-item > .nav-link:hover::before, .navbar-expand-xl .navbar-nav > .nav-item > .nav-link.show::before, .navbar-expand-xl .navbar-nav > .nav-item > .nav-link:focus-visible::before, .navbar-expand-xl .navbar-nav > .nav-item > .nav-link.active::before {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  .navbar-expand-xl .navbar-nav > .nav-item > .nav-link.dropdown-toggle::after {
    margin-left: 0.15em;
  }
  .navbar-expand-xl .navbar-nav:hover > .nav-item > .nav-link.active:not(:hover):not(.show):not(:focus-visible)::before {
    transform: scaleX(0);
    transform-origin: bottom right;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    --cz-dropdown-box-shadow: var(--cz-box-shadow);
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu.show {
    animation: fade-up 0.25s ease-in-out;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu .dropend .dropdown-toggle::after {
    -webkit-mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M12.7,9.7c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7c0.4,0.4,0.4,1.1,0,1.5l-6.7,6.7c-0.4,0.4-1.1,0.4-1.5,0s-0.4-1.1,0-1.5l6-6l-6-6C12.3,10.6,12.3,10.1,12.7,9.7L12.7,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
    mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M12.7,9.7c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7c0.4,0.4,0.4,1.1,0,1.5l-6.7,6.7c-0.4,0.4-1.1,0.4-1.5,0s-0.4-1.1,0-1.5l6-6l-6-6C12.3,10.6,12.3,10.1,12.7,9.7L12.7,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu .dropstart .dropdown-toggle::after {
    display: none;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu .dropstart .dropdown-toggle::before {
    display: inline-block;
    width: 1.275em;
    height: 1.275em;
    margin-left: -0.25rem;
    content: "";
    background-color: currentcolor;
    border: 0 !important;
    -webkit-mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M19.3,9.7c0.4,0.4,0.4,1.1,0,1.5L13.5,17l6,6c0.4,0.4,0.4,1.1,0,1.5s-1.1,0.4-1.5,0l-6.7-6.7c-0.4-0.4-0.4-1.1,0-1.5L18,9.7C18.4,9.3,18.9,9.3,19.3,9.7L19.3,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
    mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M19.3,9.7c0.4,0.4,0.4,1.1,0,1.5L13.5,17l6,6c0.4,0.4,0.4,1.1,0,1.5s-1.1,0.4-1.5,0l-6.7-6.7c-0.4-0.4-0.4-1.1,0-1.5L18,9.7C18.4,9.3,18.9,9.3,19.3,9.7L19.3,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu .dropend .dropdown-menu,
  .navbar-expand-xl .navbar-nav .dropdown-menu .dropstart .dropdown-menu {
    margin: calc(var(--cz-dropdown-padding-y) * -1 + var(--cz-dropdown-border-width) * -1) var(--cz-dropdown-spacer) 0 var(--cz-dropdown-spacer) !important;
  }
  .navbar-expand-xl.navbar-dark:not([data-bs-theme=dark]) .offcanvas .navbar-nav {
    --cz-nav-link-color: var(--cz-navbar-color);
    --cz-nav-link-hover-color: var(--cz-navbar-hover-color);
    --cz-navbar-active-color: #fff;
    --cz-nav-link-disabled-color: var(--cz-navbar-disabled-color);
  }
  .navbar-expand-xl:not(.navbar-stuck) .dropdown-menu-static {
    display: block;
    animation: none !important;
  }
  .navbar-expand-xl.navbar-stuck .navbar-stuck-show {
    display: block !important;
  }
  .navbar-expand-xl.navbar-stuck .navbar-stuck-hide:not(.collapse):not(.collapsing) {
    display: none !important;
  }
  .navbar-expand-xl.navbar-stuck .navbar-stuck-hide.collapse:not(.show) {
    display: none;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link::before {
    top: auto;
    bottom: calc(var(--cz-nav-link-padding-y) * 0.875);
    left: var(--cz-navbar-nav-link-padding-x);
    width: calc(100% - var(--cz-navbar-nav-link-padding-x) * 2);
    height: var(--cz-nav-link-underline-border-width);
    transform: scaleX(0);
    transform-origin: bottom right;
  }
  .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link.dropdown-toggle::before {
    width: calc(100% - 1.275em - 0.15em - var(--cz-navbar-nav-link-padding-x) * 2);
  }
  .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link:hover::before, .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link.show::before, .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link:focus-visible::before, .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link.active::before {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link.dropdown-toggle::after {
    margin-left: 0.15em;
  }
  .navbar-expand-xxl .navbar-nav:hover > .nav-item > .nav-link.active:not(:hover):not(.show):not(:focus-visible)::before {
    transform: scaleX(0);
    transform-origin: bottom right;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    --cz-dropdown-box-shadow: var(--cz-box-shadow);
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu.show {
    animation: fade-up 0.25s ease-in-out;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu .dropend .dropdown-toggle::after {
    -webkit-mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M12.7,9.7c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7c0.4,0.4,0.4,1.1,0,1.5l-6.7,6.7c-0.4,0.4-1.1,0.4-1.5,0s-0.4-1.1,0-1.5l6-6l-6-6C12.3,10.6,12.3,10.1,12.7,9.7L12.7,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
    mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M12.7,9.7c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7c0.4,0.4,0.4,1.1,0,1.5l-6.7,6.7c-0.4,0.4-1.1,0.4-1.5,0s-0.4-1.1,0-1.5l6-6l-6-6C12.3,10.6,12.3,10.1,12.7,9.7L12.7,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu .dropstart .dropdown-toggle::after {
    display: none;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu .dropstart .dropdown-toggle::before {
    display: inline-block;
    width: 1.275em;
    height: 1.275em;
    margin-left: -0.25rem;
    content: "";
    background-color: currentcolor;
    border: 0 !important;
    -webkit-mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M19.3,9.7c0.4,0.4,0.4,1.1,0,1.5L13.5,17l6,6c0.4,0.4,0.4,1.1,0,1.5s-1.1,0.4-1.5,0l-6.7-6.7c-0.4-0.4-0.4-1.1,0-1.5L18,9.7C18.4,9.3,18.9,9.3,19.3,9.7L19.3,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
    mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M19.3,9.7c0.4,0.4,0.4,1.1,0,1.5L13.5,17l6,6c0.4,0.4,0.4,1.1,0,1.5s-1.1,0.4-1.5,0l-6.7-6.7c-0.4-0.4-0.4-1.1,0-1.5L18,9.7C18.4,9.3,18.9,9.3,19.3,9.7L19.3,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu .dropend .dropdown-menu,
  .navbar-expand-xxl .navbar-nav .dropdown-menu .dropstart .dropdown-menu {
    margin: calc(var(--cz-dropdown-padding-y) * -1 + var(--cz-dropdown-border-width) * -1) var(--cz-dropdown-spacer) 0 var(--cz-dropdown-spacer) !important;
  }
  .navbar-expand-xxl.navbar-dark:not([data-bs-theme=dark]) .offcanvas .navbar-nav {
    --cz-nav-link-color: var(--cz-navbar-color);
    --cz-nav-link-hover-color: var(--cz-navbar-hover-color);
    --cz-navbar-active-color: #fff;
    --cz-nav-link-disabled-color: var(--cz-navbar-disabled-color);
  }
  .navbar-expand-xxl:not(.navbar-stuck) .dropdown-menu-static {
    display: block;
    animation: none !important;
  }
  .navbar-expand-xxl.navbar-stuck .navbar-stuck-show {
    display: block !important;
  }
  .navbar-expand-xxl.navbar-stuck .navbar-stuck-hide:not(.collapse):not(.collapsing) {
    display: none !important;
  }
  .navbar-expand-xxl.navbar-stuck .navbar-stuck-hide.collapse:not(.show) {
    display: none;
  }
}
.navbar-expand .navbar-nav > .nav-item > .nav-link::before {
  top: auto;
  bottom: calc(var(--cz-nav-link-padding-y) * 0.875);
  left: var(--cz-navbar-nav-link-padding-x);
  width: calc(100% - var(--cz-navbar-nav-link-padding-x) * 2);
  height: var(--cz-nav-link-underline-border-width);
  transform: scaleX(0);
  transform-origin: bottom right;
}
.navbar-expand .navbar-nav > .nav-item > .nav-link.dropdown-toggle::before {
  width: calc(100% - 1.275em - 0.15em - var(--cz-navbar-nav-link-padding-x) * 2);
}
.navbar-expand .navbar-nav > .nav-item > .nav-link:hover::before, .navbar-expand .navbar-nav > .nav-item > .nav-link.show::before, .navbar-expand .navbar-nav > .nav-item > .nav-link:focus-visible::before, .navbar-expand .navbar-nav > .nav-item > .nav-link.active::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.navbar-expand .navbar-nav > .nav-item > .nav-link.dropdown-toggle::after {
  margin-left: 0.15em;
}
.navbar-expand .navbar-nav:hover > .nav-item > .nav-link.active:not(:hover):not(.show):not(:focus-visible)::before {
  transform: scaleX(0);
  transform-origin: bottom right;
}
.navbar-expand .navbar-nav .dropdown-menu {
  --cz-dropdown-box-shadow: var(--cz-box-shadow);
}
.navbar-expand .navbar-nav .dropdown-menu.show {
  animation: fade-up 0.25s ease-in-out;
}
.navbar-expand .navbar-nav .dropdown-menu .dropend .dropdown-toggle::after {
  -webkit-mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M12.7,9.7c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7c0.4,0.4,0.4,1.1,0,1.5l-6.7,6.7c-0.4,0.4-1.1,0.4-1.5,0s-0.4-1.1,0-1.5l6-6l-6-6C12.3,10.6,12.3,10.1,12.7,9.7L12.7,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M12.7,9.7c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7c0.4,0.4,0.4,1.1,0,1.5l-6.7,6.7c-0.4,0.4-1.1,0.4-1.5,0s-0.4-1.1,0-1.5l6-6l-6-6C12.3,10.6,12.3,10.1,12.7,9.7L12.7,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
}
.navbar-expand .navbar-nav .dropdown-menu .dropstart .dropdown-toggle::after {
  display: none;
}
.navbar-expand .navbar-nav .dropdown-menu .dropstart .dropdown-toggle::before {
  display: inline-block;
  width: 1.275em;
  height: 1.275em;
  margin-left: -0.25rem;
  content: "";
  background-color: currentcolor;
  border: 0 !important;
  -webkit-mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M19.3,9.7c0.4,0.4,0.4,1.1,0,1.5L13.5,17l6,6c0.4,0.4,0.4,1.1,0,1.5s-1.1,0.4-1.5,0l-6.7-6.7c-0.4-0.4-0.4-1.1,0-1.5L18,9.7C18.4,9.3,18.9,9.3,19.3,9.7L19.3,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M19.3,9.7c0.4,0.4,0.4,1.1,0,1.5L13.5,17l6,6c0.4,0.4,0.4,1.1,0,1.5s-1.1,0.4-1.5,0l-6.7-6.7c-0.4-0.4-0.4-1.1,0-1.5L18,9.7C18.4,9.3,18.9,9.3,19.3,9.7L19.3,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
}
.navbar-expand .navbar-nav .dropdown-menu .dropend .dropdown-menu,
.navbar-expand .navbar-nav .dropdown-menu .dropstart .dropdown-menu {
  margin: calc(var(--cz-dropdown-padding-y) * -1 + var(--cz-dropdown-border-width) * -1) var(--cz-dropdown-spacer) 0 var(--cz-dropdown-spacer) !important;
}
.navbar-expand.navbar-dark:not([data-bs-theme=dark]) .offcanvas .navbar-nav {
  --cz-nav-link-color: var(--cz-navbar-color);
  --cz-nav-link-hover-color: var(--cz-navbar-hover-color);
  --cz-navbar-active-color: #fff;
  --cz-nav-link-disabled-color: var(--cz-navbar-disabled-color);
}
.navbar-expand:not(.navbar-stuck) .dropdown-menu-static {
  display: block;
  animation: none !important;
}
.navbar-expand.navbar-stuck .navbar-stuck-show {
  display: block !important;
}
.navbar-expand.navbar-stuck .navbar-stuck-hide:not(.collapse):not(.collapsing) {
  display: none !important;
}
.navbar-expand.navbar-stuck .navbar-stuck-hide.collapse:not(.show) {
  display: none;
}

.navbar-stuck {
  animation: navbar-show 0.25s;
}

.navbar-sticky.is-stuck.navbar,
.navbar-sticky.is-stuck .navbar,
.navbar-sticky.navbar-stuck.navbar,
.navbar-sticky.navbar-stuck .navbar {
  box-shadow: 0 0.5rem 2rem -0.25rem rgba(103, 111, 123, 0.1);
}
.navbar-sticky.is-stuck.rounded-pill:not(.form-control):not(.badge):not(.btn),
.navbar-sticky.is-stuck .rounded-pill:not(.form-control):not(.badge):not(.btn),
.navbar-sticky.navbar-stuck.rounded-pill:not(.form-control):not(.badge):not(.btn),
.navbar-sticky.navbar-stuck .rounded-pill:not(.form-control):not(.badge):not(.btn) {
  border-radius: var(--cz-border-radius-lg) !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

@keyframes navbar-show {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
.sticky-product-banner .sticky-product-banner-inner {
  position: absolute;
  width: 100%;
  transition: transform 0.3s ease-in-out;
  transform: translateY(0);
}
.sticky-product-banner:not(.is-stuck) .sticky-product-banner-inner {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  transform: translateY(-100%);
}

[data-bs-theme=dark] .navbar:not([data-bs-theme=light]) {
  --cz-navbar-color: rgba(255, 255, 255, 0.8);
  --cz-navbar-hover-color: #fff;
  --cz-navbar-disabled-color: rgba(255, 255, 255, 0.4);
  --cz-navbar-active-color: #fff;
  --cz-navbar-brand-color: #fff;
  --cz-navbar-brand-hover-color: #fff;
}
[data-bs-theme=dark] .navbar-sticky.is-stuck.navbar:not([data-bs-theme=light]),
[data-bs-theme=dark] .navbar-sticky.is-stuck .navbar:not([data-bs-theme=light]),
[data-bs-theme=dark] .navbar-sticky.navbar-stuck.navbar:not([data-bs-theme=light]),
[data-bs-theme=dark] .navbar-sticky.navbar-stuck .navbar:not([data-bs-theme=light]) {
  box-shadow: 0 0.5rem 1.875rem -0.25rem rgba(8, 11, 18, 0.25);
}

.card-header-nav-underline {
  --cz-nav-link-padding-y: calc(1.25 * var(--cz-card-cap-padding-y));
  margin: calc(-1 * var(--cz-card-cap-padding-y)) calc(-0.125 * var(--cz-card-cap-padding-x));
}

.card-header-tabs {
  --cz-nav-tabs-padding: var(--cz-card-cap-padding-y);
  margin: calc(-1 * var(--cz-card-cap-padding-y)) calc(-1 * var(--cz-card-cap-padding-x));
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.product-card {
  position: relative;
}
.product-card .product-card-details {
  display: none;
}
.product-card.hover-effect-opacity .hover-effect-target {
  transition: none;
}
@media (min-width: 992px) {
  .product-card:hover, .product-card:has(.select-card-check:checked) {
    z-index: 2;
    box-shadow: var(--cz-box-shadow);
  }
}
.product-card:hover .product-card-button:hover,
.product-card .count-input:not(.collapsed) .product-card-button:hover {
  color: #fff;
}
@media (min-width: 992px) {
  .product-card:hover .product-card-details {
    display: block;
  }
}
.product-card:hover .hover-effect-underline {
  text-decoration: underline !important;
  text-decoration-thickness: var(--cz-underline-thickness) !important;
}
.product-card:hover .count-input .form-control,
.product-card:hover [data-decrement] {
  transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
.product-card:hover .count-input:not(.collapsed) [data-count-input-value] {
  display: none;
}
.product-card:hover .count-input:not(.collapsed) [data-increment] > i {
  display: inline-flex;
}
.product-card:hover .count-input.collapsed [data-increment] {
  border-radius: var(--cz-border-radius-sm) !important;
}
.product-card:not(:hover) .count-input .form-control,
.product-card:not(:hover) [data-decrement] {
  visibility: hidden;
  opacity: 0;
}
.product-card:not(:hover) .count-input:not(.collapsed) [data-count-input-value] {
  display: inline;
}
.product-card:not(:hover) .count-input:not(.collapsed) [data-increment] > i {
  display: none;
}
.product-card:not(:hover) [data-increment] {
  border-radius: var(--cz-border-radius-sm) !important;
}

.category-card-body,
.category-card-title {
  transition: transform 0.35s ease-in-out;
  transform-origin: top center;
  will-change: transform;
}

.category-card-list {
  position: absolute;
  opacity: 0;
  transition: transform 0.35s ease-in-out, opacity 0.35s ease-in-out;
  will-change: transform, opacity;
}

.category-card {
  position: relative;
  overflow: hidden;
}
.category-card:hover .category-card-body {
  transform: scale(0.67);
}
.category-card:hover .category-card-title,
.category-card:hover .category-card-list {
  transform: scale(1.45);
}
.category-card:hover .category-card-list {
  opacity: 1;
}

.accordion {
  --cz-accordion-btn-font-size: 1rem;
  --cz-accordion-btn-font-weight: 600;
  --cz-accordion-body-font-size: 0.875rem;
}

.accordion-button {
  font-size: var(--cz-accordion-btn-font-size);
  font-weight: var(--cz-accordion-btn-font-weight);
}
.accordion-button:focus-visible {
  box-shadow: 0 0 0 0.25rem var(--cz-focus-ring-color);
}
.accordion-button:not(.collapsed) {
  box-shadow: none;
}

.accordion-item {
  border: 0;
  border-bottom: var(--cz-accordion-border-width) solid var(--cz-accordion-border-color);
}

.accordion-body {
  padding-bottom: var(--cz-accordion-btn-padding-y);
  font-size: var(--cz-accordion-body-font-size);
}

.accordion-alt-icon {
  --cz-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23181d25'%3e%3cpath d='M12 4.125c.483 0 .875.392.875.875v14c0 .483-.392.875-.875.875s-.875-.392-.875-.875V5c0-.483.392-.875.875-.875z'/%3e%3cpath d='M4.125 12c0-.483.392-.875.875-.875h14c.483 0 .875.392.875.875s-.392.875-.875.875H5c-.483 0-.875-.392-.875-.875z'/%3e%3c/svg%3e");
  --cz-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23181d25'%3e%3cpath d='M4.125 12c0-.483.392-.875.875-.875h14c.483 0 .875.392.875.875s-.392.875-.875.875H5c-.483 0-.875-.392-.875-.875z'/%3e%3c/svg%3e");
}

[data-label-collapsed]::before {
  content: attr(data-label-expanded);
}
[data-label-collapsed].collapsed::before, .collapsed > [data-label-collapsed]::before {
  content: attr(data-label-collapsed);
}

:not(.collapsed) > .collapse-toggle-icon {
  transform: rotate(180deg);
}

.hiding-collapse-toggle:not(.collapsed) {
  display: none;
}

[data-bs-theme=dark] .accordion:not([data-bs-theme=light]) .accordion-button::after {
  --cz-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23fff'%3e%3cpath d='M3.8,7.9c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7l6.7-6.7c0.4-0.4,1.1-0.4,1.5,0c0.4,0.4,0.4,1.1,0,1.5l-7.4,7.4c-0.4,0.4-1.1,0.4-1.5,0L3.8,9.4C3.3,9.1,3.3,8.4,3.8,7.9z'/%3e%3c/svg%3e");
  --cz-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23fff'%3e%3cpath d='M3.8,7.9c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7l6.7-6.7c0.4-0.4,1.1-0.4,1.5,0c0.4,0.4,0.4,1.1,0,1.5l-7.4,7.4c-0.4,0.4-1.1,0.4-1.5,0L3.8,9.4C3.3,9.1,3.3,8.4,3.8,7.9z'/%3e%3c/svg%3e");
}
[data-bs-theme=dark] .accordion-alt-icon:not([data-bs-theme=light]) .accordion-button::after {
  --cz-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23fff'%3e%3cpath d='M12 4.125c.483 0 .875.392.875.875v14c0 .483-.392.875-.875.875s-.875-.392-.875-.875V5c0-.483.392-.875.875-.875z'/%3e%3cpath d='M4.125 12c0-.483.392-.875.875-.875h14c.483 0 .875.392.875.875s-.392.875-.875.875H5c-.483 0-.875-.392-.875-.875z'/%3e%3c/svg%3e");
  --cz-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23fff'%3e%3cpath d='M4.125 12c0-.483.392-.875.875-.875h14c.483 0 .875.392.875.875s-.392.875-.875.875H5c-.483 0-.875-.392-.875-.875z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --cz-breadcrumb-font-weight: 500;
  --cz-breadcrumb-color: var(--cz-component-color);
  --cz-breadcrumb-hover-color: var(--cz-component-hover-color);
  flex-direction: row;
  gap: 0;
  font-weight: var(--cz-breadcrumb-font-weight);
}

.breadcrumb-item > a {
  color: var(--cz-breadcrumb-color);
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}
.breadcrumb-item > a:hover, .breadcrumb-item > a:focus-visible {
  color: var(--cz-breadcrumb-hover-color);
}
.breadcrumb-item + .breadcrumb-item::before {
  width: 1.125em;
  height: 1.125em;
  padding-right: 0;
  margin-right: var(--cz-breadcrumb-item-padding-x);
  line-height: 2em;
}

[data-bs-theme=dark] .breadcrumb:not([data-bs-theme=light]) .breadcrumb-item + .breadcrumb-item::before {
  content: var(--cz-breadcrumb-divider, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23e0e5eb'%3e%3cpath d='M8.381 5.381c.342-.342.896-.342 1.237 0l6 6c.342.342.342.896 0 1.237l-6 6c-.342.342-.896.342-1.237 0s-.342-.896 0-1.237L13.763 12 8.381 6.619c-.342-.342-.342-.896 0-1.237z'/%3e%3c/svg%3e")) /* rtl: var(--cz-breadcrumb-divider, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23e0e5eb'%3e%3cpath d='M15.3,5.4c0.3,0.3,0.3,0.9,0,1.2L9.9,12l5.4,5.4c0.3,0.3,0.3,0.9,0,1.2s-0.9,0.3-1.2,0l-6-6c-0.3-0.3-0.3-0.9,0-1.2l6-6C14.4,5,14.9,5,15.3,5.4L15.3,5.4z'/%3e%3c/svg%3e")) */;
}

.pagination {
  --cz-pagination-spacer: 0.25rem;
  --cz-pagination-font-weight: 500;
  flex-direction: row;
  gap: var(--cz-pagination-spacer);
  margin-bottom: 0;
}

.page-link {
  font-weight: var(--cz-pagination-font-weight);
}
.page-link:hover, .page-link:focus, .page-link.active, .active > .page-link {
  z-index: 0;
}
.page-link:focus {
  box-shadow: none;
}
.page-link:focus-visible {
  z-index: 4;
  color: var(--cz-pagination-focus-color);
  background-color: var(--cz-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--cz-pagination-focus-box-shadow);
}
.page-link [class*=" ci-"],
.page-link [class^=ci-] {
  margin-top: 0.125rem;
}

.pagination-sm {
  --cz-pagination-font-size: 0.75rem;
}

.pagination-lg {
  --cz-pagination-font-size: 1rem;
}

.alert .btn-close {
  --cz-btn-close-size: .6875em;
  --cz-btn-close-opacity: .75;
  --cz-btn-close-hover-opacity: 1;
  padding: 1.375rem 1rem;
}

.alert-primary .btn-close {
  --cz-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23286090'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  filter: none;
}

.alert-success .btn-close {
  --cz-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232d9f5f'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  filter: none;
}

.alert-info .btn-close {
  --cz-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232762c3'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  filter: none;
}

.alert-warning .btn-close {
  --cz-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fc8518'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  filter: none;
}

.alert-danger .btn-close {
  --cz-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ee2525'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  filter: none;
}

.progress-bar-striped.bg-light {
  background-image: linear-gradient(45deg, rgba(24, 29, 37, 0.15) 25%, transparent 25%, transparent 50%, rgba(24, 29, 37, 0.15) 50%, rgba(24, 29, 37, 0.15) 75%, transparent 75%, transparent);
}

.list-group {
  --cz-list-group-font-size: 0.875rem;
  --cz-list-group-action-font-weight: 500;
  --cz-list-group-borderless-item-spacer: 0.1875rem;
  gap: 0;
  font-size: var(--cz-list-group-font-size);
}

.list-group-item-action {
  font-weight: var(--cz-list-group-action-font-weight);
  color: var(--cz-list-group-action-color);
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.list-group-item-action:hover, .list-group-item-action:focus-visible {
  color: var(--cz-list-group-action-hover-color);
  background-color: var(--cz-list-group-action-hover-bg);
}
.list-group-item-action.active {
  color: var(--cz-list-group-action-active-color);
  background-color: var(--cz-list-group-action-active-bg);
}

.list-group-borderless {
  gap: var(--cz-list-group-borderless-item-spacer);
  border-radius: 0;
}
.list-group-borderless .list-group-item {
  border: 0;
  border-radius: var(--cz-list-group-border-radius) !important;
}
.list-group-borderless .list-group-item.active {
  margin-top: 0;
}

.card > .list-group-flush {
  --cz-list-group-item-padding-x: var(--cz-card-spacer-x);
}

.btn-close {
  --cz-btn-close-size: 0.75em;
  width: var(--cz-btn-close-size);
  height: var(--cz-btn-close-size);
  background-size: var(--cz-btn-close-size) auto;
  transition: opacity 0.2s ease-in-out;
}
.btn-close:focus {
  outline: 0;
  box-shadow: none;
}
.btn-close:focus-visible {
  box-shadow: var(--cz-btn-close-focus-shadow);
  opacity: var(--cz-btn-close-focus-opacity);
}

.toast {
  padding: var(--cz-toast-padding-y) var(--cz-toast-padding-x);
}
.toast .btn-close {
  --cz-btn-close-size: .625em;
  margin-top: 0.125rem;
}

.toast-header,
.toast-body {
  padding: 0;
}
.toast-header .btn-close,
.toast-body .btn-close {
  --cz-btn-close-size: .625em;
  margin: 0.125rem 0 0;
}

.toast-header {
  margin-bottom: var(--cz-toast-padding-y);
  border: 0;
}

.tooltip-sm {
  --cz-tooltip-padding-x: 0.46875rem;
  --cz-tooltip-padding-y: 0.28125rem;
  --cz-tooltip-font-size: 0.75rem;
  --cz-tooltip-border-radius: var(--cz-border-radius-xs);
}

.popover {
  --cz-popover-arrow-border: var(--cz-border-color);
}

.popover-header {
  padding-bottom: 0;
  margin-bottom: -0.5rem;
  border-bottom: 0;
}

.popover-sm {
  --cz-popover-max-width: 207px;
  --cz-popover-body-padding-x: 0.75rem;
  --cz-popover-body-padding-y: 0.75rem;
  --cz-popover-font-size: 0.75rem;
}

[data-bs-theme=dark] .popover:not([data-bs-theme=light]) {
  --cz-popover-box-shadow: 0 0.5rem 1.875rem -0.25rem rgba(8, 11, 18, 0.25);
}

.swiper-button-lock {
  display: none !important;
}

.swiper-pagination-bullets {
  --swiper-pagination-bottom: 1.5rem;
  --swiper-pagination-right: 1.5rem;
  --swiper-pagination-bullet-inactive-color: #cad0d9;
  --swiper-pagination-color: #222934;
  --swiper-pagination-bullet-border-radius: 50%;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 0.375rem;
  --swiper-pagination-bullet-vertical-gap: 0.375rem;
  --swiper-pagination-bullet-transition: background-color 0.2s ease-in-out;
}

.swiper-pagination-bullet {
  transition: var(--swiper-pagination-bullet-transition);
}

.swiper-pagination-progressbar {
  --swiper-pagination-progressbar-size: 0.25rem;
  --swiper-pagination-progressbar-border-radius: 50rem;
  --swiper-pagination-progressbar-bg-color: #eef1f6;
  --swiper-pagination-color: #222934;
  overflow: hidden;
  border-radius: var(--swiper-pagination-progressbar-border-radius);
}

.swiper-scrollbar {
  --swiper-scrollbar-bottom: .25rem;
  --swiper-scrollbar-right: .25rem;
  --swiper-scrollbar-size: 0.25rem;
  --swiper-scrollbar-border-radius: 50rem;
  --swiper-scrollbar-bg-color: #eef1f6;
  --swiper-scrollbar-drag-bg-color: #222934;
  display: none;
}
.swiper-scrollbar.swiper-scrollbar-horizontal, .swiper-scrollbar.swiper-scrollbar-vertical {
  display: block;
}

.swiper-thumbs {
  --swiper-thumbnail-border-width: var(--cz-border-width);
  --swiper-thumbnail-border-color: var(--cz-border-color);
  --swiper-thumbnail-active-border-color: var(--cz-component-active-color);
  --swiper-thumbnail-border-radius: var(--cz-border-radius);
  --swiper-thumbnail-opacity: .5;
  --swiper-thumbnail-active-opacity: 1;
}

.swiper-thumb-img {
  opacity: var(--swiper-thumbnail-opacity);
  transition: opacity 0.2s ease-in-out;
  border-radius: var(--swiper-thumbnail-border-radius);
}

.swiper-thumb {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  cursor: pointer;
  border: var(--swiper-thumbnail-border-width) solid var(--swiper-thumbnail-border-color);
  border-radius: var(--swiper-thumbnail-border-radius);
  transition: border-color 0.2s ease-in-out;
}
.swiper-thumb:hover .swiper-thumb-img, .swiper-thumb.active .swiper-thumb-img, .swiper-thumb.swiper-slide-thumb-active .swiper-thumb-img {
  opacity: var(--swiper-thumbnail-active-opacity);
}
.swiper-thumb.active, .swiper-thumb.swiper-slide-thumb-active {
  border-color: var(--swiper-thumbnail-active-border-color);
}

.swiper-load {
  visibility: hidden;
}
.swiper-load.swiper-initialized {
  visibility: visible;
}

[data-bs-theme=dark] .swiper-pagination-bullets:not([data-bs-theme=light]) {
  --swiper-pagination-bullet-inactive-color: rgba(255, 255, 255, 0.3);
  --swiper-pagination-color: #fff;
}
[data-bs-theme=dark] .swiper-pagination-progressbar:not([data-bs-theme=light]) {
  --swiper-pagination-progressbar-bg-color: rgba(255, 255, 255, 0.3);
  --swiper-pagination-color: #fff;
}
[data-bs-theme=dark] .swiper-scrollbar:not([data-bs-theme=light]) {
  --swiper-scrollbar-bg-color: rgba(255, 255, 255, 0.3);
  --swiper-scrollbar-drag-bg-color: #fff;
}

.glightbox-open {
  overflow: clip;
}

@-moz-document url-prefix() {
  .glightbox-open {
    overflow: hidden;
  }
}
.glightbox-clean .gslide-media {
  box-shadow: none;
}

.glightbox-mobile .goverlay,
.goverlay {
  background: var(--cz-body-bg);
}

.glightbox-clean .gprev,
.glightbox-clean .gnext,
.glightbox-clean .gclose {
  width: 2.5rem;
  height: 2.5rem;
}
.glightbox-clean .gprev svg,
.glightbox-clean .gnext svg,
.glightbox-clean .gclose svg {
  width: 1.25rem;
}
@media (min-width: 768px) {
  .glightbox-clean .gprev,
  .glightbox-clean .gnext,
  .glightbox-clean .gclose {
    width: 3rem;
    height: 3rem;
  }
  .glightbox-clean .gprev svg,
  .glightbox-clean .gnext svg,
  .glightbox-clean .gclose svg {
    width: 1.5rem;
  }
}
.glightbox-clean .gprev path,
.glightbox-clean .gnext path,
.glightbox-clean .gclose path {
  fill: currentcolor;
}

.glightbox-clean .gclose {
  opacity: 1;
}

.glightbox-button-hidden {
  display: none;
}

.gbtn.focused {
  outline: none;
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.1);
}

.choices {
  margin-bottom: 0;
}

.choices[data-type*=select-one]::after {
  display: none;
}
.choices[data-type*=select-one] .form-select {
  padding-right: 3.25rem;
}
.choices[data-type*=select-one] .choices__button {
  right: 0;
  left: auto;
  margin-right: 2rem;
  margin-left: 0;
  background-size: 9px;
  box-shadow: none !important;
  opacity: 0.4;
  transition: opacity 0.15s ease-in-out;
}
.choices[data-type*=select-one] .choices__button:hover {
  opacity: 0.8;
}

.filter-select:has(.choices__item:not(.choices__placeholder)) {
  --cz-form-control-border-color: #181d25;
}

.choices[data-type*=select-multiple] .form-select,
.choices[data-type*=text] .form-select {
  padding: 0.53rem 0.53rem 0.28rem 0.53rem;
  background-image: none;
}
.choices[data-type*=select-multiple] .form-select.form-select-lg,
.choices[data-type*=text] .form-select.form-select-lg {
  padding: 0.685rem 0.685rem 0.435rem 0.685rem;
}
.choices[data-type*=select-multiple] .form-select.form-select-sm,
.choices[data-type*=text] .form-select.form-select-sm {
  padding: 0.375rem 0.375rem 0.125rem 0.375rem;
}

.choices__placeholder {
  color: var(--cz-tertiary-color);
  opacity: 1;
}

.is-focused .form-select {
  border-color: var(--cz-form-control-focus-border-color);
}

.is-disabled .form-select {
  color: var(--cz-tertiary-color);
  background-color: var(--cz-tertiary-bg);
  border-color: var(--cz-border-color);
  border-style: dashed;
}

.choices__list--dropdown,
.choices__list[aria-expanded] {
  z-index: 10;
  padding: 0.75rem 0.75rem;
  margin: 0.3125rem 0 !important;
  font-size: 0.875rem;
  background-color: var(--cz-body-bg);
  border: var(--cz-border-width) solid var(--cz-light-border-subtle) !important;
  border-radius: var(--cz-border-radius) !important;
  box-shadow: var(--cz-box-shadow) !important;
}
.choices__list--dropdown .choices__placeholder,
.choices__list[aria-expanded] .choices__placeholder {
  display: none !important;
}
.choices__list--dropdown .choices__list,
.choices__list[aria-expanded] .choices__list {
  max-height: 260px;
}

.choices__list--single {
  display: flex;
  padding: 0;
}
.choices__list--single .choices__item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.choices__list--dropdown .choices__item,
.choices__list[aria-expanded] .choices__item {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: var(--cz-component-color);
  border-radius: calc(var(--cz-border-radius) * 0.75) !important;
}
.choices__list--dropdown .choices__item.is-highlighted,
.choices__list[aria-expanded] .choices__item.is-highlighted {
  color: var(--cz-component-active-color);
  background-color: var(--cz-component-active-bg);
}

.choices .choices__input {
  padding-top: 0.25rem;
  padding-right: 0;
  padding-bottom: 0.25rem;
  padding-left: 0.375rem;
  margin: 0;
  margin-bottom: 0.25rem;
  color: var(--cz-body-color);
  background-color: transparent !important;
}
.choices .choices__input::placeholder {
  color: var(--cz-tertiary-color);
  opacity: 1;
}

.choices:not([data-type*=select-multiple]):not([data-type*=text]) .choices__input {
  padding-left: 1.75rem !important;
  margin-top: -0.375rem !important;
  margin-bottom: 0.3125rem !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 32 32' fill='%239ca3af'%3e%3cpath d='m21.6 20.4-3.8-3.8c1.2-1.5 2-3.5 2-5.6 0-4.9-4-8.9-8.9-8.9S2 6.1 2 11s4 8.9 8.9 8.9c2.1 0 4.1-.8 5.6-2l3.8 3.8c.3.3.9.3 1.2 0 .5-.4.5-1 .1-1.3M16.1 16l-.1.1c-1.3 1.2-3 2-5 2-3.9 0-7.1-3.2-7.1-7.1S7.1 3.9 11 3.9s7.1 3.2 7.1 7.1c0 1.9-.8 3.7-2 5'/%3e%3c/svg%3e") no-repeat 0.125rem 0.75rem;
  border-color: var(--cz-border-color) !important;
}

.choices__list--dropdown .choices__item--selectable::after,
.choices__list[aria-expanded] .choices__item--selectable::after {
  flex-shrink: 0;
  width: 1.3125rem;
  height: 1.3125rem;
  margin-top: 0.1875rem;
  margin-right: -0.25rem;
  margin-left: auto;
  content: "";
  background-color: currentcolor;
  opacity: 0;
  -webkit-mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M20.619 5.381a.875.875 0 0 1 0 1.238l-11 11a.875.875 0 0 1-1.238 0l-5-5A.875.875 0 1 1 4.62 11.38L9 15.763 19.381 5.38a.875.875 0 0 1 1.238 0Z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M20.619 5.381a.875.875 0 0 1 0 1.238l-11 11a.875.875 0 0 1-1.238 0l-5-5A.875.875 0 1 1 4.62 11.38L9 15.763 19.381 5.38a.875.875 0 0 1 1.238 0Z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
}
.choices__list--dropdown .choices__item--selectable.is-highlighted::after,
.choices__list[aria-expanded] .choices__item--selectable.is-highlighted::after {
  opacity: 0.85;
}

.choices__heading {
  padding: 1rem 0.75rem 0.75rem;
  margin-bottom: 0.3125rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--cz-heading-color);
  border-color: var(--cz-border-color);
}

.choices.is-disabled .choices__list--multiple .choices__item,
.choices__list--multiple .choices__item {
  background-color: var(--cz-emphasis-color);
  border-color: var(--cz-emphasis-color);
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
}
.choices.is-disabled .choices__list--multiple .choices__item .choices__button,
.choices__list--multiple .choices__item .choices__button {
  padding-right: 0.375rem;
  padding-left: 1rem;
  margin-right: -0.25rem;
  margin-left: 0.5rem;
  border-right: 0;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}
.choices.is-disabled .choices__list--multiple .choices__item.is-highlighted,
.choices__list--multiple .choices__item.is-highlighted {
  background-color: var(--cz-secondary-color);
  border-color: var(--cz-secondary-color);
}

.choices.is-disabled .choices__list--multiple .choices__item {
  opacity: 0.45;
}

.was-validated .choices:has(.form-select:invalid) .form-select,
.choices:has(.is-invalid) .form-select {
  border-color: var(--cz-form-invalid-border-color);
}
.was-validated .choices:has(.form-select:invalid) ~ .invalid-feedback,
.was-validated .choices:has(.form-select:invalid) ~ .invalid-tooltip,
.choices:has(.is-invalid) ~ .invalid-feedback,
.choices:has(.is-invalid) ~ .invalid-tooltip {
  display: block;
}

.was-validated .choices:has(.form-select:valid) ~ .valid-feedback,
.was-validated .choices:has(.form-select:valid) ~ .valid-tooltip,
.choices:has(.is-valid) ~ .valid-feedback,
.choices:has(.is-valid) ~ .valid-tooltip {
  display: block;
}

[data-bs-theme=dark] .choices[data-type*=select-one] .choices__button {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjZmZmIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
}
[data-bs-theme=dark] .choices.is-disabled .choices__list--multiple .choices__item,
[data-bs-theme=dark] .choices__list--multiple .choices__item {
  color: #222934;
}
[data-bs-theme=dark] .choices.is-disabled .choices__list--multiple .choices__item .choices__button,
[data-bs-theme=dark] .choices__list--multiple .choices__item .choices__button {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
  border-color: #cad0d9;
}
[data-bs-theme=dark] .filter-select:has(.choices__item:not(.choices__placeholder)) {
  --cz-form-control-border-color: #fff;
}
[data-bs-theme=dark] .choices:has([data-bs-theme=light]) .form-select {
  --cz-form-control-bg: #fff;
  --cz-form-control-border-color: #cad0d9;
  --cz-form-control-focus-bg: #fff;
  --cz-form-control-focus-border-color: #181d25;
  --cz-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%234e5562' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}
[data-bs-theme=dark] .choices:has([data-bs-theme=light]) .choices__button {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
}
[data-bs-theme=dark] .choices:has([data-bs-theme=light]) .choices.is-disabled .choices__list--multiple .choices__item,
[data-bs-theme=dark] .choices:has([data-bs-theme=light]) .choices__list--multiple .choices__item {
  color: #fff;
}
[data-bs-theme=dark] .choices:has([data-bs-theme=light]) .choices.is-disabled .choices__list--multiple .choices__item .choices__button,
[data-bs-theme=dark] .choices:has([data-bs-theme=light]) .choices__list--multiple .choices__item .choices__button {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjZmZmIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
  border-color: rgba(255, 255, 255, 0.3);
}
[data-bs-theme=dark] .choices:has([data-bs-theme=light]) .filter-select:has(.choices__item:not(.choices__placeholder)) {
  --cz-form-control-border-color: #181d25;
}

[data-simplebar] {
  --cz-scrollbar-width: 0.1875rem;
  --cz-scrollbar-bg: #cad0d9;
  --cz-scrollbar-border-radius: var(--cz-border-radius);
  --cz-scrollbar-track-width: 0.1875rem;
  --cz-scrollbar-track-bg: #eef1f6;
}

.simplebar-scrollbar::before {
  right: 0;
  left: 0;
  background-color: var(--cz-scrollbar-bg);
  border-radius: var(--cz-scrollbar-border-radius);
  opacity: 1 !important;
  transition: none;
}

.simplebar-track {
  overflow: initial;
  background-color: var(--cz-scrollbar-track-bg);
  border-radius: var(--cz-scrollbar-border-radius);
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}
.simplebar-track.simplebar-vertical {
  right: calc(var(--cz-scrollbar-width) * 0.5);
  width: var(--cz-scrollbar-track-width);
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar {
  left: 50%;
  width: var(--cz-scrollbar-width);
  margin-left: calc(var(--cz-scrollbar-width) * -0.5);
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar::before {
  top: 0;
  bottom: 0;
}
.simplebar-track.simplebar-horizontal {
  bottom: calc(var(--cz-scrollbar-width) * 0.5);
  height: var(--cz-scrollbar-track-width);
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  top: 50%;
  bottom: 0;
  height: var(--cz-scrollbar-width);
  margin-top: calc(var(--cz-scrollbar-width) * -0.5);
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.simplebar-wrapper:hover ~ .simplebar-track,
[data-simplebar-auto-hide=false] .simplebar-track {
  opacity: 1;
}

[data-simplebar]:not(.simplebar-dragging) .simplebar-content-wrapper {
  scroll-behavior: smooth;
}

[dir=rtl] .simplebar-track.simplebar-vertical {
  /* rtl:begin:ignore */
  right: auto;
  left: 0;
  /* rtl:end:ignore */
}

.accordion-item .simplebar-track.simplebar-vertical {
  right: calc(var(--cz-scrollbar-width) * 2.25);
}

[data-bs-theme=dark] [data-simplebar]:not([data-bs-theme=light]) {
  --cz-scrollbar-bg: #4e5562;
  --cz-scrollbar-track-bg: #272e3a;
}

/* stylelint-disable selector-class-pattern, selector-no-qualifying-type */
.flatpickr-calendar {
  width: 325px;
  padding: 0 0.5rem;
  border: var(--cz-border-width) solid var(--cz-light-border-subtle);
  border-radius: var(--cz-border-radius);
  box-shadow: var(--cz-box-shadow);
}
.flatpickr-calendar::before, .flatpickr-calendar::after {
  display: none;
}

.flatpickr-innerContainer {
  padding-bottom: 1rem;
}

.flatpickr-months {
  padding: 0.75rem 0;
}
.flatpickr-months svg {
  vertical-align: top;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  top: 0.75rem;
}
.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  fill: var(--cz-heading-color) !important;
}

span.flatpickr-weekday,
.flatpickr-time .flatpickr-time-separator {
  color: var(--cz-gray-600);
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  padding: 0.25rem;
  appearance: none;
}

.numInputWrapper,
.numInput,
.flatpickr-current-month .flatpickr-monthDropdown-months {
  color: var(--cz-heading-color) !important;
}

.numInputWrapper:hover,
.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background-color: var(--cz-component-hover-bg);
}

.numInput:hover {
  background-color: transparent !important;
}

.flatpickr-day {
  height: 38px;
  line-height: 37px;
  color: var(--cz-body-color);
  border-radius: var(--cz-border-radius);
}
.flatpickr-day:hover:not(.flatpickr-disabled):not(.today):not(.selected):not(.startRange):not(.endRange), .flatpickr-day:focus:not(.flatpickr-disabled):not(.today):not(.selected):not(.startRange):not(.endRange) {
  color: var(--cz-component-active-color);
  background-color: var(--cz-component-active-bg);
  border-color: var(--cz-component-active-bg);
}
.flatpickr-day.today {
  font-weight: 500;
  background-color: transparent !important;
  border-color: var(--cz-component-active-color) !important;
}
.flatpickr-day.today:not(.startRange):not(.endRange):not(.selected) {
  color: var(--cz-component-active-color) !important;
}
.flatpickr-day.today.selected {
  color: #fff !important;
}
.flatpickr-day.today:hover {
  background-color: transparent;
}
.flatpickr-day.selected {
  font-weight: 500;
  background-color: var(--cz-component-active-color) !important;
  border-color: var(--cz-component-active-color) !important;
}
.flatpickr-day.flatpickr-disabled {
  color: var(--cz-tertiary-color) !important;
  text-decoration: line-through;
}
.flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay {
  color: var(--cz-tertiary-color);
}
.flatpickr-day.inRange {
  background-color: var(--cz-component-active-bg) !important;
  border-color: var(--cz-component-active-bg) !important;
  box-shadow: -5px 0 0 var(--cz-component-active-bg), 5px 0 0 var(--cz-component-active-bg);
}
.flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.endRange.seleced, .flatpickr-day.endRange:hover {
  background-color: var(--cz-component-active-color) !important;
  border-color: var(--cz-component-active-color) !important;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0;
  border-radius: 0 0.5rem 0.5rem 0;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 0;
  border-radius: 0.5rem 0 0 0.5rem;
}

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 0.5rem;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 0 var(--cz-component-active-color);
}

.flatpickr-time .flatpickr-am-pm {
  color: var(--cz-emphasis-color);
}
.flatpickr-time input:hover,
.flatpickr-time input::selection,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time .flatpickr-am-pm:focus {
  background-color: transparent;
}
.flatpickr-time input.flatpickr-hour {
  font-weight: 400;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 3.5rem;
  max-height: 3.5rem;
  padding: 0.5rem 0;
  line-height: 3.5rem;
  border: 0;
}
.flatpickr-calendar.hasTime .flatpickr-time .numInputWrapper {
  line-height: 2.5rem;
}
.flatpickr-calendar.hasTime .flatpickr-innerContainer + .flatpickr-time {
  border-top: 1px solid var(--cz-border-color);
}

.numInputWrapper span {
  border-color: var(--cz-border-color);
}
.numInputWrapper span.arrowUp::after {
  border-bottom-color: var(--cz-component-color) !important;
}
.numInputWrapper span.arrowDown::after {
  border-top-color: var(--cz-component-color) !important;
}
.numInputWrapper span:hover {
  background: var(--cz-border-color);
}

[data-bs-theme=dark] .flatpickr-calendar {
  background: #181d25;
  border-color: #333d4c;
  box-shadow: 0 0.5rem 1.875rem -0.25rem rgba(8, 11, 18, 0.35);
}
[data-bs-theme=dark] .flatpickr-day.startRange, [data-bs-theme=dark] .flatpickr-day.endRange, [data-bs-theme=dark] .flatpickr-day.startRange.selected, [data-bs-theme=dark] .flatpickr-day.selected, [data-bs-theme=dark] .flatpickr-day.selected.today {
  color: #222934 !important;
}

/* stylelint-enable selector-class-pattern, selector-no-qualifying-type */
[data-zoom]:hover {
  cursor: crosshair;
}

.drift-zoom-pane {
  background-color: var(--cz-body-bg);
  border: 1px solid var(--cz-border-color);
  border-radius: var(--cz-border-radius-lg);
}

[dir=rtl] .drift-zoom-pane img {
  /* rtl:begin:ignore */
  left: 0;
  /* rtl:end:ignore */
}

.drift-inline {
  z-index: 10;
}

/*# sourceMappingURL=theme.css.map */
